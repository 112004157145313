import { Anchor, Box, Button, Heading, Image, ResponsiveContext, Text } from 'grommet'
import React, { useRef, useState } from 'react'

import { FunkyPalette, INTRO_SCRIPT_DEMO, SOFIA_SANS, ThemeProps } from 'lib/constants'

import CloseIcon from 'components/icons/common/CloseIcon'
import ThreevetaBallBlackAndWhite from 'components/icons/myPage/ThreevetaBallBlackAndWhite'
import ThreevetaFullImageBlackAndWhite from 'components/icons/myPage/ThreevetaFullImageBlackAndWhite'
import HamburgerMenuIcon from 'components/icons/navigation/HamburgerMenuIcon'
import { SloganBanner } from 'components/SloganBanner'
import { colors } from 'layout/themeColors/colors'

import { ProviderBookingPage } from '../../Booking/ProviderBookingPage'

import SemiCircle from './components/illustrations/funky/SemiCircle'
import { ProvidersContactForm } from './components/ProvidersContactForm'
import { TemplateFooter } from './components/TemplateFooter'

export const FunkyThemePage = ({
  t,
  subdomain,
  font,
  palette,
  homePageImage,
  homeImagePosition,
  logo,
  title,
  subtitle,
  aboutTitle,
  aboutDescription,
  aboutImage,
  aboutImagePosition,
  preview,
  homeSectionRef,
  aboutSectionRef,
  providerId,
  bookableServices,
  lang = 'en',
  shouldHaveBanner = true,
}: ThemeProps<FunkyPalette>) => {
  const size = React.useContext(ResponsiveContext)

  const contactSectionRef = useRef<HTMLDivElement>(null)
  const isMobile = size === 'small'
  let secondary = font
  if (font === INTRO_SCRIPT_DEMO) {
    secondary = SOFIA_SANS
  }

  const homeImageSized =
    typeof homePageImage === 'object'
      ? isMobile || preview
        ? homePageImage?.small
        : homePageImage?.regular
      : homePageImage
  const aboutImageSized =
    typeof aboutImage === 'object'
      ? isMobile || preview
        ? aboutImage?.small
        : aboutImage?.regular
      : aboutImage
  const headerFontColor = palette.colors.header === '#FBFBFB' ? palette.colors.text : colors.white
  const contactFontColor = palette.colors.contact === '#020B5F' ? colors.white : palette.colors.text
  const buttonColor = palette.colors.button === '#000000' ? colors.white : palette.colors.text
  const contactButtonDoubleShadowColor =
    palette.colors.contact === '#020B5F' ? colors.white : '#000000'

  const primaryTitleSize = isMobile ? '40px' : '62px'
  const subTitleSize = isMobile ? '18px' : '27px'
  const secondaryTitleSize = isMobile ? '25px' : '43px'
  const paragraphSize = isMobile ? '12px' : '16px'
  const buttonTextSize = isMobile ? '11px' : '11px'

  const [openMobileNav, setOpenMobileNav] = useState(false)

  const navButtons = () => (
    <Box
      direction={isMobile ? 'column' : 'row'}
      gap={isMobile ? '25px' : '50px'}
      justify="center"
      fill
      align={isMobile ? 'start' : 'center'}>
      <Anchor
        href="#about"
        label={t('website-templates.about')}
        color={headerFontColor}
        style={{
          textTransform: 'uppercase',
          fontSize: isMobile ? '11px' : '14px',
          textDecoration: 'none',
          fontFamily: secondary,
        }}
      />
      {bookableServices && (
        <Anchor
          href="#services"
          label={t('website-templates.services')}
          color={headerFontColor}
          style={{
            textTransform: 'uppercase',
            fontSize: isMobile ? '11px' : '14px',
            textDecoration: 'none',
            fontFamily: secondary,
          }}
        />
      )}
      <Anchor
        href="#contact"
        label={t('website-templates.contacts')}
        color={headerFontColor}
        style={{
          textTransform: 'uppercase',
          textDecoration: 'none',
          fontSize: isMobile ? '11px' : '14px',
          fontFamily: secondary,
        }}
      />
    </Box>
  )

  return (
    <Box
      flex="grow"
      background={colors.white}
      className={(preview ? 'preview' : 'website-template') + ' funky-template'}>
      {shouldHaveBanner && <SloganBanner />}
      <Box style={{ minHeight: '70px', position: 'relative' }}>
        <Box
          direction="row"
          ref={homeSectionRef}
          align="center"
          fill="horizontal"
          pad={isMobile ? '0 35px' : '0 125px 0 80px'}
          height="70px"
          background={palette.colors.header}>
          <Box direction="row" gap="50px" align="center">
            {logo ? (
              <Image src={logo} height="35px" />
            ) : (
              <ThreevetaBallBlackAndWhite width="35px" height="35px" />
            )}
          </Box>
          {isMobile ? (
            <Box fill align="end" justify="center">
              <Button style={{ height: '19px' }} onClick={() => setOpenMobileNav((open) => !open)}>
                {openMobileNav ? (
                  <CloseIcon sideSize="16" color={headerFontColor} />
                ) : (
                  <HamburgerMenuIcon color={headerFontColor} />
                )}
              </Button>
            </Box>
          ) : (
            navButtons()
          )}
        </Box>
        {openMobileNav && isMobile && (
          <Box
            background={palette.colors.header}
            pad="35px"
            style={{ position: 'absolute', top: '70px', width: '100%', zIndex: 2 }}>
            {navButtons()}
          </Box>
        )}
      </Box>
      <Box style={{ minHeight: 'fit-content' }}>
        <Box
          background={colors.white}
          pad={{ top: isMobile ? '50px' : '90px' }}
          align="center"
          gap="large">
          <Box gap="small" align="center" width="80%">
            <Heading
              level={1}
              textAlign="center"
              size={primaryTitleSize}
              color={palette.colors.textHeading}
              margin="0"
              style={{ fontFamily: font }}>
              {!title && preview ? t('website-builder.builder-form.home.main-title') : title}
            </Heading>
            <Text
              size={subTitleSize}
              color={palette.colors.text}
              weight={600}
              style={{ fontFamily: secondary }}>
              {!subtitle && preview ? t('website-builder.builder-form.home.subtitle') : subtitle}
            </Text>
          </Box>
          <Box
            width={isMobile ? '80%' : '50%'}
            style={{ boxShadow: `2vw 2vw 0px -1vw ${palette.colors.accent1}` }}
            border={{ color: colors.dark, size: '3px', side: 'all', style: 'solid' }}
            round="4px">
            {homePageImage ? (
              <Image
                width="100%"
                style={{
                  height: '410px',
                  objectFit: 'cover',
                  objectPosition: homeImagePosition,
                }}
                src={homeImageSized}
              />
            ) : (
              <ThreevetaFullImageBlackAndWhite width="100%" height="410px" />
            )}
          </Box>
        </Box>
        <Box ref={aboutSectionRef} id="about" style={{ zIndex: 1 }}>
          <Box background="transparent">
            <Box
              direction={isMobile ? 'column-reverse' : 'row'}
              width={isMobile ? '80%' : '70%'}
              align="center"
              alignSelf="center"
              justify="center"
              margin={isMobile ? '50px 0' : '110px 0'}
              gap={isMobile ? 'medium' : 'xlarge'}>
              <Box
                width={isMobile ? '70vw' : '20vw'}
                height={isMobile ? '70vw' : '20vw'}
                round="4px"
                style={{
                  boxShadow: `2vw 2vw 0px -1vw ${palette.colors.accent2}`,
                }}
                border={{
                  color: colors.dark,
                  size: '3px',
                  side: 'all',
                  style: 'solid',
                }}>
                {aboutImageSized ? (
                  <Image
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: aboutImagePosition,
                    }}
                    src={aboutImageSized}
                  />
                ) : (
                  <ThreevetaFullImageBlackAndWhite width="100%" height="100%" />
                )}
              </Box>
              <Box gap="medium" width={isMobile ? undefined : '50%'}>
                <Heading
                  level={2}
                  size={secondaryTitleSize}
                  color={palette.colors.textHeading}
                  style={{ fontFamily: font }}>
                  {!aboutTitle && preview
                    ? t('website-builder.builder-form.home.title')
                    : aboutTitle}
                </Heading>
                {!aboutDescription && preview ? (
                  <Text
                    size={paragraphSize}
                    color={palette.colors.text}
                    weight={400}
                    style={{ fontFamily: secondary }}>
                    {t('website-builder.builder-form.home.description')}
                  </Text>
                ) : (
                  aboutDescription && (
                    <div
                      dangerouslySetInnerHTML={{ __html: aboutDescription }}
                      style={{
                        fontFamily: secondary,
                        color: palette.colors.text,
                        fontSize: paragraphSize,
                        lineHeight: isMobile ? '24px' : '38px',
                        margin: '0',
                        fontWeight: 500,
                      }}
                    />
                  )
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {bookableServices && (
        <Box id="services">
          <ProviderBookingPage
            shouldHaveBanner={false}
            preview={false}
            services={bookableServices}
            withHeader={false}
            subdomain={subdomain}
            showInline
          />
        </Box>
      )}
      <Box
        id="contact"
        style={{ minHeight: 'fit-content', position: 'relative' }}
        background={colors.white}
        align="center"
        pad={isMobile ? '0' : '80px 0 90px 0'}
        ref={contactSectionRef}>
        {!isMobile && (
          <div style={{ position: 'absolute', right: '0', top: '200px', transform: 'scaleX(-1)' }}>
            <SemiCircle color={palette.colors.funkyCircle4} width="110px" height="260px" />
          </div>
        )}
        <Box
          width={isMobile ? '100%' : '60%'}
          background={palette.colors.contact}
          align="center"
          pad="40px 0 80px 0">
          <Heading
            level={2}
            size={secondaryTitleSize}
            color={contactFontColor}
            textAlign="center"
            style={{ fontFamily: font }}>
            {t('website-templates.contact-title')}
          </Heading>
          <Box width="70%">
            <ProvidersContactForm
              t={t}
              providerId={providerId}
              backgroundColor={palette.colors.services}
              buttonStyle={{
                marginRight: '6px',
                fontFamily: secondary,
                color: buttonColor,
                border: `2px solid ${contactButtonDoubleShadowColor}`,
                backgroundColor: palette.colors.button,
                boxShadow: `6px 6px 0px 0px ${palette.colors.buttonBoxShadow}, 6px 6px 0px 2px ${contactButtonDoubleShadowColor}`,
                padding: '10px 24px',
                fontSize: buttonTextSize,
                lineHeight: '23px',
                width: 'fit-content',
                float: 'right',
              }}
              palette={palette}
              secondary={secondary}
            />
          </Box>
        </Box>
      </Box>
      <TemplateFooter
        t={t}
        lang={lang}
        font={font}
        palette={palette}
        shouldHavePlatformBranding={shouldHaveBanner}
      />
    </Box>
  )
}

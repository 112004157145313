import i18next from 'i18next'
import getConfig from 'next/config'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import useCookieConsent from 'config/useCookieConsent'
import { gtmInitialize } from 'config/useGTM'
import { DEFAULT_APP_SUBDOMAIN, TEAM_MEMBER_ROLES_ENUM } from 'lib/constants'
import { getMainTeam } from 'lib/helpers'
import { routes } from 'lib/routes'

import { Team } from 'services/api/Team'
import { ServerUser } from 'services/api/Users'
import { fetchUser, getUserIdFromCookie } from 'services/auth/fetchUser'

const {
  publicRuntimeConfig: { WHITELABEL_ENV_IS_3VETA },
} = getConfig()

type UserContextProps = {
  loading: boolean
  user?: ServerUser
  refreshUser: () => Promise<void>
  setUser: React.Dispatch<React.SetStateAction<ServerUser | undefined>>
  subdomain: string
  mainSubdomain: boolean
  ownSubdomain: boolean
} & useTeamProps

export const UserContext = React.createContext<UserContextProps>(null as any)

export const useUserContext = () => {
  return useContext(UserContext)
}

export const useUser = (): [
  ServerUser | undefined,
  React.Dispatch<React.SetStateAction<ServerUser | undefined>>,
  boolean,
  () => void,
] => {
  const { user, setUser, loading, refreshUser } = useUserContext()
  return [user, setUser, loading, refreshUser]
}

export const useStrictUser = (): [
  ServerUser,
  {
    setUser: React.Dispatch<React.SetStateAction<ServerUser | undefined>>
    loading: boolean
    refreshUser: () => void
  },
] => {
  const { user, setUser, loading, refreshUser } = useUserContext()
  if (!user) {
    throw new Error('No user in strict mode')
  }
  return [user, { setUser, loading, refreshUser }]
}

type useTeamProps = {
  team?: Team
  isActiveInTeam: boolean
  roleInTeam?: string
  isAdminOrOwner: boolean
  refreshUser: () => void
}
export const useTeam = (): useTeamProps => {
  const { team, isActiveInTeam, roleInTeam, isAdminOrOwner, refreshUser } = useUserContext()
  return { team, isActiveInTeam, roleInTeam, isAdminOrOwner, refreshUser }
}

type UserContextProviderProps = React.PropsWithChildren<{
  currentSubdomain: string
  serverUser?: ServerUser
  path?: string
}>

const cookieControlPathsBlacklist = [
  routes.signInWithGoogleEmbeddablePage,
  routes.signInWithFacebookEmbeddablePage,
]

export const UserContextProvider = ({
  currentSubdomain,
  serverUser,
  path,
  children,
}: UserContextProviderProps) => {
  const [user, setUser] = useState<ServerUser | undefined>(serverUser)
  const { initialize } = useCookieConsent(currentSubdomain === 'team')
  const [loading, setLoading] = useState(false)

  const mainSubdomain = useMemo(
    () => DEFAULT_APP_SUBDOMAIN === currentSubdomain,
    [currentSubdomain],
  )
  const ownSubdomain = useMemo(() => user?.subdomain === currentSubdomain, [currentSubdomain, user])

  const team = useMemo<Team | undefined>(() => getMainTeam(user), [user])

  const isActiveInTeam = useMemo<boolean>(() => {
    return team?._joinData?.is_active ?? false
  }, [team])

  const roleInTeam = useMemo<string | undefined>(() => {
    return team?._joinData?.role
  }, [team])

  const isAdminOrOwner = useMemo<boolean>(() => {
    return (
      roleInTeam === TEAM_MEMBER_ROLES_ENUM.ADMIN || roleInTeam === TEAM_MEMBER_ROLES_ENUM.OWNER
    )
  }, [roleInTeam])

  useEffect(() => {
    // Don't init when user about to be loaded from cookie
    const cookieUserId = getUserIdFromCookie()
    if (!user && cookieUserId) return
    // Don't init when user is loading
    if (loading) return

    if (!path || !cookieControlPathsBlacklist.includes(path)) {
      // Don't show cookie tool on non-3veta or embedded pages but still load GTM
      if (!WHITELABEL_ENV_IS_3VETA || window.self !== window.top) {
        console.log('loading GTM without cookie tool for path: ', path)
        gtmInitialize({
          events: {
            ...(user?.id && { user_id: user.id }),
            ...(user?.email && { user_email: user.email }),
            ...(user?.user_type && { user_type: user.user_type.name }),
            user_lang: i18next.language,
            consent_analytics: false,
            consent_marketing: false,
            originalLocation:
              document.location.protocol +
              '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search,
          },
        })
        return
      }

      initialize({
        onLoad: () => {
          gtmInitialize({
            events: {
              ...(user?.id && { user_id: user.id }),
              ...(user?.email && { user_email: user.email }),
              ...(user?.user_type && { user_type: user.user_type.name }),
              user_lang: i18next.language,
              consent_analytics: window.CookieControl.getCategoryConsent(0),
              consent_marketing: window.CookieControl.getCategoryConsent(1),
              originalLocation:
                document.location.protocol +
                '//' +
                document.location.hostname +
                document.location.pathname +
                document.location.search,
            },
          })
        },
      })
    }
  }, [initialize, loading, user])

  const refreshUser = useCallback(async () => {
    try {
      setLoading(true)
      const updatedUser = await fetchUser()
      setUser(updatedUser)
    } catch (error) {
      // no user session
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    // Validate if JWT user is the same as local storage user
    const cookieUserId = getUserIdFromCookie()
    if (!user || (cookieUserId && user?.id && user?.id !== cookieUserId)) {
      refreshUser()
    }

    // Initial user language on the frontend
    if (user?.iso_language_code) {
      i18next.changeLanguage(user?.iso_language_code)
    }
  }, [refreshUser, user])

  const value = useMemo<UserContextProps>(
    () => ({
      loading,
      user,
      refreshUser,
      setUser,
      subdomain: currentSubdomain,
      mainSubdomain,
      ownSubdomain,
      team,
      isActiveInTeam,
      roleInTeam,
      isAdminOrOwner,
    }),
    [loading, user, refreshUser, currentSubdomain, mainSubdomain, ownSubdomain],
  ) //added to avoid multiple renders

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

import getConfig from 'next/config'
import Cookies from 'universal-cookie'

import { checkWhitelabelEnv, WHITELABEL_ENVS } from './lib/whitelabelEnvs'

const {
  publicRuntimeConfig: {
    THREEVETA_ENV,
    WEBAPP_URL,
    PLATFORM_DEFAULT_SUBDOMAIN,
    WHITELABEL_ENV,
    DOMAIN,
  },
} = getConfig()

function is3vetaDomain(domain: string) {
  return new RegExp(/.*\.3veta\.com/).test(domain)
}

function createAuthTokenCookieSuffix() {
  if (checkWhitelabelEnv(WHITELABEL_ENVS.THREEVETA)) {
    if (THREEVETA_ENV === 'production') {
      return ''
    } else {
      return `_${THREEVETA_ENV?.substr(0, 3)}`
    }
  } else {
    if (is3vetaDomain(DOMAIN)) {
      return `_${WHITELABEL_ENV?.substr(0, 3)}`
    } else {
      return ''
    }
  }
}

const suffix = createAuthTokenCookieSuffix()

export const AUTH_TOKEN_KEY = `authToken${suffix}`
export const AUTH_TOKEN_RESTRICTED_KEY = `authTokenRestricted${suffix}`

export class AuthTokenRestrictedNotSetError extends Error {
  constructor(message: string) {
    super(message)
  }
}

// Expire cookie in 7 days
const expirationDate = new Date()
expirationDate.setDate(expirationDate.getDate() + 7)

const expirationDateY2K38 = new Date((Math.pow(2, 31) - 1) * 1000)

interface CookieSerializeOptionsForRemoval {
  domain?: string
  path?: string
}

interface CookieSerializeOptionsForSetting extends CookieSerializeOptionsForRemoval {
  encode?(val: string): string
  expires?: Date
  httpOnly?: boolean
  maxAge?: number
  sameSite?: boolean | 'lax' | 'strict' | 'none'
  secure?: boolean
}

export const cookieOptionsForRemoval = (): CookieSerializeOptionsForRemoval => {
  return {
    // .3veta.com
    // .staging.3veta.com
    // .3veta.localhost
    domain: WEBAPP_URL && new URL(WEBAPP_URL).host?.replace(`${PLATFORM_DEFAULT_SUBDOMAIN}.`, '.'),
    path: '/',
  }
}

export const cookieOptionsForSetting = (
  externallyAccessible = false,
  shouldExpire = true,
): CookieSerializeOptionsForSetting => {
  return {
    ...cookieOptionsForRemoval(),
    secure: true,
    sameSite: externallyAccessible ? 'none' : 'lax',
    // We cannot get or set httpOnly cookies from the browser, only the server.
    httpOnly: false,
    expires: shouldExpire ? expirationDate : expirationDateY2K38,
  }
}

export const getCookie = (key = 'state') => {
  try {
    if (typeof document === 'undefined') {
      throw new Error('Invalid context')
    }
    const cookies = new Cookies(document.cookie)
    const serializedValue = cookies.get(key)
    if (serializedValue === undefined) {
      return undefined
    }
    return serializedValue
  } catch (e) {
    // Ignore read errors.
    return undefined
  }
}

export const setCookie = (
  key = 'state',
  value: any,
  externallyAccessible = false,
  shouldExpire = true,
) => {
  if (typeof document === 'undefined') {
    throw new Error('Invalid context')
  }
  const serializedValue = typeof value === 'string' ? value : JSON.stringify(value)
  const cookies = new Cookies(document.cookie)
  cookies.set(key, serializedValue, cookieOptionsForSetting(externallyAccessible, shouldExpire))
  if (key === AUTH_TOKEN_RESTRICTED_KEY && serializedValue && !cookies.get(key)) {
    throw new AuthTokenRestrictedNotSetError(`Failed setting cookie with key ${key}`)
  }
}

export const removeCookie = (key = 'state') => {
  if (typeof document === 'undefined') {
    throw new Error('Invalid context')
  }
  try {
    const cookies = new Cookies(document.cookie)
    cookies.remove(key, cookieOptionsForRemoval())
  } catch (e) {
    console.error(e)
    // Ignore remove errors.
  }
}

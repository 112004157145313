import { ClickAwayListener } from '@mui/material'
import { Box, ResponsiveContext, Text } from 'grommet'
import React, { useContext, useEffect, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'

import { colors } from 'layout/themeColors/colors'

type Props = {
  boxWidth?: string
  boxHeight?: string
  defaultPickerColor?: string
  predefinedColors?: string[]
  handleChange: (color: string) => void
  justify?: 'around' | 'between' | 'center' | 'end' | 'evenly' | 'start' | 'stretch'
  isRelative?: boolean
  showBorders?: boolean
  pickerTopPosition?: string
}
const ColorPicker = ({
  boxWidth = '2rem',
  boxHeight = '2rem',
  defaultPickerColor = colors.white,
  predefinedColors = [],
  handleChange,
  justify = 'between',
  isRelative = false,
  showBorders = true,
  pickerTopPosition = '3.5rem',
}: Props) => {
  const [color, setColor] = useState<string>(defaultPickerColor)
  const [isPickerVisible, setIsPickerVisible] = useState<boolean>(false)

  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  const hasPredefinedColors = predefinedColors?.length > 0

  useEffect(() => {
    handleChange(color)
  }, [color])

  return (
    <ClickAwayListener onClickAway={() => setIsPickerVisible(false)}>
      <Box flex width="100%" direction="row" justify={justify} gap="4px" alignContent="center">
        {predefinedColors?.map((cl: string) => {
          return (
            <Box
              key={cl}
              round="4px"
              width={boxWidth}
              height={boxHeight}
              border={
                showBorders
                  ? {
                      color: colors.primaryBrand,
                      size: cl === color ? '4px' : '1px',
                      style: 'solid',
                      side: 'all',
                    }
                  : false
              }
              margin={{
                ...(justify === 'start' && {
                  right: 'small',
                }),
              }}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setColor(cl)
                // handleChange(cl)
                setIsPickerVisible(false)
              }}
              background={cl}
            />
          )
        })}
        <Box style={{ position: 'relative' }}>
          <Box
            round="4px"
            width={boxWidth}
            height={boxHeight}
            justify="center"
            align="center"
            background={!hasPredefinedColors ? color : ''}
            border={{
              color: colors.primaryBrand,
              size: '1px',
              style: 'solid',
              side: 'all',
            }}
            onClick={() => setIsPickerVisible(!isPickerVisible)}>
            {hasPredefinedColors && <Text color={colors.primaryBrand}>+</Text>}
          </Box>
          {isPickerVisible && (
            <Box
              width="180px"
              margin={isRelative ? '12px 0 0' : ''}
              // Display picker as popover to control visibility when clicking outside
              style={{
                position: isRelative ? 'relative' : 'absolute',
                ...(!isRelative && {
                  zIndex: 999,
                  ...(!isMobile && {
                    top: pickerTopPosition,
                    left: '-85px',
                  }),
                  ...(isMobile && {
                    top: '-85px',
                    left: '-230px',
                  }),
                }),
              }}>
              {!isRelative && (
                <Box
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                  onClick={() => setIsPickerVisible(false)}
                />
              )}
              {/* @ts-expect-error react-17-to-18 */}
              <ChromePicker color={color} onChange={(val: ColorResult) => setColor(val.hex)} />
            </Box>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  )
}

export default ColorPicker

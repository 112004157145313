import RedirectIcon from '../RedirectIcon'

interface ExternalLinkIconProps {
  width?: number
  height?: number
}

export function ExternalLinkIcon({ height = 20, width = 20 }: ExternalLinkIconProps) {
  return <RedirectIcon width={width} height={height} />
}

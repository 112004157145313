import { Grid, ResponsiveContext } from 'grommet'
import React, { useMemo } from 'react'

import { BookingPagesTemplate } from 'components/BookingPagesTemplate'
import { UpcomingEvent } from 'services/api/Meetings'
import { ProviderUser } from 'services/api/Users'

import { EventCard } from './components/EventCard'

interface ProviderEventsPageProps {
  shouldHaveBanner?: boolean
  withHeader?: boolean
  provider?: ProviderUser
  events: UpcomingEvent[] | undefined
  subdomain?: string
}

export function ProviderEventsPage({
  provider,
  shouldHaveBanner,
  withHeader = true,
  events,
  subdomain,
}: ProviderEventsPageProps) {
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'

  const eventsColumnCount = useMemo(() => {
    if (isMobile) {
      return 1
    }
    if (events && events?.length <= 3) {
      if (events.length === 4) return 2
      return events?.length
    }
    return 3
  }, [events, isMobile])

  if (!provider && !subdomain) return null

  return (
    <BookingPagesTemplate
      style={{
        // When we have subdomain the events are shown inline
        minHeight: subdomain ? undefined : '100vh',
      }}
      provider={provider}
      shouldHaveBanner={shouldHaveBanner}
      withHeader={withHeader}>
      <>
        {events && events?.length > 0 && (
          <Grid
            columns={{ count: eventsColumnCount, size: '300px' }}
            gap="16px"
            align="start"
            justify="center">
            {events.map((event) => (
              <EventCard
                key={event.id}
                event={event}
                subdomain={subdomain ?? provider?.subdomain}
              />
            ))}
          </Grid>
        )}
      </>
    </BookingPagesTemplate>
  )
}

import { Anchor, Box, Button, Heading, Image, ResponsiveContext, Text } from 'grommet'
import React, { useRef, useState } from 'react'

import { INTRO_SCRIPT_DEMO, ProfessionalPalette, SOFIA_SANS, ThemeProps } from 'lib/constants'

import CloseIcon from 'components/icons/common/CloseIcon'
import ThreevetaBallBlackAndWhite from 'components/icons/myPage/ThreevetaBallBlackAndWhite'
import ThreevetaFullImageBlackAndWhite from 'components/icons/myPage/ThreevetaFullImageBlackAndWhite'
import HamburgerMenuIcon from 'components/icons/navigation/HamburgerMenuIcon'
import { SloganBanner } from 'components/SloganBanner'
import { colors } from 'layout/themeColors/colors'

import { ProviderBookingPage } from '../../Booking/ProviderBookingPage'

import { ProvidersContactForm } from './components/ProvidersContactForm'
import { TemplateFooter } from './components/TemplateFooter'

export const ProfessionalThemePage = ({
  t,
  subdomain,
  font,
  palette,
  homePageImage,
  homeImagePosition,
  logo,
  title,
  subtitle,
  aboutTitle,
  aboutDescription,
  preview,
  bookableServices,
  homeSectionRef,
  aboutSectionRef,
  providerId,
  lang = 'en',
  shouldHaveBanner = true,
}: ThemeProps<ProfessionalPalette>) => {
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'
  const contactSectionRef = useRef<HTMLDivElement>(null)

  let secondary = font
  if (font === INTRO_SCRIPT_DEMO) {
    secondary = SOFIA_SANS
  }

  const homeImage =
    typeof homePageImage === 'object'
      ? isMobile || preview
        ? homePageImage?.regular
        : homePageImage?.full
      : homePageImage
  const headerFontColor =
    palette.colors.header === colors.white ? palette.colors.text : colors.white
  const contactFontColor =
    palette.colors.contact === colors.white ? palette.colors.text : palette.colors.services
  const primaryTitleSize = isMobile ? '33px' : '65px'
  const subTitleSize = isMobile ? '18px' : '25px'
  const secondaryTitleSize = isMobile ? '25px' : '35px'
  const paragraphSize = isMobile ? '12px' : '16px'

  const [openMobileNav, setOpenMobileNav] = useState(false)

  const navButtons = () => (
    <Box direction="column" gap="25px" justify="center" fill align="start">
      <Anchor
        href="#about"
        label={t('website-templates.about')}
        color={contactFontColor}
        style={{
          textTransform: 'uppercase',
          fontSize: isMobile ? '11px' : '14px',
          textDecoration: 'none',
          fontFamily: secondary,
        }}
      />
      {bookableServices && (
        <Anchor
          href="#services"
          label={t('website-templates.services')}
          color={contactFontColor}
          style={{
            textTransform: 'uppercase',
            fontSize: isMobile ? '11px' : '14px',
            textDecoration: 'none',
            fontFamily: secondary,
          }}
        />
      )}
      <Anchor
        href="#contact"
        label={t('website-templates.contacts')}
        color={contactFontColor}
        style={{
          textTransform: 'uppercase',
          textDecoration: 'none',
          fontSize: isMobile ? '11px' : '14px',
          fontFamily: secondary,
        }}
      />
    </Box>
  )

  return (
    <Box
      flex="grow"
      className={(preview ? 'preview' : 'website-template') + ' professional-template'}>
      {shouldHaveBanner && <SloganBanner />}
      <Box style={{ minHeight: '70px', position: 'relative' }}>
        <Box
          direction="row"
          ref={homeSectionRef}
          align="center"
          fill="horizontal"
          pad={isMobile ? '0 35px' : '0 125px 0 80px'}
          justify="between"
          height="70px"
          background={palette.colors.header}>
          <Box direction="row" gap="50px" align="center">
            {logo ? (
              <Image src={logo} height="35px" />
            ) : (
              <ThreevetaBallBlackAndWhite width="35px" height="35px" />
            )}
            {!isMobile && (
              <Anchor
                href="#about"
                label={t('website-templates.about')}
                color={headerFontColor}
                style={{
                  paddingLeft: '150px',
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  fontFamily: secondary,
                  fontSize: '14px',
                }}
              />
            )}
            {!isMobile && bookableServices && (
              <Anchor
                href="#services"
                label={t('website-templates.services')}
                color={headerFontColor}
                style={{
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  fontFamily: secondary,
                  fontSize: '14px',
                }}
              />
            )}
          </Box>

          {isMobile ? (
            <Box fill align="end" justify="center">
              <Button style={{ height: '19px' }} onClick={() => setOpenMobileNav((open) => !open)}>
                {openMobileNav ? (
                  <CloseIcon sideSize="16" color={headerFontColor} />
                ) : (
                  <HamburgerMenuIcon color={headerFontColor} />
                )}
              </Button>
            </Box>
          ) : (
            <Anchor
              href="#contact"
              label={t('website-templates.contacts')}
              color={headerFontColor}
              style={{
                textTransform: 'uppercase',
                textDecoration: 'none',
                fontFamily: secondary,
                fontSize: '14px',
              }}
            />
          )}
        </Box>
        {openMobileNav && isMobile && (
          <Box
            background={palette.colors.contact}
            pad="35px"
            style={{ position: 'absolute', top: '70px', width: '100%', zIndex: 2 }}>
            {navButtons()}
          </Box>
        )}
      </Box>
      <Box style={{ minHeight: 'fit-content' }}>
        <Box
          style={{ position: 'relative' }}
          height={isMobile ? '430px' : '660px'}
          align="start"
          justify={isMobile ? 'end' : 'center'}>
          {homeImage ? (
            <Image
              src={homeImage}
              style={{
                objectFit: 'cover',
                pointerEvents: 'none',
                width: '100%',
                height: '100%',
                objectPosition: homeImagePosition,
              }}
            />
          ) : (
            <ThreevetaFullImageBlackAndWhite width="100%" height="100%" />
          )}
          <Box
            width={isMobile ? '80%' : '40%'}
            gap="small"
            style={{ position: 'absolute', zIndex: 2 }}
            pad={isMobile ? '40px' : { left: '130px' }}>
            <Heading
              level={1}
              size={isMobile ? '40px' : '80px'}
              color={palette.colors.text}
              margin="0">
              <Text
                size={primaryTitleSize}
                style={{
                  backgroundColor: colors.white,
                  fontFamily: font,
                  boxShadow: '12px 0 0 white,-12px 0 0 white',
                  height: isMobile ? '40px' : '80px',
                  lineHeight: '1.3px',
                }}>
                {!title && preview ? t('website-builder.builder-form.home.main-title') : title}
              </Text>
            </Heading>
            <Text size={isMobile ? '18px' : '25px'}>
              <Text
                size={subTitleSize}
                color={palette.colors.text}
                weight={600}
                style={{
                  backgroundColor: colors.white,
                  fontFamily: secondary,
                  boxShadow: '12px 0 0 white,-12px 0 0 white',
                  height: isMobile ? '18px' : '25px',
                  lineHeight: '1.3px',
                }}>
                {!subtitle && preview ? t('website-builder.builder-form.home.subtitle') : subtitle}
              </Text>
            </Text>
          </Box>
        </Box>
        <Box ref={aboutSectionRef} id="about">
          {(aboutDescription || preview) && (
            <Box background={colors.white}>
              <Box
                width={isMobile ? '80%' : '60%'}
                alignSelf="center"
                margin={isMobile ? '50px 0' : '110px 0'}
                gap="medium">
                <Heading
                  level={2}
                  size={secondaryTitleSize}
                  textAlign="center"
                  color={palette.colors.textHeading}
                  style={{ fontFamily: font }}>
                  {!aboutTitle && preview
                    ? t('website-builder.builder-form.home.title')
                    : aboutTitle}
                </Heading>
                {!aboutDescription && preview ? (
                  <Text
                    size={paragraphSize}
                    color={palette.colors.text}
                    weight={500}
                    style={{ fontFamily: secondary }}>
                    {t('website-builder.builder-form.home.description')}
                  </Text>
                ) : (
                  aboutDescription && (
                    <div
                      dangerouslySetInnerHTML={{ __html: aboutDescription }}
                      style={{
                        fontFamily: secondary,
                        color: palette.colors.text,
                        fontSize: paragraphSize,
                        lineHeight: isMobile ? '22px' : '34px',
                        fontWeight: 500,
                      }}
                    />
                  )
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {bookableServices && (
        <Box id="services" height={{ min: 'fit-content' }}>
          <ProviderBookingPage
            shouldHaveBanner={false}
            preview={false}
            services={bookableServices}
            withHeader={false}
            subdomain={subdomain}
            showInline
          />
        </Box>
      )}
      <Box
        id="contact"
        style={{ minHeight: 'fit-content' }}
        background={palette.colors.contact}
        fill
        align="center"
        pad="40px 0 110px 0"
        ref={contactSectionRef}>
        <Heading
          level={2}
          size={secondaryTitleSize}
          color={contactFontColor}
          textAlign="center"
          style={{ fontFamily: font }}>
          {t('website-templates.contact-title')}
        </Heading>
        <Box width={isMobile ? '80%' : '60%'}>
          <ProvidersContactForm
            t={t}
            providerId={providerId}
            backgroundColor={palette.colors.services}
            buttonStyle={{
              textTransform: 'uppercase',
              fontFamily: secondary,
              backgroundColor: palette.colors.button,
              padding: '12px 24px',
              fontSize: '13px',
              lineHeight: '23px',
              width: 'fit-content',
              float: 'right',
            }}
            palette={palette}
            secondary={secondary}
          />
        </Box>
      </Box>
      <TemplateFooter
        t={t}
        lang={lang}
        font={font}
        palette={palette}
        shouldHavePlatformBranding={shouldHaveBanner}
      />
    </Box>
  )
}

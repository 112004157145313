import { Theme, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import { useLocalStorage } from 'config/useLocalStorage'

const SidebarContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const router = useRouter()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerExpanded, setDrawerExpanded] = useState(true)
  const [storage, setStorage] = useLocalStorage<boolean | undefined>('drawer_open', undefined)
  const mobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const isPersistent = !mobile

  useEffect(() => {
    if (typeof storage !== 'undefined' && !mobile) {
      // Restore state from localStorage on desktop
      setDrawerExpanded(storage)
    }
  }, [storage, mobile])

  useEffect(() => {
    const onRouteChange = () => {
      if (mobile) {
        // Hide the sidebar on mobile when navigating away
        setDrawerOpen(false)
      }
    }
    router.events.on('routeChangeStart', onRouteChange)
    return () => router.events.off('routeChangeStart', onRouteChange)
  }, [mobile])

  const toggleExpanded = () => {
    setDrawerExpanded((open) => !open)
    setStorage(!drawerExpanded)
  }

  const toggleOpen = () => {
    setDrawerOpen((open) => !open)
  }

  const drawerToggle = useMemo(
    () => (isPersistent ? toggleExpanded : toggleOpen),
    [isPersistent, toggleExpanded, toggleOpen],
  )

  return {
    drawerOpen,
    drawerToggle,
    drawerExpanded,
    isPersistent,
  }
}

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const context = useInit()
  return <SidebarContext.Provider value={context}>{children}</SidebarContext.Provider>
}

export const useSidebar = () => {
  return useContext(SidebarContext)
}

import { Box, BoxProps, Heading, ResponsiveContext } from 'grommet'
import React from 'react'

import { colors } from 'layout/themeColors/colors'
import { ProviderUser } from 'services/api/Users'

import Avatar from './Avatar'

interface BookingHeaderProps {
  provider: ProviderUser | undefined
}

type Props = BookingHeaderProps & BoxProps

export function BookingHeader({ provider, ...rest }: Props) {
  const deviceSize = React.useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  return (
    <Box {...rest} direction="row" gap="medium">
      {provider?.avatar_url ? (
        <Avatar
          user={provider}
          width={isMobile ? '50px' : '80px'}
          height={isMobile ? '50px' : '80px'}
        />
      ) : null}
      <Heading level={1} color={colors.dark} textAlign="center" margin="0">
        {provider?.name}
      </Heading>
    </Box>
  )
}

import React from 'react'

import { colors } from 'layout/themeColors/colors'

type DefaultAvatar3vetaIconProps = {
  width: string
  height: string
  circleId: string
  clipPathId: string
  gradientId: string
}

export function DefaultAvatar3vetaIcon({
  gradientId,
  height,
  circleId,
  clipPathId,
  width,
}: DefaultAvatar3vetaIconProps) {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
      xmlSpace="preserve">
      <g>
        <g>
          <defs>
            <circle id={circleId} cx="250" cy="250" r="250" />
          </defs>
          <clipPath id={clipPathId}>
            <use href={'#' + circleId} overflow="visible" />
          </clipPath>
          <radialGradient
            id={gradientId}
            cx="261.6955"
            cy="19.8128"
            r="324.8997"
            fx="407.4697"
            fy="27.8206"
            gradientTransform="matrix(1.0125 0 0 1.5452 5.1876 105.4284)"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#9F7DD8" />
            <stop offset="0.508" stopColor="#8771D4" />
            <stop offset="1" stopColor="#6C63CF" />
          </radialGradient>
          <rect
            x="-7.7"
            y="-7.7"
            clipPath={'url(#' + clipPathId + ')'}
            fill={'url(#' + gradientId + ')'}
            width="518.4"
            height="514.9"
          />
          <g clipPath={'url(#' + clipPathId + ')'}>
            <g>
              <path
                fill={colors.white}
                d="M247.7,276.9c-41.4,0-75-33.6-75-75c0-41.4,33.6-75,75-75s75,33.6,75,75
					C322.7,243.2,289.1,276.9,247.7,276.9z M247.7,151.9c-27.6,0-50,22.4-50,50c0,27.6,22.4,50,50,50s50-22.4,50-50
					C297.7,174.3,275.3,151.9,247.7,151.9z"
              />
            </g>
            <g>
              <path
                fill={colors.white}
                d="M363,355.1h-25c0-35.5-22.9-64.4-51-64.4h-78.5c-28.1,0-51,28.9-51,64.4h-25c0-23.4,7.6-45.6,21.5-62.3
					c14.4-17.4,33.8-27.1,54.6-27.1H287c20.8,0,40.1,9.6,54.6,27.1C355.4,309.6,363,331.7,363,355.1z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

import { Box, BoxExtendedProps } from 'grommet'
import React from 'react'

import { colors } from 'layout/themeColors/colors'
import { ProviderUser } from 'services/api/Users'

import { BookingHeader } from './BookingHeader'
import { SloganBanner } from './SloganBanner'

interface BookingPagesHeaderProps {
  provider: ProviderUser | undefined
  withHeader?: boolean
  shouldHaveBanner?: boolean
  children?: React.ReactElement
  preview?: boolean
  mobilePreview?: boolean
}

type Props = BookingPagesHeaderProps & BoxExtendedProps

export function BookingPagesTemplate({
  withHeader,
  provider,
  shouldHaveBanner,
  children,
  preview = false,
  mobilePreview = false,
  ...rest
}: Props) {
  return (
    <Box
      {...rest}
      className={preview && !mobilePreview ? 'preview' : undefined}
      background={colors.light1}
      overflow="auto"
      fill="horizontal"
      align="center"
      pad={{
        bottom: '40px',
        top: withHeader || shouldHaveBanner ? '0' : '30px',
        left: 'large',
        right: 'large',
      }}>
      {shouldHaveBanner && <SloganBanner />}
      {withHeader && provider && (
        <BookingHeader direction="column" align="center" margin="medium" provider={provider} />
      )}
      <Box pad={{ top: shouldHaveBanner && !withHeader ? 'small' : undefined }}>{children}</Box>
    </Box>
  )
}

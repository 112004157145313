import { Box } from 'grommet'
import { PadType } from 'grommet/utils'
import React from 'react'

import { colors } from 'layout/themeColors/colors'

type Props = {
  pad?: PadType
  fill?: string
}
function ExpandRightIcon({ pad = { vertical: '10px', left: '10px' }, fill }: Props) {
  return (
    <Box pad={pad}>
      <svg width="10" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L5.44444 5L1 9"
          stroke={colors.primaryBrand}
          strokeWidth="1.5"
          strokeLinecap="round"
          fill={fill}
        />
      </svg>
    </Box>
  )
}

export default ExpandRightIcon

import React from 'react'

import { colors } from 'layout/themeColors/colors'

const HamburgerMenuIcon = ({ color }: { color: string }) => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M0.21875 0.3125H18.7812V2.75H0.21875V0.3125Z" fill={color} />
      <path d="M0.21875 7.16992H18.7812V9.60742H0.21875V7.16992Z" fill={color} />
      <path d="M0.21875 14.0276H18.7812V16.4651H0.21875V14.0276Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="16" fill={colors.white} />
      </clipPath>
    </defs>
  </svg>
)

export default HamburgerMenuIcon

import React from 'react'

const ErrorPageIllustration = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 460 305"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M425.2 113.5C425 110.4 422.3 108 419.2 108.2C416.1 108.4 413.7 111.1 413.9 114.2C414.7 126.2 416 139.6 418 154.4H314.8C316.8 139.7 318.5 126.4 319.8 114.4C320.1 111.3 317.9 108.5 314.8 108.2C311.7 107.9 308.9 110.1 308.6 113.2C306.1 136.8 302.2 165.6 296.4 200.6C291.5 230.4 285.2 264.6 277.2 303.9H288.8C296.6 265.3 302.8 231.7 307.6 202.4C309.8 189.3 311.6 177.1 313.3 165.7H419.6C421.2 177.1 423.3 189.4 425.7 202.5C431.1 231.8 438.5 265.4 448.4 303.9H460C449.7 264.5 442.2 230.3 436.7 200.5C434 185.8 431.8 172.3 430.1 159.7C430.1 159.4 430 159.1 430 158.9C427.7 142.1 426.1 127 425.2 113.5Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.3 168.9C190.3 167.2 189 165.9 187.3 165.9C185.6 165.9 184.3 167.2 184.3 168.9V261V304H190.3V264H236.4V304H242.4V261V168.9C242.4 167.2 241.1 165.9 239.4 165.9C237.7 165.9 236.4 167.2 236.4 168.9V247H190.3V168.9ZM236.4 258H190.3V250.8H236.4V258Z"
      fill="#546AA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.2 168.9C138.2 167.2 136.9 165.9 135.2 165.9C133.5 165.9 132.2 167.2 132.2 168.9V261V304H138.2V264H184.3V304H190.3V261V168.9C190.3 167.2 189 165.9 187.3 165.9C185.6 165.9 184.3 167.2 184.3 168.9V247H138.2V168.9ZM184.3 258H138.2V250.8H184.3V258Z"
      fill="#546AA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.1 168.9H130.4C128.3 168.9 126.6 170.6 126.6 172.7C126.6 174.8 128.3 176.5 130.4 176.5H248.1C250.2 176.5 251.9 174.8 251.9 172.7C251.9 170.6 250.2 168.9 248.1 168.9Z"
      fill="#546AA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M252.4 165.2H125.4C123.3 165.2 121.6 166.9 121.6 169C121.6 171.1 123.3 172.8 125.4 172.8H252.4C254.5 172.8 256.2 171.1 256.2 169C256.1 166.9 254.4 165.2 252.4 165.2Z"
      fill="#546AA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.4 165.2H125.4C123.3 165.2 121.6 166.9 121.6 169C121.6 171.1 123.3 172.8 125.4 172.8H175.4C177.5 172.8 179.2 171.1 179.2 169C179.2 166.9 177.5 165.2 175.4 165.2Z"
      fill="#546AA2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.9 67.1C259.8 69.8 281.8 74.2 288.8 83.2C293.4 89.1 294 96.2 286.4 96.2C278.8 96.2 248 96.2 248 96.2L248.9 67.1Z"
      fill="#C7CFEC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.5 115.3L156.7 128.2C156.7 128.2 148.2 104.7 156.6 84.2C165 63.7 197 62.1 210.1 60.9C223.3 59.7 240.1 96.1 240.1 96.1L171.5 115.3Z"
      fill="#C7CFEC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M225.6 131.8C225.6 131.8 253.6 138.2 257.2 139C260.7 139.8 270.3 149.4 272 158.5C273.7 167.7 289.2 284.2 289.2 284.2C289.2 284.2 285.2 291.2 284.7 291.3C284.2 291.4 276.6 287.8 276.6 287.8C276.6 287.8 249.6 189 246.5 180.3C243.4 171.6 244.2 165.1 234.6 165.1C225 165.1 205.9 165.1 205.9 165.1L198.5 139.8L201.5 136.9C198.3 137.1 195.7 137.1 195.3 136.8C193.9 136 172.6 128.2 172.6 128.2C172.6 128.2 187.9 123.6 189.2 123.1C190.6 122.6 212.5 120.8 212.5 120.8C212.5 120.8 212.6 126.8 213.6 128.1C214.7 129.4 217 129.9 217 129.9L225.6 131.8Z"
      fill="#8493C9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M158.4 102.3C158.2 110.2 157.9 118.6 156.6 122.9C157.1 125.7 156.6 128.3 156.6 128.3L156.4 128.9C156.6 129.5 156.7 129.9 156.7 129.9C160.2 132.6 163.9 132.7 168.2 134.3V133.7L168 132.9L180.5 138.9C184.7 138.5 211.7 136.7 218.4 130.2C217.8 123.9 244.7 106.1 252.1 104.4L158.4 102.3Z"
      fill="#C7CFEC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M285.9 286.2C285.9 286.2 287.1 283.5 290 284.3C292.9 285.1 310.2 294.6 312.9 295.8C315.6 297 318.4 301.4 316.3 304H275.3C272.9 302.5 270.5 297.5 273 288.8C275.5 280 274.9 289.2 285.9 286.2Z"
      fill="#DCEAFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M285.9 286.2C285.9 286.2 287.1 283.5 290 284.3C291.8 284.8 299.2 288.7 305.1 291.8C303.4 293.1 299.6 294.3 298.8 294.1C297.8 293.9 285.9 286.2 285.9 286.2Z"
      fill="#A8C0DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M272.6 304H317C317.7 304 318.2 303.5 318.2 302.8V300.6C318.2 299.9 317.7 299.4 317 299.4H272.6C271.9 299.4 271.4 299.9 271.4 300.6V302.8C271.4 303.5 271.9 304 272.6 304Z"
      fill="#A8C0DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.2 165.1C149.1 165.1 148.4 136.9 156.7 128.2C156.7 128.2 160.9 131.5 167.5 131.4C178.5 131.3 204.1 125.1 211.9 135.6C219.7 146.1 222.6 163.6 218.1 189.1C213.6 214.6 202.3 288 202.3 288L189.4 286.9C189.4 286.9 191.3 183.4 191.5 177.9C191.7 172.4 188.8 165 182.9 165C177 165.2 165.2 165.1 165.2 165.1Z"
      fill="#849DD9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.7 287C199.7 287 194.8 280.9 188.8 285.8C188.8 285.8 185.5 287.1 184.3 290.2C183.1 293.3 180.8 294.7 180.4 298C180 301.4 180.9 304 185.2 304C189.5 304 189.1 304 192.1 304C195.2 304 199.7 304.5 201.3 300.4C202.9 296.3 201.3 292.6 201.8 290.4C202.4 288.3 199.7 287 199.7 287Z"
      fill="#DCEAFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.7 287C199.7 287 194.8 280.9 188.8 285.8C188.8 285.8 188.2 286.1 187.4 286.6C187.1 289.3 186.8 292.1 187 292.8C187.5 294.3 195.2 293.9 196.3 293.4C197.1 293 199 289.1 199.8 287.1C199.8 287 199.7 287 199.7 287Z"
      fill="#A8C0DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M180.9 304H201.1C201.8 304 202.3 303.5 202.3 302.8V300.6C202.3 299.9 201.8 299.4 201.1 299.4H180.9C180.2 299.4 179.7 299.9 179.7 300.6V302.8C179.7 303.5 180.2 304 180.9 304Z"
      fill="#A8C0DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.8 113.5C188.6 110.4 185.9 108 182.8 108.2C179.7 108.4 177.3 111.1 177.5 114.2C178.3 126.2 179.6 139.6 181.6 154.4H78.4C80.4 139.7 82.1 126.4 83.4 114.4C83.7 111.3 81.5 108.5 78.4 108.2C75.3 107.9 72.5 110.1 72.2 113.2C69.7 136.8 65.8 165.6 60 200.6C55.1 230.4 48.8 264.6 40.8 303.9H52.4C60.2 265.3 66.4 231.7 71.2 202.4C73.4 189.3 75.2 177.1 76.9 165.7H183.2C184.9 177.1 186.9 189.4 189.3 202.5C194.7 231.8 202.1 265.4 212 303.9H223.7C213.4 264.5 205.9 230.3 200.4 200.5C197.7 185.8 195.5 172.3 193.8 159.7C193.8 159.4 193.7 159.1 193.7 158.9C191.2 142.1 189.7 127 188.8 113.5Z"
      fill="#577CB1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M432.2 100.3H173.6C170.6 100.3 168.1 102.8 168.1 105.8V118.1C168.1 121.1 170.6 123.6 173.6 123.6H432.2C435.2 123.6 437.7 121.1 437.7 118.1V105.8C437.7 102.8 435.2 100.3 432.2 100.3Z"
      fill="url(#paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M432.2 100.3H116.5C113.5 100.3 111 102.8 111 105.8V113.1H219.2H437.7V105.8C437.7 102.8 435.2 100.3 432.2 100.3Z"
      fill="#6875B0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M437.7 96.1H168.1C164.8 96.1 162.2 98.8 162.2 102C162.2 105.3 164.9 107.9 168.1 107.9H437.6C440.9 107.9 443.5 105.2 443.5 102C443.6 98.8 440.9 96.1 437.7 96.1Z"
      fill="url(#paint2_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.3 100.3H71.7C68.7 100.3 66.2 102.8 66.2 105.8V118.1C66.2 121.1 68.7 123.6 71.7 123.6H190.3C193.3 123.6 195.8 121.1 195.8 118.1V105.8C195.8 102.8 193.4 100.3 190.3 100.3Z"
      fill="#577CB1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M187.7 100.3H74.4C69.9 100.3 66.3 102.8 66.3 105.8V113.1H195.9V105.8C195.8 102.8 192.2 100.3 187.7 100.3Z"
      fill="#4B6EA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M195.8 96.1H63C59.7 96.1 57.1 98.8 57.1 102C57.1 105.3 59.8 107.9 63 107.9H195.8C199.1 107.9 201.7 105.2 201.7 102C201.8 98.8 199.1 96.1 195.8 96.1Z"
      fill="#577CB1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.5 96.1C259.5 96.1 253.8 89.6 255.2 81.9C256.6 74.2 256.7 59.3 248.6 55.1C240.5 50.9 230.5 51.5 226.2 57.5C226.2 57.5 223.9 54.2 221.3 54.3C218.7 54.4 220.9 58.6 221.5 59.1C194.9 50.8 218.6 90.7 199.8 96.1C199.8 96.1 259.5 96.1 259.5 96.1Z"
      fill="#878CC9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.5 96.1C259.5 96.1 253.8 89.6 255.2 81.9C255.4 80.9 255.6 79.7 255.7 78.4C246.9 81.5 226.3 87.5 216.9 78C212 73 209.6 67.6 208.5 63C206 71.2 211 87.1 204.1 93.7C206.3 94.3 208.8 95.1 211.6 96.1H259.5Z"
      fill="#878BC8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M287.9 96.1C293.3 92 290.1 77.9 277.1 81.8C267.9 84.5 252.2 90 250.2 90.4C247.6 91 241.6 90.4 240.1 90.4C238.6 90.4 234.8 91.9 234.8 96.1H287.2H287.9Z"
      fill="#F8C3BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.1 96.1C162.1 96.1 158.2 94.2 158.6 89.4C159 84.6 164.8 79.8 169.2 79.3C173.6 78.8 221.1 89.5 225.5 90.1C230 90.7 239.9 90.1 242.5 90.1C245 90.1 251.5 91.7 251.5 96.2H163.2H162.1V96.1Z"
      fill="#F8C3BD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M294.4 58C294.4 58 293.7 55.4 291.5 55.3C289.3 55.2 288.5 57.9 289.2 58.2C289.8 58.6 294.4 58 294.4 58Z"
      fill="#F2D8CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M292.5 62.6L293.1 62.5L295.1 62.1C292.7 68.7 286 67.7 288.7 62.7L292 62V62.3C292 62.5 292.2 62.7 292.4 62.6H292.5Z"
      fill="#F2D8CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M395.2 0H301.6C299 0 296.5 2.1 296 4.7L282.8 68C282.3 70.6 283.9 72.7 286.5 72.7H380.1C382.7 72.7 385.2 70.6 385.7 68L398.9 4.6C399.4 2.1 397.8 0 395.2 0Z"
      fill="#8FB0D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M395.2 0H304.7C302.1 0 299.6 2.1 299.1 4.7L285.8 68C285.3 70.6 286.9 72.7 289.5 72.7H380C382.6 72.7 385.1 70.6 385.6 68L398.8 4.6C399.4 2.1 397.8 0 395.2 0Z"
      fill="#DCEAFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M384.4 47.3H295.5C293.9 47.3 292.4 48.7 292.1 50.5L289 67C288.7 68.7 289.7 70.2 291.2 70.2H380.1C381.7 70.2 383.2 68.8 383.5 67L386.6 50.5C386.9 48.8 385.9 47.3 384.4 47.3Z"
      fill="#C7CFEC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M394.6 5.6H303.7C302.7 5.6 301.7 6.4 301.5 7.4C301.3 8.4 301.9 9.2 302.9 9.2H393.8C394.8 9.2 395.8 8.4 396 7.4C396.2 6.4 395.6 5.6 394.6 5.6Z"
      fill="#C9CEEC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M330.2 52.3C329.5 52.3 328.3 52.5 328.3 51.7C328.3 51 328.9 50.4 329.6 50.4H356.2C357 50.4 361.4 50.5 361.7 55.7C361.8 57.4 362.5 62.8 363.4 68.6C364.2 74.2 365.1 80.6 365.7 84.1C365.8 85.1 365.9 86.1 365.9 87.1C365.9 89.1 365.5 91.1 364.6 92.7C363.6 94.4 362 95.6 359.5 96C359 96.1 358.5 96.1 358 96.1H329.6C328.9 96.1 328.3 95.5 328.3 94.8C328.3 94.1 329.5 94.4 330.3 94.4L335.1 93.5C335.5 93.5 335.9 93.5 336.3 93.4C337.9 93.2 338.9 92.4 339.5 91.4C340.2 90.3 340.4 88.8 340.4 87.2C340.4 86.3 340.3 85.5 340.2 84.6C339.7 81.2 338.5 75.1 337.6 69C336.8 63.1 336 57.7 335.9 55.9C335.7 53.2 333.5 53.1 333.2 53.1H333.1L330.2 52.3Z"
      fill="#8FB0D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M328.2 53.1C327.5 53.1 326.9 52.5 326.9 51.8C326.9 51.1 327.5 50.5 328.2 50.5H330.9C331.7 50.5 336.1 50.6 336.4 55.8C336.5 57.5 337.2 62.9 338.1 68.7C338.9 74.3 339.8 80.7 340.4 84.2C340.5 85.2 340.6 86.2 340.6 87.2C340.6 89.2 340.2 91.2 339.3 92.8C338.3 94.5 336.7 95.7 334.2 96.1C333.7 96.2 333.2 96.2 332.7 96.2H325.4C324.7 96.2 324.1 95.6 324.1 94.9C324.1 94.2 324.7 93.6 325.4 93.6H332.7C333.1 93.6 333.5 93.6 333.9 93.5C335.5 93.3 336.5 92.5 337.1 91.5C337.8 90.4 338 88.9 338 87.3C338 86.4 337.9 85.6 337.8 84.7C337.3 81.3 336.4 75.2 335.5 69.1C334.7 63.2 333.9 57.8 333.8 56C333.6 53.3 331.4 53.2 331.1 53.2H331H328.2V53.1Z"
      fill="#DCEAFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.6 184.1C97.2 176.4 100.9 193.2 95.2 207.1C89.5 221.1 60.5 268 59 286.6C58.4 293.8 57.1 293 55.9 288C50.2 262.7 62.6 194.7 82.6 184.1Z"
      fill="#9DD2F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8 120.2C35.1 105.2 24.7 126.2 27.6 146.5C30.5 166.8 53.4 238.4 49.3 263.4C47.7 273.1 49.7 272.5 52.9 266.2C68.7 234.8 74.8 140.9 51.8 120.2Z"
      fill="#9DD2F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.9 270.4C49.3 271.3 50.1 270.9 51.1 269.3C68.5 211.3 41.4 124.8 41.3 124.5C41.2 124 40.6 123.8 40.2 123.9C39.7 124 39.5 124.6 39.6 125C39.7 125.3 67.1 213.1 48.9 270.4Z"
      fill="#7DB5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3 168.4C-0.899988 161.4 -3.39999 179.6 3.80001 194C11 208.4 45.9 256 49.1 275.7C50.3 283.3 51.7 282.4 52.5 276.9C56.5 249.5 37.6 178.1 15.3 168.4Z"
      fill="#9DD2F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5 281C51 281.6 51.5 281.1 51.9 279.6C50.5 232.1 8.70001 174.4 8.60001 174.2C8.40001 173.9 7.90001 173.8 7.60001 174C7.30001 174.2 7.20001 174.7 7.40001 175C7.60001 175.2 49.9 233.7 50.5 281Z"
      fill="#7DB5D8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.1 271.6H69.2L77.3 279L70.7 304.5H53.6H36.6L30 279L38.1 271.6Z"
      fill="url(#paint3_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 261H77.3C78.7 261 79.9 262.2 79.9 263.6V275.3C79.9 276.7 78.7 277.9 77.3 277.9H30C28.6 277.9 27.4 276.7 27.4 275.3V263.6C27.4 262.1 28.6 261 30 261Z"
      fill="url(#paint4_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.3 279L75.9 284.2H31.4L30 279H77.3Z"
      fill="#6875B0"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.8 211.8C190.6 219.9 190.5 228.8 190.3 237.5V209.3C190.5 210.1 190.7 210.9 190.8 211.8Z"
      fill="url(#paint5_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="277.224"
        y1="206.036"
        x2="460.024"
        y2="206.036"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#5C65A1" />
        <stop offset="1" stopColor="#7C549A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="168.124"
        y1="111.95"
        x2="437.724"
        y2="111.95"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#5C65A1" />
        <stop offset="1" stopColor="#7C549A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="162.224"
        y1="102"
        x2="443.527"
        y2="102"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#5C65A1" />
        <stop offset="0.4779" stopColor="#5E64A0" />
        <stop offset="0.7234" stopColor="#66609F" />
        <stop offset="0.9154" stopColor="#73599C" />
        <stop offset="1" stopColor="#7C549A" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="30.0242"
        y1="288.05"
        x2="77.3242"
        y2="288.05"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#5C65A1" />
        <stop offset="1" stopColor="#7C549A" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="27.4242"
        y1="269.45"
        x2="79.9242"
        y2="269.45"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#5C65A1" />
        <stop offset="1" stopColor="#7C549A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="190.355"
        y1="223.4"
        x2="190.846"
        y2="223.4"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#59ACFE" />
        <stop offset="1" stopColor="#E559D6" />
      </linearGradient>
    </defs>
  </svg>
)
export default ErrorPageIllustration

import { CheckCircleOutline } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import toast, { ToastBar, Toaster } from 'react-hot-toast'

import { colors } from 'layout/themeColors/colors'

export function CustomToaster() {
  return (
    <Toaster
      // zIndex is 1 000 000 + 1 in order to be on top of Crisp
      containerStyle={{ zIndex: 1000001 }}
      position="bottom-right"
      toastOptions={{
        style: {
          maxWidth: '400px',
          width: '400px',
          minHeight: '80px',
          borderRadius: '8px',
        },
      }}>
      {(t) => {
        return (
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible ? 'slide-in 0.1s forwards' : 'slide-out 0.1s forwards',
              padding: 0,
            }}>
            {({ icon, message }) => {
              const isSuccess = t.type === 'success'
              const isLoading = t.type === 'loading'
              return (
                <Stack direction="row" alignItems="center" height="100%" width="100%">
                  <Box
                    sx={{
                      background: isSuccess
                        ? colors.success
                        : isLoading
                        ? colors.accent6
                        : colors.statusError,
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '60px',
                      display: 'flex',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                    }}>
                    {isSuccess && <CheckCircleOutline sx={{ color: colors.white }} />}
                    {!isSuccess && icon}
                  </Box>
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    p={1}
                    alignItems="center">
                    <Box width="fit-content">{message}</Box>
                    {!isLoading && (
                      <Button
                        onClick={() => {
                          toast.dismiss(t.id)
                        }}>
                        OK
                      </Button>
                    )}
                  </Stack>
                </Stack>
              )
            }}
          </ToastBar>
        )
      }}
    </Toaster>
  )
}

import { ColorType } from 'grommet/utils'
import { TFunction } from 'i18next'
import getConfig from 'next/config'
import React from 'react'

import { policies } from 'config/usePlanPrices'
import { routes } from 'lib/routes'

import FunkyThemeIcon from 'components/icons/myPage/FunkyTheme'
import ProfessionalThemeIcon from 'components/icons/myPage/ProfessionalTheme'
import StandardThemeIcon from 'components/icons/myPage/StandardTheme'
import { colors } from 'layout/themeColors/colors'
import { FunkyThemePage } from 'scenes/MyPage/WebsiteBuilder/templates/FunkyThemePage'
import { ProfessionalThemePage } from 'scenes/MyPage/WebsiteBuilder/templates/ProfessionalThemePage'
import { StandardThemePage } from 'scenes/MyPage/WebsiteBuilder/templates/StandardThemePage'
import { UpcomingEvent } from 'services/api/Meetings'
import { UserTypeCustomer, UserTypeProvider } from 'services/api/Users'
import {
  Bank,
  BookableService,
  BookableServices,
  ImageType,
  PersistedProviderWebsite,
} from 'services/auth/ssp'

import {
  TEMPLATE_1_1,
  TEMPLATE_1_2,
  TEMPLATE_1_3,
  TEMPLATE_1_4,
  TEMPLATE_2_1,
  TEMPLATE_2_2,
  TEMPLATE_2_3,
  TEMPLATE_3_1,
  TEMPLATE_3_2,
  TEMPLATE_3_3,
  TemplateImages,
} from './websiteBuilderTemplateImages'
import { isWhitelabel, WHITELABEL_ENVS } from './whitelabelEnvs'

const {
  publicRuntimeConfig: {
    CDN_URL,
    isGermanLanguageOn,
    isSpanishLanguageOn,
    isBulgarianLanguageOn,
    isItalianLanguageOn,
    isVietnameseLanguageOn,
    PLATFORM_PRES_URL,
    PLATFORM_DEFAULT_SUBDOMAIN,
  },
} = getConfig()

export const SUBDOMAIN_REPLACE_REGEX = /[^\w]/gi

export const USER_ROLE: {
  PROVIDER: UserTypeProvider['id']
  CUSTOMER: UserTypeCustomer['id']
} = {
  PROVIDER: 2,
  CUSTOMER: 3,
}
export type UserRole = { id: number; label: 'provider' | 'customer' }
export const USER_PROVIDER: UserRole = { id: USER_ROLE.PROVIDER, label: 'provider' }
export const USER_CUSTOMER: UserRole = { id: USER_ROLE.CUSTOMER, label: 'customer' }
export const USER_ROLES: UserRole[] = [USER_PROVIDER, USER_CUSTOMER]

export const subscriptionTypes = {
  PREMIUM: 'premium',
  BASIC: 'basic',
}

export const ERROR_CODES = {
  CLIENT_ERROR: 400,
  NOT_AUTHENTICATED: 401,
  PAYMENT_REQUIRED: 402,
  NOT_AUTHORIZED: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
}
export type Language = {
  value: string
  label: string
}
export const LANGUAGES: Language[] = [
  { value: 'en', label: 'English' },
  ...(isBulgarianLanguageOn ? [{ value: 'bg', label: 'Български' }] : []),
  ...(isItalianLanguageOn ? [{ value: 'it', label: 'Italiano' }] : []),
  ...(isGermanLanguageOn ? [{ value: 'de', label: 'Deutsch' }] : []),
  ...(isSpanishLanguageOn ? [{ value: 'es', label: 'Spanish' }] : []),
  ...(isVietnameseLanguageOn ? [{ value: 'vn', label: 'Tiếng Việt' }] : []),
]

export type ListedOnMarketplaceOption = {
  id: number
  translateKey: string
  value: boolean
}

export const ListOnMarketplaceOptions: {
  [key: string]: ListedOnMarketplaceOption
} = {
  YES: { id: 0, translateKey: 'yes', value: true },
  NO: { id: 1, translateKey: 'no', value: false },
}

// TODO iv whitelabel
export const TEAM_MEMBER_NAME_GETTING_STARTED_CALL_INVITE = 'Nikola'
export const TEAM_MEMBER_BOOKING_PAGE_GETTING_STARTED_CALL_INVITE =
  'https://nikola.3veta.com/booking'
export const TESTIMONIAL_NAME = 'Kate Park'
export const TESTIMONIAL_AVATAR =
  'https://3veta.com/wp-content/uploads/2022/01/Kate-primerno-min.jpg'

export const PLAN_FEATURES = [
  ...(policies.basic.can_use_video || policies.premium.can_use_video
    ? [
        {
          name: 'all-video-features',
          proOnly: !policies.basic.can_use_video && policies.premium.can_use_video,
        },
      ]
    : []),
  { name: 'all-scheduling-features', proOnly: false },
  { name: 'booking-page', proOnly: false },
  { name: 'all-payment-features', proOnly: false },
  { name: 'fully-white-label', proOnly: true },
  { name: 'integrations', proOnly: true },
]

export const CURRENCIES = [
  'usd',
  'eur',
  'cad',
  'gbp',
  'bgn',
  'czk',
  'dkk',
  'huf',
  'nok',
  'pln',
  'ron',
  'sek',
  'chf',
  'aud',
  'nzd',
  'jpy',
  'brl',
  'mxn',
  'sgd',
  'myr',
  'inr',
  'aed',
]

// eslint-disable-next-line @typescript-eslint/ban-types
export type Option = string | boolean | number | JSX.Element | object

export const FALLBACK_LANG = 'en'

export type MarketplaceCategory = {
  id: string
  max_selected: number | null // null means infinity
  list_order: number
  display_name: string
  marketplace_category_options: MarketplaceCategoryOption[]
}

export type UserMarketplaceCategoryOption = {
  id: string
  display_name: string
}

export type MarketplaceCategoryOption = UserMarketplaceCategoryOption & {
  marketplace_category_id: string
}

export type UserMarketplaceCategoryOptions = {
  [key: string]: UserMarketplaceCategoryOption[]
}

export type DateRange = { dateFrom: Date; dateTo: Date }

export type Country = {
  name: string
  iso_country_code?: string
  default_iso_currency_code?: string
  default_iso_language_code?: string
}

export type OnboardingStep =
  | 'name-country'
  | 'team-name'
  | 'calendar-connect'
  | 'add-photo'
  | 'ref-source-industry'

export const ONBOARDING_STEPS: {
  [key: string]: { id: number; path: OnboardingStep }
} = {
  NAME_COUNTRY: { id: 0, path: 'name-country' },
  TEAM_NAME: { id: 1, path: 'team-name' },
  CALENDAR_CONNECT: { id: 2, path: 'calendar-connect' },
  ADD_PHOTO: { id: 3, path: 'add-photo' },
  REF_SOURCE_INDUSTRY: { id: 4, path: 'ref-source-industry' },
}

export const ONBOARDING_REF_SOURCES: {
  [key: string]: { id: number; name: string }
} = {
  REF_SOURCE_FACEBOOK: { id: 0, name: 'ref-source-facebook' },
  REF_SOURCE_GOOGLE: { id: 1, name: 'ref-source-google' },
  REF_SOURCE_BLOG_POST: { id: 2, name: 'ref-source-blog-post' },
  REF_SOURCE_WORD_OF_MOUTH: { id: 3, name: 'ref-source-word-of-mouth' },
  REF_SOURCE_LINKEDIN: { id: 4, name: 'ref-source-linkedin' },
  REF_SOURCE_OTHER: { id: 5, name: 'ref-source-other' },
}

export type Industry = {
  id: number
  name: string
}

export const INDUSTRIES: {
  [key: string]: { id: number; name: string }
} = {
  INDUSTRY_SALES: { id: 0, name: 'industry-sales' },
  INDUSTRY_MARKETING: { id: 1, name: 'industry-marketing' },
  INDUSTRY_HR_RECRUITING: { id: 2, name: 'industry-hr-recruiting' },
  INDUSTRY_FREELANCE_CONSULTING: { id: 3, name: 'industry-freelance-consulting' },
  INDUSTRY_COACHING: { id: 4, name: 'industry-coaching' },
  INDUSTRY_CUSTOMER_ACCESS: { id: 5, name: 'industry-customer-success' },
  INDUSTRY_COUNSELLING: { id: 6, name: 'industry-counselling' },
  INDUSTRY_DESIGN_CREATIVE: { id: 7, name: 'industry-design-creative' },
  INDUSTRY_EDUCATION: { id: 8, name: 'industry-education' },
  INDUSTRY_PRODUCT_MANAGEMENT: { id: 9, name: 'industry-product-management' },
  INDUSTRY_FINANCE_ACCOUNTING: { id: 10, name: 'industry-finance-accounting' },
  INDUSTRY_LEGAL: { id: 11, name: 'industry-legal' },
  INDUSTRY_OPERATIONS: { id: 12, name: 'industry-operations' },
  INDUSTRY_PROJECT_MANAGEMENT: { id: 13, name: 'industry-project-management' },
  INDUSTRY_STUDENT: { id: 14, name: 'industry-student' },
  INDUSTRY_MEDICAL_HEALTH: { id: 15, name: 'industry-medical-health' },
  INDUSTRY_SPORTS_FITNESS: { id: 16, name: 'industry-sports-fitness' },
  INDUSTRY_NONPROFITS: { id: 17, name: 'industry-nonprofits' },
  INDUSTRY_EVENTS_ENTERTAINMENT: {
    id: 18,
    name: 'industry-events-entertainment',
  },
  INDUSTRY_IT: { id: 19, name: 'industry-it' },
  INDUSTRY_OTHER: { id: 20, name: 'industry-other' },
}

export const defaultLogo = `${CDN_URL}static/logo.png`

export const MOBILE_APP_LINKS = {
  ios: 'https://apps.apple.com/us/app/3veta/id1549208659',
  android: 'https://play.google.com/store/apps/details?id=com.threeveta',
}

// duplicated in the backend as PaddleSubscriptionStatusesEnum
// change one - change the other!
export enum SUBSCRIPTION_STATUSES_ENUM {
  ACTIVE = 'active',
  TRIALING = 'trialing',
  MANUAL_TRIALING = 'manual_trialing',
  PAST_DUE = 'past_due',
  PAUSED = 'paused',
  DELETED = 'deleted',
}

// duplicated in the backend as TeamMemberRolesEnum
// change one - change the other!
export enum TEAM_MEMBER_ROLES_ENUM {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
}

export const MEETING_PAYMENT_TYPE: { [key: string]: string } = {
  FREE: 'free',
  BY_MEETING: 'by-meeting',
  BY_ATTENDEE: 'by-attendee',
}

export const SHARED_WITH_TYPE = {
  PERSONAL: 'personal',
  TEAM: 'team',
}

export type TabObject<T = string> = {
  value: number
  label: T
}
export type NestedTabObject<T = string> = {
  childIndex: number
  parentIndex: number
  label: T
}
export const MEETING_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const MEETING_RATING_PROBLEM_TRIGGER = 3
export const MEETING_MAX_LENGTH_IN_HOURS = 23
export const SERVICE_MAX_LENGTH_IN_HOURS = 23
export const EMAIL_CHIPS_MAX_NUMBER = 30

export type DefaultBookableService = BookableService

export type WebsiteBuilderTab = 'themes' | 'colors' | 'fonts' | 'home' | 'about' | 'services'

export const WEBSITE_BUILDER_TABS: NestedTabObject<WebsiteBuilderTab>[] = [
  { childIndex: 0, parentIndex: 0, label: 'themes' },
  { childIndex: 1, parentIndex: 0, label: 'colors' },
  { childIndex: 2, parentIndex: 0, label: 'fonts' },
  { childIndex: 0, parentIndex: 1, label: 'home' },
  { childIndex: 1, parentIndex: 1, label: 'about' },
  { childIndex: 2, parentIndex: 1, label: 'services' },
]

export const INTRO_SCRIPT_DEMO = 'Intro Script Demo'
export const SOFIA_SANS = 'Sofia Sans'

export const WEBSITE_BUILDER_FONTS = [
  'Ubuntu',
  'Plovdiv Display',
  'Intro Script Demo',
  'Casper Bold',
  'Roboto',
  'Nunito',
  'Literata',
  'Open Sans',
]

export const DEFAULT_SECONDARY_BANK_LOGO = `${CDN_URL}static/${
  isWhitelabel() ? 'logo_square.png' : '3veta_logo.svg'
}`

export const DEFAULT_SECONDARY_BANK: Bank = {
  logo: DEFAULT_SECONDARY_BANK_LOGO,
  theme: 2,
  palette: 0,
  font: 'Ubuntu',
  services: [],
  homePageImage: TEMPLATE_3_1.homePageImage,
  aboutImage: TEMPLATE_3_1.aboutImage,
  aboutImagePosition: undefined,
  include_bookable_services: true,
}
export const DEFAULT_WEBSITE_DATA: PersistedProviderWebsite = {
  is_live: false,
  banks_are_equal: true,
  secondary_bank: DEFAULT_SECONDARY_BANK,
}

export type ThemeProps<P extends GenericPalette> = {
  t: TFunction
  subdomain: string
  currency?: string
  font: string
  palette: P
  homePageImage?: ImageType
  homeImagePosition?: string
  logo: string
  title?: string
  subtitle?: string
  aboutTitle?: string
  aboutDescription?: string
  aboutImage?: ImageType
  aboutImagePosition?: string
  preview?: boolean
  homeSectionRef?: React.RefObject<HTMLDivElement>
  aboutSectionRef?: React.RefObject<HTMLDivElement>
  providerId: string
  lang?: string
  bookableServices?: BookableServices
  shouldHaveBanner?: boolean
  events?: UpcomingEvent[]
}

export const DEFAULT_TEMPLATE_IMAGES: TemplateImages[][] = [
  [TEMPLATE_1_1, TEMPLATE_1_2, TEMPLATE_1_3, TEMPLATE_1_4],
  [TEMPLATE_2_1, TEMPLATE_2_2, TEMPLATE_2_3],
  [TEMPLATE_3_1, TEMPLATE_3_2, TEMPLATE_3_3],
]

export function zipUpToSmallerSize<T, U>(a: T[], b: U[]): Map<T, U> {
  const result = new Map<T, U>()
  for (let i = 0; i < Math.min(a.length, b.length); i++) {
    result.set(a[i], b[i])
  }
  return result
}

export const defaultTeamMemberColor = colors.accent5

export const teamMemberColorsList: string[] = [
  colors.accent3,
  '#039BE5',
  '#68A03B',
  '#F6BF26',
  '#D50000',
  '#FF5000',
  '#C86FC9',
  '#3F51B5',
  '#009688',
  '#F09300',
  '#FF8360',
  '#AD1457',
  '#A79B8E',
]

function opacityHex(opacity: number) {
  return `0${Math.round((255 / 100) * opacity).toString(16)}`.slice(-2).toUpperCase()
}
export const addAlphaToSolidColor = (hexColor: string, opacity: number) => {
  return hexColor + opacityHex(opacity)
}
export const add30AlphaToSolidColor = (hexColor: string) => {
  return addAlphaToSolidColor(hexColor, 30)
}

/**
 * @source https://stackoverflow.com/a/59906630/668245
 */
type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift'
type FixedLengthArray<T, L extends number, TObj = [T, ...Array<T>]> = Pick<
  TObj,
  Exclude<keyof TObj, ArrayLengthMutationKeys>
> & {
  readonly length: L
  [I: number]: T
  [Symbol.iterator]: () => IterableIterator<T>
}

export type GenericPalette = {
  id: number
  name: string
  fullWidth?: boolean
  colors: {
    header: string // 0
    text: string // 1
    button: string // 2
    services: string // 3
    contact: string // 4
    footer: string // 5
    textHeading: string | undefined // 6
  }
}

export type ProfessionalPalette = GenericPalette
export type FunkyPalette = GenericPalette & {
  colors: {
    buttonBoxShadow: string // 7
    accent1: string // 8
    accent2: string // 9
    funkyCircle1: string // 10
    funkyCircle2: string // 11
    funkyCircle3: string // 12
    funkyCircle4: string // 13
  }
}

export type StandardPalette = GenericPalette

export enum ThemeNames {
  /** Stockholm */
  professional = 0,
  /** Valencia */
  funky = 1,
  /** Lisbon */
  standard = 2,
}

export type PaletteType = ProfessionalPaletteType | FunkyPaletteType | StandardPaletteType

enum ProfessionalPaletteType {
  dalarna = 0,
  vanern = 1,
  tyresta = 2,
}
enum FunkyPaletteType {
  ruzafa = 0,
  iberia = 1,
  albufera = 2,
}
enum StandardPaletteType {
  atlantic = 0,
  roca = 1,
  tagus = 2,
}

export type PaletteInfo<T extends PaletteType, P extends GenericPalette> = {
  id: T
  name: string
  fullWidth?: boolean
} & P

type Palettes = {
  [ThemeNames.professional]: {
    [key in ProfessionalPaletteType]: PaletteInfo<ProfessionalPaletteType, ProfessionalPalette>
  }
  [ThemeNames.funky]: {
    [key in FunkyPaletteType]: PaletteInfo<FunkyPaletteType, FunkyPalette>
  }
  [ThemeNames.standard]: {
    [key in StandardPaletteType]: PaletteInfo<StandardPaletteType, StandardPalette>
  }
}
export const palettes: Palettes = {
  [ThemeNames.standard]: {
    [StandardPaletteType.atlantic]: {
      id: StandardPaletteType.atlantic,
      name: 'Atlantic',
      fullWidth: true,
      colors: {
        header: '#F5F8FD',
        text: '#1D1F50',
        button: '#1627DC',
        services: '#F5F8FD',
        contact: '#E5EFFF',
        footer: colors.dark,
        textHeading: '#1D1F50',
      },
    },
    [StandardPaletteType.roca]: {
      id: StandardPaletteType.roca,
      name: 'Roca',
      colors: {
        header: '#EEE8E6',
        text: '#030D28',
        button: '#000000',
        services: '#F9F9F9',
        contact: '#EEE8E6',
        footer: colors.white,
        textHeading: '#211812',
      },
    },
    [StandardPaletteType.tagus]: {
      id: StandardPaletteType.tagus,
      name: 'Tagus',
      colors: {
        header: '#1E737B',
        text: '#181818',
        button: '#02535B',
        services: '#F5F8FD',
        contact: '#9DE0D9',
        footer: colors.dark,
        textHeading: '#181818',
      },
    },
  },
  [ThemeNames.professional]: {
    [ProfessionalPaletteType.dalarna]: {
      id: ProfessionalPaletteType.dalarna,
      name: 'Dalarna',
      colors: {
        header: '#105248',
        text: '#050C1A',
        button: '#000000',
        services: '#F5F5F5',
        contact: '#22776A',
        footer: colors.white,
        textHeading: undefined, // FIXME: color is missing in theme
      },
    },
    [ProfessionalPaletteType.vanern]: {
      id: ProfessionalPaletteType.vanern,
      name: 'Vanern',
      colors: {
        header: colors.white,
        text: '#07175B',
        button: '#283EAE',
        services: '#F5F8FF',
        contact: colors.white,
        footer: colors.dark,
        textHeading: undefined, // FIXME: color is missing in theme
      },
    },
    [ProfessionalPaletteType.tyresta]: {
      id: ProfessionalPaletteType.tyresta,
      name: 'Tyresta',
      colors: {
        header: '#180E0E',
        text: '#050C1A',
        button: '#AB120C',
        services: '#F5F5F5',
        contact: '#180E0E',
        footer: colors.white,
        textHeading: undefined, // FIXME: color is missing in theme
      },
    },
  },
  [ThemeNames.funky]: {
    [FunkyPaletteType.ruzafa]: {
      id: FunkyPaletteType.ruzafa,
      name: 'Ruzafa',
      colors: {
        header: colors.dark,
        text: '#032875',
        button: '#F4D362',
        services: '#F4F5FF',
        contact: '#58CFA2',
        footer: colors.dark,
        textHeading: '#0543C7',
        buttonBoxShadow: colors.dark,
        accent1: '#F4D362',
        accent2: '#58CFA2',
        funkyCircle1: '#73A0FE',
        funkyCircle2: '#58CFA2',
        funkyCircle3: '#F4D362',
        funkyCircle4: '#73A0FE',
      },
    },
    [FunkyPaletteType.iberia]: {
      id: FunkyPaletteType.iberia,
      name: 'Iberia',
      colors: {
        header: '#FBFBFB',
        text: '#000000',
        button: '#000000',
        services: '#F1F1F1',
        contact: colors.white,
        footer: colors.dark,
        textHeading: '#000000',
        buttonBoxShadow: colors.white,
        accent1: '#000000',
        accent2: '#000000',
        funkyCircle1: '#FFA787',
        funkyCircle2: '#FFA787',
        funkyCircle3: '#FFA787',
        funkyCircle4: '#FFA787',
      },
    },
    [FunkyPaletteType.albufera]: {
      id: FunkyPaletteType.albufera,
      name: 'Albufera',
      colors: {
        header: '#020B5F',
        text: '#020B5F',
        button: '#9EE0FD',
        services: '#F4F5FF',
        contact: '#020B5F',
        footer: '#020B5F',
        textHeading: '#020B5F',
        buttonBoxShadow: '#020B5F',
        accent1: '#9EA8FF',
        accent2: '#3549FA',
        funkyCircle1: '#9EA8FF',
        funkyCircle2: '#9EA8FF',
        funkyCircle3: '#9EE0FD',
        funkyCircle4: '#73A0FE',
      },
    },
  },
}
export type WebsiteBuilderTheme<P extends GenericPalette> = {
  id: ThemeNames
  IconComponent: () => JSX.Element
  labelFontColor: ColorType
  labelLocalizationKey: string
  Component: (props: ThemeProps<P>) => JSX.Element
  palettes: FixedLengthArray<P, 3>
}

const professionalPalettes: FixedLengthArray<ProfessionalPalette, 3> = [
  palettes[ThemeNames.professional][ProfessionalPaletteType.dalarna],
  palettes[ThemeNames.professional][ProfessionalPaletteType.vanern],
  palettes[ThemeNames.professional][ProfessionalPaletteType.tyresta],
]
const funkyPalettes: FixedLengthArray<FunkyPalette, 3> = [
  palettes[ThemeNames.funky][FunkyPaletteType.ruzafa],
  palettes[ThemeNames.funky][FunkyPaletteType.iberia],
  palettes[ThemeNames.funky][FunkyPaletteType.albufera],
]
const standardPalettes: FixedLengthArray<StandardPalette, 3> = [
  palettes[ThemeNames.standard][StandardPaletteType.atlantic],
  palettes[ThemeNames.standard][StandardPaletteType.roca],
  palettes[ThemeNames.standard][StandardPaletteType.tagus],
]

export const WEBSITE_BUILDER_PALETTES = {
  [ThemeNames.professional]: professionalPalettes,
  [ThemeNames.funky]: funkyPalettes,
  [ThemeNames.standard]: standardPalettes,
}

const ProfessionalTheme: WebsiteBuilderTheme<ProfessionalPalette> = {
  id: ThemeNames.professional,
  IconComponent: ProfessionalThemeIcon,
  labelFontColor: colors.white,
  labelLocalizationKey: 'website-builder.builder-form.themes.pro',
  Component: ProfessionalThemePage,
  palettes: WEBSITE_BUILDER_PALETTES[ThemeNames.professional],
}
const FunkyTheme: WebsiteBuilderTheme<FunkyPalette> = {
  id: ThemeNames.funky,
  IconComponent: FunkyThemeIcon,
  labelFontColor: colors.dark,
  labelLocalizationKey: 'website-builder.builder-form.themes.funky',
  Component: FunkyThemePage,
  palettes: WEBSITE_BUILDER_PALETTES[ThemeNames.funky],
}
const StandardTheme: WebsiteBuilderTheme<StandardPalette> = {
  id: ThemeNames.standard,
  IconComponent: StandardThemeIcon,
  labelFontColor: colors.dark,
  labelLocalizationKey: 'website-builder.builder-form.themes.standard',
  Component: StandardThemePage,
  palettes: WEBSITE_BUILDER_PALETTES[ThemeNames.standard],
}

export const WEBSITE_BUILDER_THEMES = [StandardTheme, ProfessionalTheme, FunkyTheme]

export const findTheme = (themeId: ThemeNames) => {
  return WEBSITE_BUILDER_THEMES.find((theme) => theme.id === themeId)
}

export const links = {
  facebook: 'https://facebook.com/3veta',
  instagram: 'https://instagram.com/3veta_/',
  linkedin: 'https://linkedin.com/company/3veta/',
  twitter: 'https://twitter.com/_3veta_',
  termsOfUse: '/terms-of-use', // TODO change to respective provider's ToU
  privacyNotice: '/privacy-notice',
  termsOfUseProviders: '/terms-of-use-providers',
  termsOfUseCustomers: '/terms-of-use-end-users',
  landing: PLATFORM_PRES_URL,
}

export const WEBSITE_BUILDER_PATHS = {
  themes: routes.website.themes,
  colors: routes.website.colors,
  fonts: routes.website.fonts,
  home: routes.website.home,
  about: routes.website.about,
  services: routes.website.services,
  subPathAbout: 'about',
  subPathServices: 'services',
  mainPath: `${routes.website.index}/`,
}

export const COUNTRY_EMOJIS: {
  [key: string]: { emoji: string }
} = {
  bg: { emoji: '🇧🇬' },
  us: { emoji: '🇺🇸' },
  ua: { emoji: '🇺🇦' },
  no: { emoji: '🇳🇴' },
}

export const WEBSITE_BUILDER_MAX_ALLOWED_SERVICES = 50

export const richTextEditorToolbarOptions = {
  options: ['inline', 'list', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline', 'subscript'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
  link: {
    defaultTargetOption: '_blank',
  },
}

export const urlPattern =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const stateToHTMLOptions = {
  entityStyleFn: (entity: any) => {
    const entityType = entity.get('type').toLowerCase()
    if (entityType === 'link') {
      const data = entity.getData()
      return {
        element: 'a',
        attributes: {
          href: data.url,
          target: '_blank',
        },
      }
    }
  },
}

export const WHITELABEL_PLATFORMS_HAVING_MAIN_MARKETPLACE_CATEGORY_BOX = [
  WHITELABEL_ENVS.CONTABI,
  WHITELABEL_ENVS.SPARKLE,
  WHITELABEL_ENVS.DEMO,
]

// Subscription redirect exceptions
export const redirectExceptions = {
  app: [
    routes.subscription,
    routes.error,
    routes.resetPassword,
    routes.resetPasswordSuccessful,
    routes.confirmEmail,
    routes.onboard.personalInfo,
    routes.onboard.teamName,
    routes.onboard.calendarConnect,
    routes.onboard.addPhoto,
    routes.onboard.refSourceIndustry,
    '/_next/data/development/index.json',
  ],
  external: [routes.quickmeet.path, routes.booking.index, routes.meeting.path, routes.room.path],
}

export const DEFAULT_APP_SUBDOMAIN = PLATFORM_DEFAULT_SUBDOMAIN
export const LOGO_URL = `${CDN_URL}static/logo.png`

import { BoxProps, Grid, Heading, ResponsiveContext } from 'grommet'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BookingPagesTemplate } from 'components/BookingPagesTemplate'
import { ProviderUser } from 'services/api/Users'
import { BookableServices } from 'services/auth/ssp'

import { BookableService } from '../components/BookableService'

type Props = {
  services?: BookableServices
  preview: boolean
  mobilePreview?: boolean
  providerUser?: ProviderUser
  shouldHaveBanner?: boolean
  withHeader?: boolean
  showInline?: boolean
  subdomain: string
} & BoxProps
export const ProviderBookingPage = ({
  services,
  preview,
  providerUser,
  shouldHaveBanner,
  withHeader = true,
  subdomain,
  mobilePreview = false,
  showInline = false,
  ...rest
}: Props) => {
  const { t } = useTranslation()

  services?.forEach((service, index) => {
    if (!service.iso_currency_code && providerUser) {
      services[index].iso_currency_code = providerUser.iso_currency_code
    }
  })

  const showPreview = preview && !mobilePreview

  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'

  const servicesColumnCount = useMemo<number>(() => {
    if (isMobile || !services) return 1
    if (services.length <= 3) return services.length
    if (services.length === 4) return 2
    return 3
  }, [services, isMobile])

  return (
    <BookingPagesTemplate
      {...rest}
      style={{
        // Container height is based on the inline property
        minHeight: showInline ? undefined : '100vh',
      }}
      preview={preview}
      mobilePreview={mobilePreview}
      provider={providerUser}
      shouldHaveBanner={shouldHaveBanner}
      withHeader={withHeader}>
      <>
        {services?.length === 0 && (
          <Heading level={2} size="24px" color="#252847" textAlign="center" margin="0">
            {t('website-templates.no-services')}
          </Heading>
        )}
        {services && services?.length > 0 && (
          <Grid
            columns={{ count: servicesColumnCount, size: '300px' }}
            gap="16px"
            align="center"
            justify="center"
            margin="0 auto">
            {services.map((service, index) => (
              <BookableService
                isServiceShortPreview
                key={index}
                index={index}
                service={service}
                preview={showPreview && !mobilePreview}
                hasButton
                subdomain={subdomain}
              />
            ))}
          </Grid>
        )}
      </>
    </BookingPagesTemplate>
  )
}

import React from 'react'

function ThreevetaBallBlackAndWhite({ width, height }: { width?: string; height?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 1280 1280"
      version="1.1"
      viewBox="0 0 1280 1280"
      width={width}
      height={height}
      xmlSpace="preserve">
      <ellipse
        cx="638.6"
        cy="641.6"
        fill="#313131"
        rx="637"
        ry="640"
        transform="rotate(-47.453 638.562 641.628)"></ellipse>
      <path
        fill="#909090"
        d="M636.2 639.5c-56.1-97.2-85.6-207.5-85.6-319.7S580.1 97.3 636.2 0C524.5.7 415 30.5 318.5 86.6c-96.5 56.1-177 135.9-232.8 232.6 55.8 96.7 136.3 177.6 232.8 233.7s206 85.9 317.7 86.6z"></path>
      <path
        fill="#DEDEDE"
        d="M640.2 0h-4c-56.1 97.2-85.6 207.5-85.6 319.7 0 112.2 29.5 222.5 85.6 319.7h4c112.3 0 222.7-29.5 320-85.6 97.3-56.1 178.1-136.9 234.2-234.1-56.1-97.2-136.9-178-234.2-234.1C862.9 29.5 752.5 0 640.2 0z"></path>
      <path
        fill="#909090"
        d="M1194.3 319.8c-56.1 97.3-136.9 178-234.2 234.1-97.3 56.1-207.6 85.7-320 85.6h-4c56.1 97.2 136.9 178 234.2 234.1 97.3 56.1 211.6 85.7 323.9 85.6l5.6-9.4c53.5-96.6 81.1-205.4 80.2-315.7-.9-110.4-30.4-218.7-85.5-314.3h-.2z"></path>
    </svg>
  )
}

export default ThreevetaBallBlackAndWhite

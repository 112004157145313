import getConfig from 'next/config'
import React from 'react'

import { colors } from 'layout/themeColors/colors'

const {
  publicRuntimeConfig: { WHITELABEL_ENV_IS_3VETA },
} = getConfig()

function ThreevetaFullImageBlackAndWhite({ width, height }: { width?: string; height?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 1812 1000"
      preserveAspectRatio="xMidYMid slice"
      width={width}
      height={height}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="721.46"
          x2="993.83"
          y1="566.1"
          y2="811.31"
          gradientTransform="matrix(1 0 0 -1 0 1120.69)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#6b6b6b"></stop>
          <stop offset="0.76" stopColor="#575757"></stop>
          <stop offset="1" stopColor="#2c2c2c"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="808.69"
          x2="836.68"
          y1="779.72"
          y2="943"
          gradientTransform="matrix(1 0 0 -1 0 1120.69)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="gray"></stop>
          <stop offset="1" stopColor="#2e2e2e"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="962.03"
          x2="1016.02"
          y1="728.38"
          y2="978.28"
          gradientTransform="matrix(1 0 0 -1 0 1120.69)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#d1d1d1"></stop>
          <stop offset="0.88" stopColor="#474747"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="1083.17"
          x2="1008.71"
          y1="624.73"
          y2="784.7"
          gradientTransform="matrix(1 0 0 -1 0 1120.69)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stopColor="#a6a6a6"></stop>
          <stop offset="1" stopColor="#525252"></stop>
        </linearGradient>
      </defs>
      <path fill="#9a9a9a" d="M0 0H1812V1000H0z"></path>
      <path
        fill="url(#linear-gradient)"
        d="M1067.1 545.38c87.77-87.75 87.77-230 0-317.77s-230.07-87.75-317.84 0-87.77 230 0 317.77 230.07 87.76 317.84 0z"></path>
      <path
        fill="url(#linear-gradient-2)"
        d="M907.25 385.9a224.84 224.84 0 010-224.69A224.66 224.66 0 00714 273.55 224.64 224.64 0 00907.25 385.9z"></path>
      <path
        fill="url(#linear-gradient-3)"
        d="M908.69 161.2h-1.42a224.9 224.9 0 000 224.69h1.42a224.65 224.65 0 00194.67-112.36A224.62 224.62 0 00908.69 161.2z"></path>
      <path
        fill="url(#linear-gradient-4)"
        d="M1103.34 273.55A224.67 224.67 0 01908.69 385.9h-1.42a224.61 224.61 0 0082.27 82.26c34.17 19.72 74.23 30.1 113.69 30.08l2-3.32a224.95 224.95 0 00-1.85-221.33z"></path>
      {WHITELABEL_ENV_IS_3VETA && (
        <path
          fill={colors.white}
          d="M767.77 833.5c0 20.92-15.78 39-49.1 39-17.55 0-35.09-5.31-46.61-14.35l9.92-17.9c8.86 7.44 22.33 12.23 36.34 12.23 16.48 0 26.23-7.27 26.23-18.79 0-11.17-8.16-18.43-26.94-18.43h-11.35v-15.79L734.44 766h-56.36v-19.34h84.54v15.41l-30.13 35.81c23.4 3.01 35.28 17.01 35.28 35.62zM877 776.08l-40.59 94.64H813.5l-40.59-94.64h23l29.42 70.18 30.31-70.18zM958.83 842.89l11.7 13.65C962.2 866.64 948.91 872 932.6 872c-31.72 0-52.28-20.38-52.28-48.56S900.88 775 929.06 775c26.4 0 47.67 17.73 47.85 47.32l-72.49 14.18c4.61 10.81 14.89 16.67 28.71 16.67 10.64.01 18.79-3.36 25.7-10.28zm-57.07-20.73v.17l53.53-10.28c-3-11.52-12.94-19.31-26.23-19.31-16.13 0-27.3 11.34-27.3 29.42zM1047.32 865.58c-5.32 4.25-13.32 6.42-21.27 6.42-20.74 0-32.61-11-32.61-31.9v-84.9h22.15v21.62h25.35v17.73h-25.35v45c0 9.21 4.79 14.18 13.12 14.18a19.92 19.92 0 0012.41-3.9zM1144.8 815.25v55.47h-20.91V859.2c-5.32 8.15-15.6 12.76-29.78 12.76-21.62 0-35.27-11.87-35.27-28.36 0-15.77 10.64-28.18 39.35-28.18h24.46V814c0-12.93-7.8-20.55-23.58-20.55-10.63 0-21.62 3.54-28.71 9.39l-8.68-16.13c10.1-7.8 24.81-11.7 40-11.7 27.32-.01 43.12 12.99 43.12 40.24zm-22.15 25.87v-11h-22.87c-15.06 0-19.14 5.67-19.14 12.59 0 8 6.74 13.11 18.08 13.11 10.81.01 20.21-4.95 23.93-14.7z"></path>
      )}
    </svg>
  )
}

export default ThreevetaFullImageBlackAndWhite

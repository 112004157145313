import React from 'react'

import { colors } from 'layout/themeColors/colors'

function StandardTheme() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="172"
      height="140"
      fill="none"
      viewBox="0 0 172 140">
      <g>
        <rect width="172" height="140" fill="#A9E8F9" rx="4"></rect>
        <path
          stroke="#3A19BE"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
          d="M172 123.674c-12.076-24.946-20.618-30.163-26.508-29.348-12.224 1.63-15.611 29.511-26.509 29.674-11.045 0-15.021-27.717-26.36-27.88-11.34-.163-15.611 27.391-26.803 27.228-11.34-.163-15.316-28.533-26.362-28.533-10.897 0-14.285 27.392-26.508 29.022C6.912 124.652-1.777 119.109-14 94"></path>
        <circle cx="60" cy="63" r="19" fill="#E8FAFF"></circle>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={colors.white} d="M0 0H172V140H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default StandardTheme

export const colors = {
  white: '#ffffff',
  primaryBrand: '#5b31d4',
  meetingRoomHeader: '#12012b',
  lightText: '#797979',
  accent1: '#5b31d4',
  accent2: '#dd9fff',
  accent3: '#713dab', // used only once-twice, remove when redesigning
  accent4: '#a25bc8', // used only once-twice, remove when redesigning
  accent5: '#5597fb',
  accent6: '#f0bf1d',
  dark: '#110b4a',
  light1: '#f6f9ff',
  light2: '#ecf2ff',
  light3: '#d3e1ff',
  statusError: '#ff4848',
  statusDisabled: '#bdbdbd',
  success: '#4cb14c',
  logoDark: '#252525',
  highlighter: '#fff7d1',
}

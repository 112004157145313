import { Box } from 'grommet'
import React from 'react'

import { colors } from 'layout/themeColors/colors'

function CollapseUpIcon({ pad = { vertical: '10px', left: '10px' } }) {
  return (
    <Box pad={pad}>
      <svg width="10" height="10" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.00006 6.00003L5.00006 1.55559L9.00006 6.00003"
          stroke={colors.primaryBrand}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Box>
  )
}

export default CollapseUpIcon

import getConfig from 'next/config'

import { colors } from 'layout/themeColors/colors'
import { Meeting } from 'services/api/Meetings'
import { Bank, BookableService, ImageType } from 'services/auth/ssp'

import { resolveImage } from './helpers'

const {
  publicRuntimeConfig: { CDN_URL, PLATFORM_NAME },
} = getConfig()

export const description = `The go-to solution for meeting clients online and getting paid. Join ${PLATFORM_NAME} and take part in the online services revolution!`
export const shortSlogan = `Join ${PLATFORM_NAME} and take part in the online services revolution!`
export const defaultOgImage = `${CDN_URL}static/og-image.png`

export const meta = [
  { charSet: 'utf-8' },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
  },
  {
    key: 'og:title',
    property: 'og:title',
    content: `Dashboard | ${PLATFORM_NAME}`,
  },
  { key: 'description', name: 'description', content: description },
  { key: 'og:description', property: 'og:description', content: description },
  {
    key: 'og:image',
    property: 'og:image',
    content: defaultOgImage,
  },
  { key: 'og:image:width', property: 'og:image:width', content: '1812' },
  { key: 'og:image:height', property: 'og:image:height', content: '1000' },
  { key: 'og:type', property: 'og:type', content: 'website' },
  { key: 'og:site_name', property: 'og:site_name', content: PLATFORM_NAME },
  { name: 'msapplication-TileColor', content: '#da532c' },
  { name: 'theme-color', content: colors.white },
]

type Links = {
  rel?: string
  href: string
  type?: string
  sizes?: string
  color?: string
}
export const links: Links[] = [
  {
    rel: 'icon',
    href: '/favicon.ico',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon-16x16.png',
  },
  {
    rel: 'manifest',
    href: '/site.webmanifest',
  },
  {
    rel: 'mask-icon',
    href: '/safari-pinned-tab.svg',
    color: '#5bbad5',
  },
  {
    href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Nunito:wght@400;500;600;700&family=Source+Sans+Pro:wght@400;600;700&family=Literata:wght@300;500&family=Nunito:wght@300;600&family=Open+Sans:wght@300;400;600&family=Ubuntu:wght@300;500&display=swap',
    rel: 'stylesheet',
  },
]

export const ogImageFromWebsite = (providerWebsite: Bank): string => {
  return ogImageFromObject(providerWebsite?.homePageImage)
}

export const ogImageFromBookingPage = (service: BookableService): string => {
  return ogImageFromObject(resolveImage(service?.image, defaultOgImage))
}

export const ogImageFromEvent = (event: Meeting): string => {
  return event.cover_image || defaultOgImage
}

const ogImageFromObject = (image?: ImageType | null): string => {
  if (!image) {
    return defaultOgImage
  }

  // CDN
  if (typeof image === 'string') {
    if (image.startsWith('http')) {
      return image
    }
  }
  // Unsplash
  else if (image?.regular) {
    return image?.regular
  }

  return defaultOgImage
}

import LocationOnIcon from '@mui/icons-material/LocationOn'
import PaymentIcon from '@mui/icons-material/Payment'
import {
  Anchor,
  Box,
  BoxExtendedProps,
  Button,
  Heading,
  Image,
  ResponsiveContext,
  Text,
} from 'grommet'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { centsToCurrency, resolveRawImage } from 'lib/helpers'
import { getLocationObject } from 'lib/meeting-location'
import { routes } from 'lib/routes'

import { colors } from 'layout/themeColors/colors'
import { BookableService as BookableServiceType } from 'services/auth/ssp'

import DisplayDuration from './DisplayDuration'

type Props = {
  service: BookableServiceType
  index: number
  preview?: boolean
  hasButton?: boolean
  isServiceShortPreview?: boolean
  subdomain: string
  isFirstMobileStep?: boolean
} & BoxExtendedProps

export const BookableService = ({
  service,
  index,
  preview,
  hasButton = false,
  isServiceShortPreview = true,
  subdomain,
  isFirstMobileStep,
  ...props
}: Props) => {
  const { t } = useTranslation()
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'
  const router = useRouter()
  const {
    id,
    image,
    price_in_cents,
    name,
    description,
    iso_currency_code,
    image_position,
    duration,
    is_free,
    btn_label,
    btn_color,
  } = service
  const parsedImage = resolveRawImage(image)
  const serviceTitleSize = '18px'
  const paragraphSize = isMobile ? '12px' : '14px'
  const shouldAppendDateTimePickerQuery =
    isFirstMobileStep || (isMobile && router.pathname === routes.booking.index)
  const bookNowQuery = shouldAppendDateTimePickerQuery ? '?show_date_time_picker=true' : ''
  const locationObject = getLocationObject(service.meeting_location)
  const serviceUrl = useMemo(
    () => routes.booking.viewService(subdomain, service.id),
    [subdomain, service],
  )

  const serviceImage = parsedImage ? (
    <Image
      style={{
        objectFit: 'cover',
        objectPosition: image_position ?? undefined,
        borderRadius: '6px',
        width: isMobile ? '100%' : isServiceShortPreview ? '250px' : 'auto',
        height: isMobile ? '100%' : isServiceShortPreview ? '250px' : 'auto',
      }}
      src={
        typeof parsedImage === 'object'
          ? preview
            ? parsedImage.small
            : parsedImage.regular
          : parsedImage
      }
    />
  ) : undefined

  const serviceHeading = (
    <Heading
      size={serviceTitleSize}
      color="#1D1F50"
      style={{
        height: isServiceShortPreview ? '24px' : undefined,
        overflow: isServiceShortPreview ? 'hidden' : undefined,
        whiteSpace: isServiceShortPreview ? 'nowrap' : undefined,
        textOverflow: isServiceShortPreview ? 'ellipsis' : undefined,
      }}>
      {!name && preview ? t('website-builder.builder-form.home.title') : name}
    </Heading>
  )

  return (
    <Box
      height={isMobile ? 'fit-content' : '100%'}
      round="6px"
      gap="small"
      width={isMobile ? '100%' : '300px'}
      alignSelf={!isMobile ? 'start' : undefined}
      justify="between"
      background={colors.white}
      pad={isMobile ? '18px' : { horizontal: '24px', bottom: '24px', top: '12px' }}
      className={`bookable-services service-${index}`}
      {...props}>
      <Box gap="small">
        {isServiceShortPreview ? (
          <Anchor href={serviceUrl} style={{ textDecoration: 'none' }}>
            {serviceHeading}
          </Anchor>
        ) : (
          serviceHeading
        )}
        {serviceImage &&
          (isServiceShortPreview ? (
            <Anchor href={serviceUrl} style={{ textDecoration: 'none', height: '250px' }}>
              {serviceImage}
            </Anchor>
          ) : (
            serviceImage
          ))}
        {!serviceImage && isServiceShortPreview && !isMobile && (
          <Box style={{ width: '250px', height: '250px' }} />
        )}
        <Box>
          {!description && preview ? (
            <Text size={paragraphSize} color="#1D1F50" style={{ padding: '10px 0' }} weight={400}>
              {t('website-builder.builder-form.home.description')}
            </Text>
          ) : (
            <Box pad={{ vertical: '10px' }} justify="between" fill>
              {description && (
                <div
                  dangerouslySetInnerHTML={{ __html: description || '' }}
                  style={{
                    color: '#1D1F50',
                    fontSize: paragraphSize,
                    height: isServiceShortPreview ? '24px' : 'auto',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box gap="small">
        {isServiceShortPreview && (
          <Box direction="row" align="center" justify="between">
            <Link href={routes.booking.viewService(subdomain, id)} passHref legacyBehavior>
              <Button plain color={colors.accent5} label={t('website-templates.read-more')} />
            </Link>
            <PaymentInfo
              is_free={is_free}
              price_in_cents={price_in_cents}
              iso_currency_code={iso_currency_code}
            />
          </Box>
        )}
        <Box direction="row" justify="between" align="center">
          <DisplayDuration duration={duration} />
          {locationObject && (
            <Box direction="row" align="center">
              <LocationOnIcon color="primary" />
              <Text size="14px" weight={600}>
                {locationObject.isOnline
                  ? t('website-builder.builder-form.home.online')
                  : t('website-builder.builder-form.home.offline')}
              </Text>
            </Box>
          )}
        </Box>

        {hasButton && (
          <Link
            href={routes.booking.viewService(subdomain, id + bookNowQuery)}
            passHref
            legacyBehavior>
            <Button
              primary
              size="medium"
              style={{ textAlign: 'center' }}
              color={btn_color || colors.primaryBrand}
              label={btn_label || t('website-templates.service-button-main-label')}
            />
          </Link>
        )}
      </Box>
    </Box>
  )
}

const PaymentInfo = ({
  is_free,
  price_in_cents,
  iso_currency_code,
}: Pick<BookableServiceType, 'is_free' | 'price_in_cents' | 'iso_currency_code'>) => {
  const { t } = useTranslation()
  if (is_free) return <div />
  return (
    <Box direction="row" align="center" gap="xsmall">
      <PaymentIcon color="primary" />
      <Text size="14px" color="#1D1F50" weight={600}>
        {price_in_cents &&
          centsToCurrency(price_in_cents, t('currency_code.' + iso_currency_code)).withCurrency}
      </Text>
    </Box>
  )
}

import { Anchor, Box, Button, Image, Text } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { generateEventStartEndTime, useClientSideRender } from 'lib/helpers'
import { assembleSubdomainAddress, routes } from 'lib/routes'

import { useUser } from 'components/contexts/UserProvider'
import { colors } from 'layout/themeColors/colors'
import { UpcomingEvent } from 'services/api/Meetings'

import { EventLocationAndPriceLabels } from './EventLocationAndPriceLabels'

interface EventCardProps {
  event: UpcomingEvent
  subdomain?: string
}

interface EventDateTimeProps {
  start_time: string
  end_time: string
  timezone: string | null
}

const EventDateTime = ({ end_time, start_time, timezone }: EventDateTimeProps) => {
  return (
    <Text size="16px" color={colors.dark} weight={400}>
      {generateEventStartEndTime(new Date(start_time), new Date(end_time), timezone, true)}
    </Text>
  )
}

export function EventCard({ event, subdomain }: EventCardProps) {
  const { t } = useTranslation()
  const [user] = useUser()

  if (!subdomain) return null

  return (
    <Box
      justify="between"
      height="fit-content"
      width="300px"
      direction="column"
      gap="small"
      round="4px"
      background={colors.light2}
      style={{ boxShadow: `0px 0px 0px 1px ${colors.primaryBrand}` }}>
      {event.cover_image && (
        <Image style={{ borderRadius: '4px' }} src={event.cover_image} width="100%" />
      )}
      <Box
        pad={{ left: 'medium', right: 'medium', top: event.cover_image ? undefined : 'small' }}
        gap="small">
        <Text size="20px" weight={700}>
          {event.name}
        </Text>
        {useClientSideRender(
          <EventDateTime
            end_time={event.end_time}
            start_time={event.start_time}
            timezone={user?.timezone ?? null}
          />,
        )}
        <EventLocationAndPriceLabels event={event} fontSize="16px" />
      </Box>
      <Anchor href={assembleSubdomainAddress(subdomain, routes.events.view + '/' + event.id)}>
        <Button
          primary
          style={{ borderRadius: '4px', padding: '12px', width: '100%' }}
          label={<Text>{t('events.see-details')}</Text>}
        />
      </Anchor>
    </Box>
  )
}

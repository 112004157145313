import { AxiosError } from 'axios'

import { ERROR_CODES } from '../lib/constants'

import HttpError from './HttpError'

export const baseErrorHandler = (error: AxiosError) => {
  console.log('baseErrorHandler', { error })
  switch (error.response?.status) {
    case ERROR_CODES.CLIENT_ERROR:
      return new HttpError(
        error.response.data.error,
        error.response.status,
        error.response.data?.extra,
      )

    default:
      throw error
  }
}

export const providerWebsiteHandler = (error: AxiosError) => {
  switch (error.response?.status) {
    case ERROR_CODES.CLIENT_ERROR:
    case ERROR_CODES.NOT_FOUND:
      return new HttpError(error.response.data.error, error.response.status)
    default:
      throw error
  }
}

export const bookMeetingHandler = (error: AxiosError) => {
  switch (error.response?.status) {
    case ERROR_CODES.CLIENT_ERROR:
    case ERROR_CODES.NOT_AUTHORIZED:
      return new HttpError(error.response.data.error, error.response.status)
    default:
      throw error
  }
}

import getConfig from 'next/config'
import { useCallback, useMemo, useState } from 'react'

import useGTM from './useGTM'

declare global {
  interface Window {
    CookieControl: any
  }
}

const {
  publicRuntimeConfig: { THREEVETA_ENV },
} = getConfig()

export default function useCookieConsent(isUsingGoogleOptimize = false) {
  const { trackEvent } = useGTM()
  const [initialized, setInitialized] = useState(false)

  const config = useMemo(
    () => ({
      apiKey: 'd2ef5dc85f8594b40019bc0d4e1374de662d23ae',
      product: 'PRO',
      initialState: 'notify',
      rejectButton: false,
      notifyOnce: false,
      notifyDismissButton: false,
      statement: {
        description: 'For more information visit our',
        name: 'Cookie Notice',
        url: 'https://3veta.com/cookie-notice',
        updated: '22/12/2020',
      },
      necessaryCookies: [
        'authToken*',
        'CookieControl',
        'next-i18n-lang*',
        '__stripe_*',
        'm',
        'crisp*',
        '__cfduid',
        'stonlyWidget*',
        ...(isUsingGoogleOptimize ? ['_ga*'] : []),
      ],
      optionalCookies: [
        {
          name: 'analytics',
          label: 'Analytical and functional',
          description:
            'We use cookies to track and report website traffic and user behavior to analyze how you use it. We receive summarized and statistical information, incl. number of visitors to the site for a given period, most visited pages, the time in which each page was viewed, etc. We use this information to develop the platform by adding new features and services, as well as to attract new customers.',
          cookies: ['_dc_gtm_UA-173917941-2', '_ga', '_gat_UA-173917941-2', '_gid', 'NID'],
          onAccept: () =>
            trackEvent({
              event: 'cookie_consent_analytics_accepted',
              consent_analytics: true,
            }),
          onRevoke: () =>
            trackEvent({
              event: 'cookie_consent_analytics_rejected',
              consent_analytics: false,
            }),
        },
        {
          name: 'marketing',
          label: 'Advertising',
          description:
            'To be able to provide you with content that meets your interests, we use advertising cookies, which provide us with information created through visits to various websites. We use this data to create behavioral advertising through which we can provide you with accurate and timely information and / or services.',
          cookies: ['fr', '_fbp'],
          onAccept: () =>
            trackEvent({
              event: 'cookie_consent_marketing_accepted',
              consent_marketing: true,
            }),
          onRevoke: () =>
            trackEvent({
              event: 'cookie_consent_marketing_rejected',
              consent_marketing: false,
            }),
        },
      ],
      position: 'LEFT',
      theme: 'LIGHT',
      branding: {
        fontColor: '#000',
        fontSizeTitle: '1.2em',
        fontSizeIntro: '1em',
        fontSizeHeaders: '1em',
        fontSize: '0.8em',
        backgroundColor: '#ffce31',
        toggleText: '#fff',
        toggleColor: '#2f2f5f',
        toggleBackground: '#111125',
        buttonIcon: null,
        buttonIconWidth: '0px',
        buttonIconHeight: '0px',
        removeIcon: true,
        removeAbout: true,
      },
      excludedCountries: ['all'],
      text: {
        title: 'This website uses cookies and other technologies that are stored on your device.',
        intro:
          'Some of these cookies and technologies are absolutely necessary for the proper functioning of the website, while others help us improve your experience by providing information about how visitors use the site.\n' +
          'Full information about the cookies and technologies used, the purposes and grounds for the processing of the data created through them is contained in our Declaration on the use of cookies.\n',
        necessaryTitle: 'Necessary',
        necessaryDescription:
          'These cookies and technologies are necessary for the proper functioning of the website and can only be deactivated by changing the preferences of your browser, which will lead to improper functioning of the website and the platform.',
      },
      locales: [
        {
          locale: 'bg',
          location: ['BG'],
          statement: {
            description: 'За повече информация: ',
            name: 'Декларация за използване на бисквитки',
            url: 'https://3veta.com/bg/cookie-notice',
            updated: '22/12/2020',
          },
          text: {
            accept: 'Приемам',
            reject: 'Отказвам',
            settings: 'Настройки',
            title:
              'Този уебсайт използва бисквитки и други технологии, които се запазват на Вашето устройство.',
            intro:
              'Някои от тези бисквитки и технологии са абсолютно необходими за правилното функциониране на сайта, докато други ни помагат да подобрим Вашето преживяване, като предоставят информация за начина, по който посетителите използват сайта.\n' +
              'Пълна информация за използваните бисквитки и технологии, целите и основанията за обработката на създадените чрез тях данни се съдържа в нашата Декларация за използване на бисквитки\n',
            acceptSettings: 'Приемам',
            acceptRecommended: 'Приемам препоръчаните настройки',
            rejectSettings: 'Отказвам',
            necessaryTitle: 'Задължителни',
            necessaryDescription:
              'Тези бисквитки и технологии са необходими за правилното функциониране на сайта и могат да бъдат деактивирани само чрез промяна на предпочитанията на Вашия браузър, което ще доведе до неправилно функциониране на сайта и платформата.',
            thirdPartyTitle: 'Някои изискват Вашето внимание',
            thirdPartyDescription:
              'Автоматичното оттегляне на съгласие за следните бисквитки не е възможно. Моля, използвайте линка по-долу, за да оттеглите съгласието си ръчно.',
            on: 'Вкл.',
            off: 'Изкл.',
            notifyTitle: 'Вашите предпочитания за използване на бисквитки на този уебсайт',
            notifyDescription:
              'Ние използваме бисквитки, за да оптимизираме работата на този уебсайт и да Ви предоставим възможно най-добро преживяване.',
            closeLabel: 'Затвори настройките на бисквитките',
            cornerButton: 'Настройте предпочитания за бисквитки',
            landmark: 'Предпочитания за бисквитки',
            showVendors: 'Показване на доставчици в тази категория',
            thirdPartyCookies: 'Този доставчик може да определи собствени бисквитки',
            readMore: 'Прочетете повече',
          },
          optionalCookies: [
            {
              label: 'Аналитични и функционални',
              description:
                'Ние използваме бисквитки за проследяване и отчитане на трафика на уебсайта и поведението на потребителите, за да анализираме как го ползвате. Получаваме обобщена и статистическа информация, вкл.  брой посетители на сайта за даден период, най-посещавани страници, времето, в което е разглеждана всяка страница и др. Използваме тази информация, за да развиваме платформата, като добавяме нови функционалности и услуги, както и за да привличаме нови клиенти.',
            },
            {
              label: 'Рекламни',
              description:
                'За да сме в състояние да Ви предоставим съдържание, което съответства на Вашите интереси, използваме рекламни бисквитки, които ни предоставят информация, създадена чрез посещения на различни сайтове. Използваме  тези данни за създаване на поведенческа реклама, чрез  която може да Ви предоставим точна и навременна информация и/или услуги.',
            },
          ],
        },
        {
          locale: 'de',
          location: ['DE', 'AT'],
          statement: {
            description: 'Weitere Informationen',
            name: 'erhalten Sie in unserem Cookie-Hinweis.',
            url: 'https://3veta.com/cookie-notice',
            updated: '22/12/2020',
          },
          text: {
            accept: 'Annehmen',
            reject: 'Ablehnen',
            settings: 'Einstellungen',
            title:
              'Diese Website verwendet Cookies und andere Technologien, die auf Ihrem Gerät gespeichert werden.',
            intro:
              'Einige dieser Cookies und Technologien sind essenziell, damit die Website korrekt ausgeführt werden kann. Andere helfen uns, Ihre Benutzererfahrung zu optimieren, indem sie uns verständlich machen, wie Benutzer unsere Website verwenden.\n' +
              'Vollständige Informationen dazu, wie die Cookies und Technologien verwendet werden, zum Zweck und zur Grundlage für die Verarbeitung der mit ihnen generierten Daten finden Sie in unserer Erklärung zur Nutzung der Cookies.\n',
            acceptSettings: 'Annehmen',
            acceptRecommended: 'Empfohlene Einstellungen annehmen',
            rejectSettings: 'Ablehnen',
            necessaryTitle: 'Nur notwendige Cookies',
            necessaryDescription:
              'Diese Cookies und Technologien sind essenziell, damit die Website korrekt ausgeführt werden kann. Sie können daher nur über die Einstellungen in Ihrem Browser deaktiviert werden. Die Website und die Plattform werden dann aber nicht mehr korrekt ausgeführt.',
            thirdPartyTitle: 'Einige Cookies müssen durch Sie überprüft werden.',
            thirdPartyDescription:
              'Die Zustimmung zu den folgenden Cookies konnte nicht automatisch zurückgenommen werden. Bitte folgen Sie dem/den unten stehenden Link(s), um diesen Schritt manuell durchzuführen.',
            on: 'An',
            off: 'Aus',
            notifyTitle: 'Ihre Auswahl zu Cookies auf dieser Website',
            notifyDescription:
              'Wir verwenden Cookies, um die Funktionalität der Website sowie Ihr Benutzererlebnis zu optimieren.',
            closeLabel: 'Cookie-Einstellungen schließen',
            cornerButton: 'Cookie-Einstellungen bestätigen',
            landmark: 'Cookie-Einstellungen',
            showVendors: 'Anbieter in dieser Kategorie anzeigen',
            thirdPartyCookies: 'Dieser Anbieter legt ggf. externe Cookies von Drittanbietern fest.',
            readMore: 'Mehr',
          },
          optionalCookies: [
            {
              label: 'Funktional und Analysen',
              description:
                'Wir verwenden Cookies zum Tracking von Website-Traffic und Benutzerverhalten. Dabei erhalten wir aggregierte, statistische Informationen, so etwa zur Anzahl der Besucher in einem bestimmten Zeitraum, den am meisten besuchten Seiten, der Zeit, die auf jeder Seite verbracht wurde, etc. Wir nutzen diese Informationen, um die Plattform weiterzuentwickeln, so z. B. mit neuen Funktionen und Services, und um sie für bestehende und potenzielle Kunden noch interessanter zu machen.',
            },
            {
              label: 'Werbung',
              description:
                'Um Ihnen Inhalte bereitstellen zu können, die für Sie relevant sind, nutzen wir Werbe-Cookies, die uns aus Besuchen auf verschiedenen Websites generierte Informationen übermitteln. Wir nutzen diese Daten, um verhaltensbasierte Werbeinhalte zu erstellen, mithilfe derer wir Ihnen korrekte und zeitlich relevante Informationen und/oder Services zur Verfügung stellen können.',
            },
          ],
        },
        {
          locale: 'it',
          location: ['IT'],
          statement: {
            description: 'Per maggiori informazioni',
            name: 'visita la nostra Informativa sui cookie',
            url: 'https://3veta.com/it/cookie-notice',
            updated: '22/12/2020',
          },
          text: {
            accept: 'Accetto',
            reject: 'Non Accetto',
            settings: 'Impostazioni',
            title:
              'Questo sito web utilizza cookie e altre tecnologie che vengono memorizzate sul tuo dispositivo.',
            intro:
              'Alcuni di questi cookie e tecnologie sono assolutamente necessari per il corretto funzionamento del sito web, mentre altri ci aiutano a migliorare la tua esperienza fornendo informazioni su come i visitatori utilizzano il sito.\n' +
              "L'informativa completa sui cookie e sulle tecnologie utilizzate, sulle finalità e sui motivi del trattamento dei dati da essi creati è contenuta nella nostra Dichiarazione sull'uso dei cookie.\n",
            acceptSettings: 'Accetto',
            acceptRecommended: 'Accetta le impostazioni consigliate',
            rejectSettings: 'Non Accetto',
            necessaryTitle: 'Necessarie',
            necessaryDescription:
              'Questi cookie e tecnologie sono necessari per il corretto funzionamento del sito web e possono essere disattivati solo modificando le preferenze del tuo browser, il che renderà inutilizzabili il sito web e la piattaforma.',
            thirdPartyTitle: 'Alcuni cookie richiedono la tua attenzione',
            thirdPartyDescription:
              'Il consenso per i seguenti cookie non può essere revocato automaticamente. Segui i link sottostanti per disattivare manualmente.',
            on: 'Attivo',
            off: 'Disattivato',
            notifyTitle: 'La tua scelta in merito ai cookie su questo sito',
            notifyDescription:
              'Utilizziamo i cookie per ottimizzare la funzionalità del sito e offrirti la migliore esperienza possibile.',
            closeLabel: 'Chiudi le impostazioni dei cookie',
            cornerButton: 'Impostare le preferenze sui cookie',
            landmark: 'Preferenze sui cookie',
            showVendors: "Mostra i fornitori all'interno di questa categoria",
            thirdPartyCookies: 'Questo fornitore può impostare cookie di terze parti.',
            readMore: 'Read more',
          },
          optionalCookies: [
            {
              label: 'Analitici e di funzionalità',
              description:
                "Utilizziamo i cookie per tracciare e segnalare il traffico del sito Web e il comportamento degli utenti per analizzare come lo si utilizza. Riceviamo informazioni riepilogative e statistiche, incluso il numero di visitatori del sito per un determinato periodo, le pagine più visitate, l'ora in cui ogni pagina è stata visualizzata, ecc. Usiamo queste informazioni per sviluppare la piattaforma aggiungendo nuove funzionalità e servizi, e per attirare nuovi clienti.",
            },
            {
              label: 'Pubblicità',
              description:
                'Per essere in grado di fornirti contenuti che soddisfino i tuoi interessi, utilizziamo cookie pubblicitari, che ci forniscono informazioni create tramite visite a vari siti web. Utilizziamo questi dati per creare pubblicità comportamentale attraverso la quale possiamo fornirti informazioni e / o servizi accurati e tempestivi.',
            },
          ],
        },
      ],
    }),
    [trackEvent],
  )

  const initialize = useCallback(
    ({ onLoad }: { onLoad: () => void }) => {
      if (initialized) {
        return
      }
      setInitialized(true)

      if (THREEVETA_ENV === 'development') {
        // Turn on GTM for development mode
        // onLoad()
        return
      }

      window.CookieControl?.load({
        ...config,
        onLoad: () => {
          onLoad()

          const notifyBar = document.getElementById('ccc-notify')
          const cookieConsentElement = document.getElementById('ccc')

          if (notifyBar && cookieConsentElement) {
            document.documentElement.style.overflow = 'hidden'
            new MutationObserver(function (mutations) {
              mutations.forEach(function (mutation) {
                if (document.getElementById('ccc-overlay')) {
                  document.documentElement.style.overflow = 'hidden'
                } else {
                  document.documentElement.style.overflow = 'auto'
                }
              })
            }).observe(cookieConsentElement, {
              childList: true,
              subtree: true,
            })
          }
        },
      })
    },
    [config, initialized],
  )

  return { initialize }
}

// duplicated in the backend/sql
// change one - change the other!
import React, { useMemo } from 'react'

import { useApi } from 'config/api'
import { routes } from 'lib/routes'

import { useStrictUser } from 'components/contexts/UserProvider'
import LogoSquare from 'components/icons/common/LogoSquare'
import { ExternalLinkIcon } from 'components/icons/common/MeetingLocationIcons/ExternalLinkIcon'
import { PhysicalLocationIcon } from 'components/icons/common/MeetingLocationIcons/PhysicalLocationIcon'
import MeetingRoomsEndpoints, { MeetingRoomFromApi } from 'services/api/MeetingRooms'

export enum Location {
  InternalMeetingLink = 'internal-meeting-link',
  ExternalMeetingLink = 'external-meeting-link',
  PhysicalLocation = 'physical-location',
  PermanentMeetingRoom = 'meeting-room',
}

export const QUICKMEET_NAME = 'Quickmeet'

export function generateQuickmeetRoomObject(userId: string, subdomain: string): MeetingRoomFromApi {
  return {
    id: userId, // use user.id instead of meeting_location_room_id
    user_id: userId,
    name: QUICKMEET_NAME,
    path_name: routes.quickmeet.bySubdomain(subdomain),
    subdomain: subdomain,
  }
}

export type MeetingLocation = {
  location: Location
  allowAdditionalInfo: boolean
  requireAdditionalInfo: boolean
  additionalInfoShouldBeLink: boolean
  isOnline: boolean
  canBeJoinedOrBooked: boolean
  icon: (props: any) => React.ReactElement
}
// duplicated in the backend/sql
// change one - change the other!
export const MEETING_LOCATION_TYPE: {
  [key: string]: MeetingLocation
} = {
  INTERNAL_MEETING_LINK: {
    location: Location.InternalMeetingLink,
    allowAdditionalInfo: false,
    requireAdditionalInfo: false,
    additionalInfoShouldBeLink: false,
    isOnline: true,
    canBeJoinedOrBooked: true,
    icon: LogoSquare,
  },
  MEETING_ROOM: {
    location: Location.PermanentMeetingRoom,
    allowAdditionalInfo: false,
    requireAdditionalInfo: false,
    additionalInfoShouldBeLink: false,
    isOnline: true,
    canBeJoinedOrBooked: true,
    icon: LogoSquare,
  },
  EXTERNAL_MEETING_LINK: {
    location: Location.ExternalMeetingLink,
    allowAdditionalInfo: true,
    requireAdditionalInfo: true,
    additionalInfoShouldBeLink: true,
    isOnline: true,
    canBeJoinedOrBooked: true,
    icon: ExternalLinkIcon,
  },
  PHYSICAL_LOCATION: {
    location: Location.PhysicalLocation,
    allowAdditionalInfo: true,
    requireAdditionalInfo: true,
    additionalInfoShouldBeLink: false,
    isOnline: false,
    canBeJoinedOrBooked: false,
    icon: PhysicalLocationIcon,
  },
}

export function generateMeetingLocationType(canUseVideo: boolean): {
  [key: string]: MeetingLocation
} {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { INTERNAL_MEETING_LINK, MEETING_ROOM, ...rest } = MEETING_LOCATION_TYPE
  return {
    ...(canUseVideo && {
      INTERNAL_MEETING_LINK: MEETING_LOCATION_TYPE.INTERNAL_MEETING_LINK,
      MEETING_ROOM: MEETING_LOCATION_TYPE.MEETING_ROOM,
    }),
    ...rest,
  }
}

export function getLocationObject(location?: Location) {
  if (!location) return
  return Object.values(MEETING_LOCATION_TYPE).find((value) => value.location === location)
}

export function getMeetingRoomName(
  meetingRoom: MeetingRoomFromApi | null | undefined,
  locationIsQuickmeet: boolean | undefined,
) {
  if (locationIsQuickmeet) {
    return QUICKMEET_NAME
  }
  if (!meetingRoom) return ''
  return meetingRoom.name
}

export const useMeetingRooms = () => {
  const [user] = useStrictUser()
  const [{ data, loading }] = useApi<{
    team_rooms: MeetingRoomFromApi[]
    personal_rooms: MeetingRoomFromApi[]
  }>(MeetingRoomsEndpoints.list(), { useCache: false })

  const meetingRooms: MeetingRoomFromApi[] = useMemo(
    () => data?.team_rooms?.concat(data?.personal_rooms) ?? [],
    [data],
  )
  const quickMeetRoom = useMemo(() => generateQuickmeetRoomObject(user.id, user.subdomain), [user])

  return useMemo(
    () => ({ meetingRooms: [quickMeetRoom, ...meetingRooms], loading }),
    [quickMeetRoom, meetingRooms, loading],
  )
}

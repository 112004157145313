import { UnsplashImage } from 'scenes/MyPage/WebsiteBuilder/components/unsplash'

export type TemplateImages = {
  homePageImage: UnsplashImage
  aboutImage?: UnsplashImage
}
const base = 'https://images.unsplash.com/'

export const TEMPLATE_1_1: TemplateImages = {
  homePageImage: {
    raw: `${base}flagged/photo-1575623196339-2ce1a4e7e9f0?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}flagged/photo-1575623196339-2ce1a4e7e9f0?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}flagged/photo-1575623196339-2ce1a4e7e9f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}flagged/photo-1575623196339-2ce1a4e7e9f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}flagged/photo-1575623196339-2ce1a4e7e9f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}flagged/photo-1575623196339-2ce1a4e7e9f0?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_1_2: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1480074568708-e7b720bb3f09?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1480074568708-e7b720bb3f09?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1480074568708-e7b720bb3f09?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1480074568708-e7b720bb3f09?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1480074568708-e7b720bb3f09?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1480074568708-e7b720bb3f09?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_1_3: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1571844305128-244233caa679?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1571844305128-244233caa679?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1571844305128-244233caa679?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1571844305128-244233caa679?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1571844305128-244233caa679?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1571844305128-244233caa679?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_1_4: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1507679799987-c73779587ccf?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1507679799987-c73779587ccf?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1507679799987-c73779587ccf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1507679799987-c73779587ccf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1507679799987-c73779587ccf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1507679799987-c73779587ccf?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_2_1: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1563992891888-3a441b92e7c7?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1563992891888-3a441b92e7c7?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1563992891888-3a441b92e7c7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1563992891888-3a441b92e7c7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1563992891888-3a441b92e7c7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1563992891888-3a441b92e7c7?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
  aboutImage: {
    raw: `${base}photo-1563993297290-609c9406efcd?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1563993297290-609c9406efcd?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1563993297290-609c9406efcd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1563993297290-609c9406efcd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1563993297290-609c9406efcd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1563993297290-609c9406efcd?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_2_2: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1600275669439-14e40452d20b?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1600275669439-14e40452d20b?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1600275669439-14e40452d20b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1600275669439-14e40452d20b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1600275669439-14e40452d20b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1600275669439-14e40452d20b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
  aboutImage: {
    raw: `${base}photo-1600275669177-176b3c586d22?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1600275669177-176b3c586d22?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1600275669177-176b3c586d22?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1600275669177-176b3c586d22?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1600275669177-176b3c586d22?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1600275669177-176b3c586d22?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_2_3: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1523464862212-d6631d073194?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1523464862212-d6631d073194?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1523464862212-d6631d073194?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1523464862212-d6631d073194?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1523464862212-d6631d073194?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1523464862212-d6631d073194?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
  aboutImage: {
    raw: `${base}photo-1523464896048-06ae115fed74?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1523464896048-06ae115fed74?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1523464896048-06ae115fed74?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1523464896048-06ae115fed74?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1523464896048-06ae115fed74?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1523464896048-06ae115fed74?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_3_1: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1571019613454-1cb2f99b2d8b?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1571019613454-1cb2f99b2d8b?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1571019613454-1cb2f99b2d8b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1571019613454-1cb2f99b2d8b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1571019613454-1cb2f99b2d8b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1571019613454-1cb2f99b2d8b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
  aboutImage: {
    raw: `${base}photo-1571019613531-fbeaeb790845?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1571019613531-fbeaeb790845?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1571019613531-fbeaeb790845?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1571019613531-fbeaeb790845?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1571019613531-fbeaeb790845?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1571019613531-fbeaeb790845?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_3_2: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1518310383802-640c2de311b2?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1518310383802-640c2de311b2?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1518310383802-640c2de311b2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1518310383802-640c2de311b2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1518310383802-640c2de311b2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1518310383802-640c2de311b2?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
  aboutImage: {
    raw: `${base}photo-1518609571773-39b7d303a87b?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1518609571773-39b7d303a87b?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1518609571773-39b7d303a87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1518609571773-39b7d303a87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1518609571773-39b7d303a87b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1518609571773-39b7d303a87b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

export const TEMPLATE_3_3: TemplateImages = {
  homePageImage: {
    raw: `${base}photo-1603988363607-e1e4a66962c6?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1603988363607-e1e4a66962c6?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1603988363607-e1e4a66962c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1603988363607-e1e4a66962c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1603988363607-e1e4a66962c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1603988363607-e1e4a66962c6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
  aboutImage: {
    raw: `${base}photo-1544367567-0f2fcb009e0b?ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1`,
    full: `${base}photo-1544367567-0f2fcb009e0b?crop=entropy&cs=srgb&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=85`,
    regular: `${base}photo-1544367567-0f2fcb009e0b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=1080`,
    small: `${base}photo-1544367567-0f2fcb009e0b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=400`,
    thumb: `${base}photo-1544367567-0f2fcb009e0b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxNDE1MHwwfDF8YWxsfHx8fHx8fHw&ixlib=rb-1.2.1&q=80&w=200`,
    custom: `${base}photo-1544367567-0f2fcb009e0b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=&h=&rect=&fit=crop`,
  },
}

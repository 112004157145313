import { Anchor, ResponsiveContext, Text } from 'grommet'
import getConfig from 'next/config'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { links } from 'lib/constants'

import { BaseBanner } from './BaseBanner'

const {
  publicRuntimeConfig: { DOMAIN, PLATFORM_NAME },
} = getConfig()

export function SloganBanner() {
  const { t } = useTranslation()
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'

  return (
    <BaseBanner>
      <Text textAlign="start" size={isMobile ? '11px' : '16px'}>
        {t('website-templates.banner-before-link')} &nbsp;
        <Anchor href={links.landing} target="_blank" style={{ textDecoration: 'none' }}>
          <Text size={isMobile ? '11px' : '16px'}>{`${DOMAIN}.`}</Text>
        </Anchor>{' '}
        &nbsp;
        {!isMobile && t('website-templates.banner-after-link', { PLATFORM_NAME: PLATFORM_NAME })}
      </Text>
    </BaseBanner>
  )
}

import React from 'react'

import { colors } from 'layout/themeColors/colors'

interface RedirectIconProps {
  width?: number
  height?: number
}

function RedirectIcon({ width = 16, height = 16 }: RedirectIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill={colors.primaryBrand}
        d="M13.174 2.23l.595.596-6.311 6.312-.596-.596 6.312-6.312z"></path>
      <path
        fill={colors.primaryBrand}
        d="M13.895 6.316h-.842V2.947H9.684v-.842h4.21v4.21zM11.79 13.895H3.367a1.24 1.24 0 01-1.263-1.263V4.21a1.24 1.24 0 011.263-1.263H8v.842H3.368c-.252 0-.42.169-.42.421v8.421c0 .253.168.421.42.421h8.421c.253 0 .421-.168.421-.42V8h.843v4.632a1.24 1.24 0 01-1.264 1.263z"></path>
    </svg>
  )
}

export default RedirectIcon

import React from 'react'

import { colors } from 'layout/themeColors/colors'

const SelectUpIcon = ({ color = colors.primaryBrand }) => (
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.61614 5.28618L4.86175 2.47977L4.50491 2.11618L4.14806 2.47977L1.39242 5.28747L0.7018 4.58876L4.50452 0.714213L8.29979 4.58962L7.61614 5.28618Z"
      fill={color}
      stroke={color}
    />
  </svg>
)

export default SelectUpIcon

import { Grommet } from 'grommet'
import { deepMerge } from 'grommet/utils'
import getConfig from 'next/config'
import { useMemo } from 'react'

import { theme } from './grommetTheme'
import { colors } from './themeColors/colors'

const {
  publicRuntimeConfig: {
    featureFlags: { isPaasDemoThemeColorPickerEnabled },
  },
} = getConfig()

// eslint-disable-next-line @typescript-eslint/ban-types
export const GrommetLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const customTheme = useMemo(
    () =>
      deepMerge(theme, {
        global: {
          colors: { ...colors, brand: colors.primaryBrand },
        },
      }),
    [colors.primaryBrand],
  )

  return (
    <div className="App">
      <Grommet
        theme={isPaasDemoThemeColorPickerEnabled ? customTheme : theme}
        style={{ width: '100%' }}>
        {children}
      </Grommet>
    </div>
  )
}

type inAppBrowserInfoType = {
  userAgentRegex: RegExp
  name: string
}

type inAppBrowsersInfoType = {
  [key: string]: inAppBrowserInfoType
}

const inAppBrowsersInfo: inAppBrowsersInfoType = {
  messenger: {
    userAgentRegex: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
    name: 'Messenger',
  },
  facebook: {
    userAgentRegex: /\bFB[\w_]+\//,
    name: 'Facebook',
  },
  twitter: {
    userAgentRegex: /\bTwitter/i,
    name: 'Twitter',
  },
  instagram: {
    userAgentRegex: /\bInstagram/i,
    name: 'Instagram',
  },
}

export const isInAppBrowser = () => {
  if (!(typeof window !== 'undefined')) {
    return false
  }

  const allRegexes = Object.values(inAppBrowsersInfo).map((value) => value.userAgentRegex)

  for (let i = 0; i < allRegexes.length; i++) {
    if (allRegexes[i].test(navigator?.userAgent)) {
      return true
    }
  }

  return false
}

export const getCurrentInAppBrowserName = (): string | null => {
  if (!(typeof window !== 'undefined')) {
    return null
  }

  const inAppBrowsers = Object.values(inAppBrowsersInfo)

  for (let i = 0; i < inAppBrowsers.length; i++) {
    if (inAppBrowsers[i].userAgentRegex.test(navigator?.userAgent)) {
      return inAppBrowsers[i].name
    }
  }

  return null
}

import { Box } from '@mui/material'
import getConfig from 'next/config'
import Image from 'next/image'
import React from 'react'

import { LOGO_URL } from 'lib/constants'

const {
  publicRuntimeConfig: { PLATFORM_NAME },
} = getConfig()

type Props = {
  forcedLogo?: string
}

function FullLogoNot3veta({ forcedLogo }: Props) {
  const logoUrl = forcedLogo || LOGO_URL
  return (
    <Box sx={{ height: '65px' }}>
      <Image
        alt={PLATFORM_NAME}
        width={200}
        height={100}
        style={{ width: 'auto', height: '100%', objectFit: 'contain' }}
        src={logoUrl}
      />
    </Box>
  )
}

export default FullLogoNot3veta

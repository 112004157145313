export function ReverseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 23.013 27.26">
      <path
        id="Refresh-ICon-SVG-paldjvc"
        d="M3.371,7.616a11.46,11.46,0,0,1,6.945-3.3L8.422,2.422A1.406,1.406,0,0,1,8.309.267a1.987,1.987,0,0,1,2.452.41l1.9,1.9a19.975,19.975,0,0,0,2.618,2.157,1.184,1.184,0,0,1,.516.634.991.991,0,0,1-.516,1.111,20.055,20.055,0,0,0-2.618,2.157l-1.9,1.9a2.179,2.179,0,0,1-1.974.629,1.327,1.327,0,0,1-.478-.219,1.378,1.378,0,0,1,.03-2.067c.028-.03.054-.061.083-.09l1.711-1.71A8.779,8.779,0,0,0,5.295,21.964c.152.152.31.3.47.433A8.784,8.784,0,0,0,17.718,9.539l1.926-1.924a11.7,11.7,0,0,1,.855.959A11.507,11.507,0,0,1,11.506,27.26a11.425,11.425,0,0,1-7.669-2.931c-.159-.142-.315-.288-.467-.44a11.506,11.506,0,0,1,0-16.273"
      />
    </svg>
  )
}

import { Button, Form, Text, TextArea, TextInput } from 'grommet'
import { TFunction } from 'i18next'
import getConfig from 'next/config'
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-hot-toast'

import { useApi } from 'config/api'
import { GenericPalette } from 'lib/constants'

import { colors } from 'layout/themeColors/colors'
import UserEndpoints from 'services/api/Users'

const {
  publicRuntimeConfig: { RECAPTCHA_SITE_KEY },
} = getConfig()

const emptyFormState = {
  name: '',
  email: '',
  subject: '',
  message: '',
}

type Props = {
  t: TFunction
  palette: GenericPalette
  secondary: string
  backgroundColor: string
  buttonStyle: CSSProperties
  providerId: string
}

export const ProvidersContactForm = ({
  t,
  palette,
  secondary,
  backgroundColor,
  buttonStyle,
  providerId,
}: Props) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [contactErrorMessage, setContactErrorMessage] = useState<string | undefined>()
  const [contactSuccessMessage, setContactSuccessMessage] = useState<string | undefined>()
  const [isFormOnFocus, setIsFormOnFocus] = useState(false)
  const [value, setValue] = useState(emptyFormState)

  const [{ error }, contact] = useApi(UserEndpoints.contactProvider(providerId), { manual: true })

  useEffect(() => {
    if (error) {
      setContactErrorMessage(t(error.message))
      toast.error(t(error.message))
    }
  }, [error])

  return (
    <Form
      value={value}
      onFocus={() => setIsFormOnFocus(true)}
      onChange={(nextValue) => setValue(nextValue)}
      onReset={() => setValue(emptyFormState)}
      onSubmit={async (e) => {
        e.preventDefault()
        if (!recaptchaRef.current) return
        const token = await recaptchaRef.current.executeAsync()

        setContactErrorMessage(undefined)
        const { status } = await contact({
          data: { ...e.value, 'g-recaptcha-response': token },
        })
        if (status === 200) {
          setContactSuccessMessage(t('website-templates.successfully-sent'))
          toast.success(t('website-templates.successfully-sent'))
          setValue(emptyFormState)
        }
      }}>
      {isFormOnFocus && (
        // @ts-expect-error react-17-to-18
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITE_KEY} />
      )}
      <TextInput
        required
        type="name"
        name="name"
        placeholder={t('website-templates.name-placeholder')}
        style={{
          backgroundColor: backgroundColor,
          borderRadius: '0',
          padding: '13px',
          marginBottom: '8px',
          border: 'none',
          color: palette.colors.text,
          fontSize: '14px',
          fontFamily: secondary,
        }}
      />
      <TextInput
        required
        type="email"
        name="email"
        placeholder={t('website-templates.email-placeholder')}
        style={{
          backgroundColor: backgroundColor,
          borderRadius: '0',
          padding: '13px',
          marginBottom: '8px',
          border: 'none',
          color: palette.colors.text,
          fontSize: '14px',
          fontFamily: secondary,
        }}
      />
      <TextInput
        required
        name="subject"
        placeholder={t('website-templates.subject-placeholder')}
        style={{
          backgroundColor: backgroundColor,
          borderRadius: '0',
          padding: '13px',
          marginBottom: '8px',
          border: 'none',
          color: palette.colors.text,
          fontSize: '14px',
          fontFamily: secondary,
        }}
      />
      <TextArea
        name="message"
        required
        placeholder={t('website-templates.message-placeholder')}
        style={{
          backgroundColor: backgroundColor,
          borderRadius: '0',
          padding: '13px',
          marginBottom: '8px',
          height: '215px',
          resize: 'none',
          color: palette.colors.text,
          fontSize: '14px',
          fontFamily: secondary,
        }}
      />
      <Button primary type="submit" style={buttonStyle}>
        {t('website-templates.contact-button')}
      </Button>

      {contactErrorMessage ? (
        <Text
          color={colors.statusError}
          style={{
            fontFamily: secondary,
            fontSize: '13px',
            width: 'fit-content',
            display: 'block',
            margin: 'auto',
          }}>
          {contactErrorMessage}
        </Text>
      ) : (
        contactSuccessMessage && (
          <Text
            style={{
              fontFamily: secondary,
              fontSize: '13px',
              width: 'fit-content',
              display: 'block',
              margin: 'auto',
            }}>
            {contactSuccessMessage}
          </Text>
        )
      )}
    </Form>
  )
}

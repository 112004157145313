import { Anchor, Box, ResponsiveContext } from 'grommet'
import React, { ReactNode } from 'react'

import { links } from 'lib/constants'

import FullLogo from 'components/icons/common/FullLogo'
import { colors } from 'layout/themeColors/colors'

interface BaseBannerProps {
  bannerColor?: string
  children?: ReactNode
  height?: string
}

export function BaseBanner({ bannerColor, children, height }: BaseBannerProps) {
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'

  return (
    <Box
      direction="row"
      align="center"
      fill="horizontal"
      gap={isMobile ? '20px' : '50px'}
      style={{ minHeight: '70px', height }}
      pad={isMobile ? '0 12px 0 12px' : '0 125px 0 80px'}
      background={bannerColor}>
      <Anchor
        href={links.landing}
        target="_blank"
        style={{ display: 'flex', alignItems: 'center' }}>
        <FullLogo height={isMobile ? 25 : 35} width={isMobile ? 90 : 120} color={colors.dark} />
      </Anchor>
      <Box pad={{ top: isMobile ? '7px' : undefined }} fill justify="center">
        {children}
      </Box>
    </Box>
  )
}

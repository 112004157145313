import { Box, Heading, Layer, ResponsiveContext, Text } from 'grommet'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { getCurrentInAppBrowserName } from 'lib/inAppBrowsersUtil'

import ErrorPageIllustration from 'components/icons/ErrorPageIllustration'
import { colors } from 'layout/themeColors/colors'

export const ErrorPageInAppBrowser = () => {
  const { t } = useTranslation()
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  const maybeInAppBrowserName = getCurrentInAppBrowserName()

  return (
    <Layer style={{ width: '100%', height: '100%' }}>
      <Box background={colors.white} fill align="center" justify="between">
        <Box margin={isMobile ? '20px' : '180px'} align="center" style={{ zIndex: 1 }} gap="xlarge">
          <Heading textAlign="center" level="3" style={{ whiteSpace: 'pre-wrap' }}>
            {t('in-app-browsers.heading')}
          </Heading>
          <Box>
            <Text textAlign="start">
              <strong>
                {t('in-app-browsers.android.title')}
                {': '}
              </strong>
              <ul>
                <li>{t('in-app-browsers.android.li1')}</li>
                <li>{t('in-app-browsers.android.li2')}</li>
              </ul>
            </Text>
            <Text textAlign="start">
              <strong>
                {t('in-app-browsers.ios.title')}
                {': '}
              </strong>
              <ul>
                <li>{t('in-app-browsers.ios.li1')}</li>
                <li>{t('in-app-browsers.ios.li2')}</li>
              </ul>
            </Text>
          </Box>
          <Box>
            {maybeInAppBrowserName && (
              <Text textAlign="center" style={{ paddingTop: '40px' }}>
                {t('in-app-browsers.not-recommended', { inAppBrowserName: maybeInAppBrowserName })}
              </Text>
            )}
          </Box>
          <Box pad={{ top: 'large' }}>
            <Text size="small" textAlign="center">
              {t('in-app-browsers.manual-copy-paste')}
            </Text>
          </Box>
        </Box>
        <Box
          style={{
            position: isMobile ? 'relative' : 'absolute',
            right: isMobile ? '0' : '140px',
            bottom: isMobile ? '0' : '70px',
            width: isMobile ? '100%' : '460px',
          }}>
          {isMobile && <ErrorPageIllustration />}
        </Box>
        {!isMobile && <Box background="#F3F5FA" height="150px" fill="horizontal" />}
      </Box>
    </Layer>
  )
}

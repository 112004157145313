import { baseErrorHandler } from 'errors/ErrorHandlers'

import { api } from 'config/api'
import { Subscription } from 'config/useMySubscription'
import { UserMarketplaceCategoryOptions } from 'lib/constants'

import { Contact } from 'services/api/Contacts'

import { Endpoint } from '.'
import { Team } from './Team'

const RESOURCE_BASE_URL = 'users'

const UserEndpoints = {
  listCustomers: (): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/list-customers`,
  }),
  listProviders: (): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/list-providers`,
  }),
  viewSelf: (userId: string): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/view/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  viewCustomer: (userId: string): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/view-customer/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  viewProvider: (userId: string): Endpoint => ({
    method: 'GET',
    url: `${RESOURCE_BASE_URL}/view-provider/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  listMarketplaceProviders: (page?: number, search?: string, filter?: string): Endpoint => {
    let optionalQueryString = ''
    if (search) {
      optionalQueryString = optionalQueryString.concat(`?search=${search}`)
    }
    if (page) {
      optionalQueryString = optionalQueryString.concat(
        `${optionalQueryString ? '&' : '?'}page=${page}`,
      )
    }
    if (filter) {
      optionalQueryString = optionalQueryString.concat(
        `${optionalQueryString ? '&' : '?'}filter=${filter}`,
      )
    }
    return {
      method: 'GET',
      url: `${RESOURCE_BASE_URL}/list-marketplace-providers${optionalQueryString}`,
      errorHandler: baseErrorHandler,
    }
  },
  create: (): Endpoint => ({
    method: 'POST',
    url: `${RESOURCE_BASE_URL}/signup`,
    errorHandler: baseErrorHandler,
  }),
  edit: (userId: string): Endpoint => ({
    method: 'PATCH',
    url: `${RESOURCE_BASE_URL}/edit/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  editEmail: (userId: string): Endpoint => ({
    method: 'PATCH',
    url: `${RESOURCE_BASE_URL}/edit-email/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  sendWelcomeEmail: (): Endpoint => ({
    method: 'POST',
    url: `${RESOURCE_BASE_URL}/send-welcome-email`,
    errorHandler: baseErrorHandler,
  }),
  contactProvider: (userId: string): Endpoint => ({
    method: 'POST',
    url: `${RESOURCE_BASE_URL}/contact/${userId}`,
    errorHandler: baseErrorHandler,
  }),
  requestPasswordReset: (): Endpoint => ({
    method: 'POST',
    url: `${RESOURCE_BASE_URL}/request-password-reset`,
  }),
  resetPassword: (): Endpoint => ({
    method: 'PATCH',
    url: `${RESOURCE_BASE_URL}/reset-password`,
  }),
  confirmEmail: (): Endpoint => ({
    method: 'PATCH',
    url: `${RESOURCE_BASE_URL}/confirm-email`,
  }),
  convertCustomerToProvider: (): Endpoint => ({
    method: 'POST',
    url: `${RESOURCE_BASE_URL}/irreversibly-convert-from-customer-to-provider`,
    data: {},
  }),
}

export async function editUser(userId?: string, data?: any) {
  if (!userId) {
    throw new Error('UserId is not provided')
  }
  await api.request({ ...UserEndpoints.edit(userId), data })
}
export type AddonFlags = {
  is_addon_video_recording_on: boolean | null
  is_addon_video_streaming_on: boolean | null
  is_addon_audio_transcription_on: boolean | null
  is_addon_meetings_agenda_on: boolean | null
  is_addon_meeting_rooms_on: boolean | null
  is_addon_contacts_on: boolean | null
  is_addon_website_builder_on: boolean | null
  is_addon_events_on: boolean | null
  is_addon_marketplace_listing_on: boolean | null
  is_addon_dashboard_on: boolean | null
}
export type Flags = AddonFlags & {
  has_set_availability: boolean
  has_logged_in_from_mobile: boolean
  has_completed_onboarding_checklist: boolean
  has_passed_onboard_team_step: boolean
  has_passed_onboard_calendar_step: boolean
  has_passed_onboard_photo_step: boolean
  has_passed_onboard_industry_step: boolean
  has_invited_collaborator: boolean
  has_connected_calendar: boolean
  has_booked_self: boolean
  ref_source: string | null
  industry: string | null
  availability_time_horizon_start_in_minutes: number | null
  availability_time_horizon_end_in_days: number | null
  booking_slots_time_interval_in_minutes: number | null
  marketplace_listing_logo_url: string | null
  jitsi_custom_background_url: string | null
  jitsi_custom_header_background_color: string | null
  jitsi_custom_logo_url: string | null
  bookable_services_order_ids_csv: string
  is_listed_on_marketplace: boolean
}
export type UserType = { id: number; name: string }
export type UserTypeProvider = { id: 2; name: 'Provider' }
export type UserTypeCustomer = { id: 3; name: 'Customer' }

export type QuickMeetingHost = {
  id: string
  name: string
  subdomain: string
  jitsi_custom_logo_url: string | null
  jitsi_custom_header_background_color: string | null
}
export type HostUser = {
  id: string
  email: string
  email_confirmed: boolean
  subdomain: string
  avatar_url: string | null
  iso_country_code: string
  iso_language_code: string
  iso_currency_code: string
  first_name: string
  last_name: string
  display_name: string
  user_type: UserTypeProvider
  name: string
  flags: Flags
}
export type ServerUser = {
  id: string
  email: string
  email_confirmed: boolean
  subdomain: string
  avatar_url: string | null
  iso_country_code: string
  iso_language_code: string
  iso_currency_code: string
  timezone: string | null
  name: string
  first_name: string
  last_name: string
  display_name: string | null
  flags: Flags
  user_type: UserType
  is_stripe_onboarded: boolean
  min_meeting_price_in_cents: number
  max_meeting_price_in_cents: number
  teams: Team[]
  subscription: Subscription | null
  marketplace_category_options?: UserMarketplaceCategoryOptions
}
export type ProviderUserProps = {
  providerUser?: ProviderUser
}
export type BookableServicePublicInfo = {
  id: string
  duration: number
  price_in_cents: string
}

export type ProviderUser = {
  id: string
  name: string
  avatar_url: string | null
  subdomain: string
  iso_language_code: string
  iso_currency_code: string
  iso_country_code: string | null
  timezone: string | null
  subscription: Subscription | null
  availability_time_horizon_end_in_days?: number | null
  availability_time_horizon_start_in_minutes?: number | null
  booking_slots_time_interval_in_minutes?: number | null
  marketplace_logo?: string | null
  marketplace_category_options?: UserMarketplaceCategoryOptions
}

export type MeetingInvitation = {
  id: string
  meeting_id: string
  contact_id: string
  contact: Contact
}

export type MeetingParticipant = {
  id: string
  email: string
  email_confirmed: boolean
  subdomain: string | null
  avatar_url: string | null
  iso_country_code: string
  iso_language_code: string
  iso_currency_code: string | null
  first_name: string
  last_name: string
  display_name: string | null
  _joinData: {
    payment_intent: string | null
    payment_intent_successful: boolean
    joined_as_free: boolean
  }
  user_type: UserTypeCustomer
  name: string
}

export type ListCustomers = {
  users: ListCustomerUser[]
}
export type ListCustomerUser = {
  sum_of_prices_paid_in_cents: number
  number_of_meetings: number
  id: string
  email: string
  email_confirmed: boolean
  subdomain: string | null
  avatar_url: string | null
  iso_country_code: string
  iso_language_code: string
  iso_currency_code?: string | null
  first_name: string
  last_name: string
  display_name: string | null
  user_type: UserType
  name: string
}

export async function fetchProvider(userId?: string): Promise<ProviderUser> {
  if (!userId) {
    throw new Error('UserId is not provided')
  }
  const response = await api.request<{ user: ProviderUser }>(UserEndpoints.viewProvider(userId))
  return response.data?.user
}

export async function fetchMarketplaceProviders(): Promise<ProviderUser[]> {
  const response = await api.request<{ users: ProviderUser[] }>(
    UserEndpoints.listMarketplaceProviders(),
  )
  return response?.data?.users
}

export async function confirmEmail({ code }: { code: string }): Promise<boolean> {
  const response = await api.request({ ...UserEndpoints.confirmEmail(), data: { code } })
  return response.status === 200
}

export default UserEndpoints

import { AUTH_TOKEN_KEY, getCookie } from 'cookieStorage'
import jwtDecode from 'jwt-decode'

import { api } from 'config/api'

import UserEndpoints, { ServerUser } from 'services/api/Users'

type UserJwt = { sub: string; exp: number }

const getUserIdFromToken = (jwt: string): string | undefined => {
  // Use the jwt subject as user id
  try {
    if (jwt) {
      return jwtDecode<UserJwt>(jwt).sub
    }
  } catch (error) {
    console.error(error)
  }
}

export const getUserIdFromCookie = () => {
  const jwt = getCookie(AUTH_TOKEN_KEY)
  return getUserIdFromToken(jwt)
}

export async function fetchUser() {
  const userId = getUserIdFromCookie()
  const jwt = getCookie(AUTH_TOKEN_KEY)
  return fetchUserById({ userId, jwt })
}

export async function fetchUserByToken({ jwt }: { jwt: string }) {
  const userId = getUserIdFromToken(jwt)
  return fetchUserById({ userId, jwt })
}

type FetchUserProps = {
  userId?: string
  jwt?: string
}
export async function fetchUserById({ userId, jwt }: FetchUserProps): Promise<ServerUser> {
  if (userId) {
    try {
      const response = await api.request<{ user: ServerUser }>({
        ...UserEndpoints.viewSelf(userId),
        headers: jwt ? { Authorization: `Bearer ${jwt}` } : {},
      })
      return response.data.user
    } catch (error) {
      console.error(error)
    }
  }
  throw new Error('UserID is not provided')
}

import React from 'react'

const CloseIcon = ({ color, sideSize }: { color: string; sideSize: string }) => (
  <svg width={sideSize} height={sideSize} viewBox="0 0 9 9" fill="none">
    <path
      d="M4.49509 3.09075L1.38386 0L1.63683e-06 1.37475L3.14253 4.50341L0 7.62525L1.39368 9L4.50491 5.90925L7.61614 9L9 7.62525L5.85747 4.49659L9 1.37475L7.60632 0L4.49509 3.09075Z"
      fill={color}
    />
  </svg>
)

export default CloseIcon

import getConfig from 'next/config'
import { useMemo } from 'react'

const {
  publicRuntimeConfig: {
    PADDLE_PLAN_IDS,
    BASIC_PLAN_VIDEO_ENABLED,
    STARTER_MONTHLY_PRICE_IN_CENTS_PER_MONTH,
    STARTER_ANNUALLY_PRICE_IN_CENTS_PER_MONTH,
    PRO_MONTHLY_PRICE_IN_CENTS_PER_MONTH,
    PRO_ANNUALLY_PRICE_IN_CENTS_PER_MONTH,
    BASIC_PLAN_PROCESSING_FEE_IN_PERCENTAGE,
    PRO_PLAN_PROCESSING_FEE_IN_PERCENTAGE,
    featureFlags: { isPaaSVideoEnabled, shouldHideBannerOnProPlan, shouldHideBannerOnBasicPlan },
  },
} = getConfig()

const DEFAULT_BASIC_PLAN_PROCESSING_FEE_IN_PERCENTAGE = 5
const DEFAULT_PRO_PLAN_PROCESSING_FEE_IN_PERCENTAGE = 0

interface PaddlePlansByBillingInterval {
  monthly: PaddlePlanPricesByType
  annual: PaddlePlanPricesByType
}

export interface PaddlePlanPricesByType {
  basic: PaddlePlan
  premium: PaddlePlan
}

export type PaddleBillingInterval = 'month' | 'year'
export type PaddlePlanType = 'basic' | 'premium'
export type PaddlePlanId = string

type PricePolicyList = {
  can_set_custom_branding: boolean
  can_set_custom_currency: boolean
  can_use_zapier: boolean
  can_use_video: boolean
  hide_banner: boolean
  processing_fee: number
  type: string
}

type PricePoliciesByType = {
  basic: PricePolicyList
  premium: PricePolicyList
}

export interface PaddlePlan {
  id: PaddlePlanId
  name_for_tracking: string
  billing_interval: PaddleBillingInterval
  type: PaddlePlanType
  price_per_user_per_month_in_cents: number
  policyList: PricePolicyList
}

export const policies: PricePoliciesByType = {
  basic: {
    can_set_custom_branding: false,
    can_set_custom_currency: false,
    can_use_zapier: false,
    can_use_video: isPaaSVideoEnabled && BASIC_PLAN_VIDEO_ENABLED,
    hide_banner: shouldHideBannerOnBasicPlan,
    processing_fee:
      BASIC_PLAN_PROCESSING_FEE_IN_PERCENTAGE || DEFAULT_BASIC_PLAN_PROCESSING_FEE_IN_PERCENTAGE,
    type: 'basic',
  },
  premium: {
    can_set_custom_branding: true,
    can_set_custom_currency: true,
    can_use_zapier: true,
    can_use_video: isPaaSVideoEnabled,
    hide_banner: shouldHideBannerOnProPlan,
    processing_fee:
      PRO_PLAN_PROCESSING_FEE_IN_PERCENTAGE || DEFAULT_PRO_PLAN_PROCESSING_FEE_IN_PERCENTAGE,
    type: 'premium',
  },
}

const prices: PaddlePlansByBillingInterval = {
  monthly: {
    basic: {
      id: PADDLE_PLAN_IDS.MONTHLY_BASIC,
      name_for_tracking: 'Starter Monthly',
      billing_interval: 'month',
      type: 'basic',
      price_per_user_per_month_in_cents: STARTER_MONTHLY_PRICE_IN_CENTS_PER_MONTH,
      policyList: policies.basic,
    },
    premium: {
      id: PADDLE_PLAN_IDS.MONTHLY_PREMIUM,
      name_for_tracking: 'Pro Monthly',
      billing_interval: 'month',
      type: 'premium',
      price_per_user_per_month_in_cents: PRO_MONTHLY_PRICE_IN_CENTS_PER_MONTH,
      policyList: policies.premium,
    },
  },
  annual: {
    basic: {
      id: PADDLE_PLAN_IDS.ANNUAL_BASIC,
      name_for_tracking: 'Starter Annual',
      billing_interval: 'year',
      type: 'basic',
      price_per_user_per_month_in_cents: STARTER_ANNUALLY_PRICE_IN_CENTS_PER_MONTH,
      policyList: policies.basic,
    },
    premium: {
      id: PADDLE_PLAN_IDS.ANNUAL_PREMIUM,
      name_for_tracking: 'Pro Annual',
      billing_interval: 'year',
      type: 'premium',
      price_per_user_per_month_in_cents: PRO_ANNUALLY_PRICE_IN_CENTS_PER_MONTH,
      policyList: policies.premium,
    },
  },
}

const plansList: PaddlePlan[] = [
  prices.monthly.basic,
  prices.monthly.premium,
  prices.annual.basic,
  prices.annual.premium,
]

export const getPlanById = (planId: string): PaddlePlan | undefined => {
  return plansList.find((plan) => plan.id === planId)
}

export const isPlanMonthly = (plan?: PaddlePlan): boolean => {
  return plan?.billing_interval === 'month'
}

export const usePlanPrices = () => {
  // TODO iv paddle
  // const [{ data, loading }] = useApi(StripeEndpoints.getPlanPrices())

  return useMemo(() => ({ prices, loading: false }), [prices])
}

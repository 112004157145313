import { Button } from 'grommet'
import { ButtonExtendedProps } from 'grommet/components/Button'

import { colors } from 'layout/themeColors/colors'

export const BorderedButton = (props: ButtonExtendedProps) => {
  return (
    <Button
      {...props}
      style={{
        ...props.style,
        color: colors.primaryBrand,
        background: colors.white,
        border: `1.5px solid ${colors.primaryBrand}`,
      }}
    />
  )
}

import { TFunction } from 'i18next'
import 'moment-timezone'
import getConfig from 'next/config'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { Location } from 'lib/meeting-location'

import Unsplash, { type Image } from 'scenes/MyPage/WebsiteBuilder/components/unsplash'
import { HostedMeeting, Meeting } from 'services/api/Meetings'
import { Team } from 'services/api/Team'
import { ServerUser } from 'services/api/Users'
import { BookableService } from 'services/auth/ssp'

import {
  MarketplaceCategory,
  ONBOARDING_STEPS,
  urlPattern,
  UserMarketplaceCategoryOption,
  UserMarketplaceCategoryOptions,
} from './constants'

const {
  publicRuntimeConfig: { CDN_URL },
} = getConfig()

export const sortByStringPropValue = (data: any[], prop: string, asc: boolean) => {
  const compareFunction = (x: any, y: any) => {
    const stringX = x[prop].toLowerCase()
    const stringY = y[prop].toLowerCase()
    return stringX < stringY ? (asc ? -1 : 1) : stringX > stringY ? (asc ? 1 : -1) : 0
  }

  return data.sort(compareFunction)
}

export const centsToCurrency = (price_in_cents: string | number, currency: string) => {
  const priceUnit = price_in_cents ? parseInt(`${price_in_cents}`, 10) / 100 : 0
  const priceFixed = price_in_cents ? priceUnit.toFixed(2) : 0
  const currencyStr = price_in_cents ? ` ${currency || ''}` : ''
  let specialWithCurrency: string | undefined = undefined
  let specialFixedWithCurrency: string | undefined = undefined
  if (currency === 'USD') {
    specialWithCurrency = `$${priceUnit}`
    specialFixedWithCurrency = `$${priceFixed}`
  }
  if (currency === 'EUR') {
    specialWithCurrency = `€${priceUnit}`
    specialFixedWithCurrency = `€${priceFixed}`
  }

  return {
    priceUnit,
    priceFixed,
    withCurrency: specialWithCurrency ?? `${priceUnit || ''}${currencyStr}`,
    fixedWithCurrency: specialFixedWithCurrency ?? `${priceFixed || ''}${currencyStr}`,
  }
}

// TODO iv teams here is the current assumption there's only one team per user
export const getMainTeam = (wrappingObject?: { teams: Team[] }): Team | undefined => {
  return wrappingObject?.teams && wrappingObject.teams[0] ? wrappingObject.teams[0] : undefined
}

export const getUserFirstLastNamesString = (user?: {
  first_name: string
  last_name: string | null
}) => {
  return user?.first_name + (user?.last_name ? ' ' + user?.last_name : '')
}

export const getCorrectOnboardingStep = (user: ServerUser) => {
  if (!user?.iso_country_code) {
    return ONBOARDING_STEPS.NAME_COUNTRY
  }
  if (!user.subdomain) {
    return ONBOARDING_STEPS.TEAM_NAME
  }
  if (!user.flags.has_passed_onboard_calendar_step) {
    return ONBOARDING_STEPS.CALENDAR_CONNECT
  }
  if (!user.flags.has_passed_onboard_photo_step) {
    return ONBOARDING_STEPS.ADD_PHOTO
  }
  if (!user.flags.has_passed_onboard_industry_step) {
    return ONBOARDING_STEPS.REF_SOURCE_INDUSTRY
  }
  return ONBOARDING_STEPS.NAME_COUNTRY
}

export const minutesToDurationString = (mins: number) => {
  if (mins < 60) {
    return `${mins}min`
  }
  const hours = Math.floor(mins / 60)
  const remainingMinutes = mins - hours * 60
  return `${hours}h` + (remainingMinutes > 0 ? ` ${remainingMinutes}min` : '')
}

export function toggleValueInArray<T>(array: T[], value: T): T[] {
  if (!array || !Array.isArray(array)) {
    return [] as T[]
  } else if (array.includes(value)) {
    return array.filter((item) => item !== value)
  } else {
    return [...array, value]
  }
}

export function roundToTwoDecimals(value: number) {
  const decimal = value / 100
  return Math.round(decimal * 100) / 100
}

export const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useClientSideRender = (page: React.ReactNode) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])
  return mounted ? page : null
}

export const isClientSide = () => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])
  return mounted
}

export const useEffectDebugger = (
  effectHook: React.EffectCallback,
  dependencies: React.DependencyList | undefined,
  dependencyNames = [],
) => {
  const previousDeps = usePrevious(dependencies, [])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      }
    }

    return accum
  }, {})

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps)
  }

  useEffect(effectHook, dependencies)
}

export function isValidHttpUrl(url: string | null | undefined): boolean {
  if (!url) return false

  let validatedUrl

  try {
    validatedUrl = new URL(url)
  } catch (_) {
    return false
  }

  return validatedUrl.protocol === 'http:' || validatedUrl.protocol === 'https:'
}

export function positiveModulo(dividend: number, divisor: number) {
  return ((dividend % divisor) + divisor) % divisor
}

export function isValidUrl(url?: string | null) {
  if (!url) return false
  return urlPattern.test(formatUrl(url))
}

export const defaultServiceImage = `${CDN_URL}static/default-service-image.png`

export const resolveImage = (image: string | null, defaultImage = defaultServiceImage): string => {
  const raw = resolveRawImage(image)
  if (!raw) return defaultImage
  return typeof raw === 'string' ? raw : raw.regular
}

export const resolveRawImage = (image: string | null): Image | undefined => {
  try {
    if (image?.startsWith('"')) {
      const parsed = JSON.parse(image)
      if (!parsed) {
        return
      }
      if (typeof parsed === 'string') {
        return parsed
      } else if (typeof parsed === 'object' && isUnsplashImage(parsed)) {
        return parsed
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const isUnsplashImage = (image: unknown): image is Unsplash.UnsplashImage => {
  return !!image && typeof image === 'object' && 'regular' in image
}

export const servicePaymentLabel = (service: BookableService, t: TFunction): string => {
  if (service.is_free) {
    return t('free')
  }
  if (service.price_in_cents) {
    const { withCurrency } = centsToCurrency(
      service.price_in_cents,
      t('currency_code.' + service.iso_currency_code),
    )
    return withCurrency
  }
  return ''
}

export function generateDefaultBookableService(canUseVideo: boolean | undefined): BookableService {
  return {
    id: '',
    user_id: null,
    name: '',
    description: '',
    image: JSON.stringify(defaultServiceImage),
    price_in_cents: '',
    iso_currency_code: '',
    is_free: true,
    duration: 15,
    is_active: true,
    is_archived: false,
    is_default: true,
    btn_label: '',
    btn_color: '',
    edited_variation_of: null,
    image_position: null,
    meeting_location: canUseVideo ? Location.InternalMeetingLink : Location.ExternalMeetingLink,
    additional_meeting_info: null,
    meeting_room: null,
    meeting_location_room_id: null,
    meeting_location_is_quickmeet: false,
    weight: 9999,
  }
}

export function formatUrl(url?: string) {
  if (!url) return ''
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url
  }
  return url
}

export function sortMarketplaceCategoriesByListOrder(
  marketplaceCategories: MarketplaceCategory[] | undefined,
) {
  if (!marketplaceCategories) return []
  return marketplaceCategories?.sort((marketplaceCategoryA, marketplaceCategoryB) => {
    if (marketplaceCategoryA.list_order > marketplaceCategoryB.list_order) {
      return 1
    } else if (marketplaceCategoryA.list_order < marketplaceCategoryB.list_order) {
      return -1
    }
    return 0
  })
}

export function handleTagSelectionChange(
  marketplaceCategory: MarketplaceCategory,
  option: UserMarketplaceCategoryOption,
  selectedCategoryOptions: UserMarketplaceCategoryOptions | undefined,
  setSelectedCategoryOptions: React.Dispatch<
    React.SetStateAction<UserMarketplaceCategoryOptions | undefined>
  >,
  maxSelected?: number | null,
  onUnallowedSelect?: () => void,
) {
  const marketplaceCategoryOptions = selectedCategoryOptions?.[marketplaceCategory.id]
  if (
    marketplaceCategoryOptions?.length &&
    marketplaceCategoryOptions.some(
      (marketplaceCategoryOption) => marketplaceCategoryOption.id === option.id,
    )
  ) {
    if (marketplaceCategoryOptions.length > 1) {
      setSelectedCategoryOptions({
        ...selectedCategoryOptions,
        [marketplaceCategory.id]:
          marketplaceCategoryOptions?.filter(
            (marketplaceCategoryOption) => marketplaceCategoryOption.id !== option.id,
          ) ?? [],
      })
    } else {
      setSelectedCategoryOptions((prev) => {
        if (!prev) {
          return prev
        }
        const { [marketplaceCategory.id]: _, ...rest } = prev
        return rest
      })
    }
  } else {
    if (
      selectedCategoryOptions?.[marketplaceCategory.id]?.length &&
      maxSelected &&
      selectedCategoryOptions[marketplaceCategory.id].length >= maxSelected
    ) {
      onUnallowedSelect && onUnallowedSelect()
    } else {
      setSelectedCategoryOptions({
        ...(selectedCategoryOptions ?? {}),
        [marketplaceCategory.id]: [
          ...(marketplaceCategoryOptions ?? []),
          { display_name: option.display_name, id: option.id },
        ],
      })
    }
  }
}

export function generateEventStartEndTime(
  startTime: Date,
  endTime: Date,
  eventTimezone: string | null,
  withTimeZone = false,
) {
  const timezone = eventTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  const eventStart = startTime.toLocaleTimeString([], {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: timezone,
  })
  const eventEnd = endTime.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: timezone,
  })

  return `${eventStart} - ${eventEnd}` + (withTimeZone ? `, ${timezone}` : '')
}

export function filterUsersAllowedToJoinMeeting(meeting: Meeting | HostedMeeting) {
  return meeting?.participants?.filter(
    (participant) =>
      participant._joinData.joined_as_free || participant._joinData.payment_intent_successful,
  )
}

export function genericSuccessToast(t: TFunction<'translation', undefined, 'translation'>) {
  return toast.success(t('toasts.success'))
}

export function isAddOnTurnedOn(flag: boolean | null | undefined, defaultValue: boolean) {
  if (flag === null || typeof flag === 'undefined') {
    return defaultValue
  }
  return flag
}

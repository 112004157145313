import moment from 'moment/moment'
import { ITimezone } from 'react-timezone-select'

export const getDurationBetweenDates = (
  startDate: string | number | Date,
  endDate: string | number | Date,
  inMinutes = false,
) => {
  const dateOpen = new Date(startDate)
  const datePast = new Date(endDate)
  let delta = Math.abs(dateOpen.valueOf() - datePast.valueOf()) / 1000

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60

  if (inMinutes) {
    return hours * 60 + minutes
  }

  return hours !== 0 ? `${hours} h ${minutes} min` : `${minutes} min`
}
export const getTimezoneShortName = (timezone: string | null | undefined): string => {
  return timezone ? moment().tz(timezone).format('z') : ''
  // .format('z (Z)')
}
export const getTimezoneLongName = (timezone: string | null | undefined): string => {
  return getTimezoneShortName(timezone) + ' (' + timezone?.replaceAll('_', ' ') + ')'
}
export const getTimezoneStringFromITimezone = (timezone: ITimezone): string => {
  return typeof timezone === 'string' ? timezone : timezone?.value
}
export const isDateWithoutTime = (value: string): boolean => {
  return /^\d{4}-\d{2}-\d{2}$/.test(value)
}

export function getDateDaysAgo(days: number, date: Date) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() - days)
  return newDate
}

export function getDateDaysAhead(days: number, date: Date) {
  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export function getFirstDateMonthsAhead(months: number, date: Date) {
  return new Date(date.getFullYear(), date?.getMonth() + months, 1)
}

export function areTheSameDate(a: Date, b: Date) {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  )
}

export function diffInIdays(a: Date, b: Date) {
  const diffTime = Math.abs(a.getTime() - b.getTime())
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export function diffInMonths(a: Date, b: Date) {
  return b.getMonth() - a.getMonth() + 12 * (b.getFullYear() - a.getFullYear())
}

export function isDateStringInPast(date: string) {
  const now = new Date()
  return now.getTime() > new Date(date).getTime()
}

export const durationToHours = (duration: number): [number, number] => {
  const hours = Math.floor(duration / 60)
  const minutes = duration - hours * 60
  // Math.floor(defaultValues.duration / 60)
  // defaultValues.duration % 60
  return [hours, minutes]
}
export const hoursToDuration = (hours: number, minutes: number) => {
  return hours * 60 + minutes
}
export const durationToString = (duration: number): string => {
  const [hours, minutes] = durationToHours(duration)
  return hours === 0 ? `${minutes} min` : `${hours} h ${minutes} min`
}

import { Box } from 'grommet'
import React, { useMemo, useState } from 'react'

import { DefaultAvatarIcon } from './icons/DefaultAvatarIcons/DefaultAvatarIcon'

const defaultSize = '40px'

type Props = {
  src?: string
  user?: { avatar_url: string | null }
  width?: string
  height?: string
  circleId?: string
  clipPathId?: string
  gradientId?: string
  wrapperStyle?: React.CSSProperties
  squareAspectRatio?: boolean
}
const Avatar = ({
  src,
  user,
  width,
  height,
  circleId = 'circleId',
  clipPathId = 'clipPathId',
  gradientId = 'gradientId',
  wrapperStyle = {},
  squareAspectRatio = true,
}: Props) => {
  const imageSrc = useMemo(() => src || user?.avatar_url, [src, user])
  const [error, setError] = useState(false)

  const url = useMemo(() => {
    if (!imageSrc) {
      return
    }
    if (imageSrc?.startsWith('https')) {
      return imageSrc
    }
    return
  }, [imageSrc])

  const showDefault = useMemo(() => !imageSrc || error, [error, imageSrc])

  return (
    <Box width={{ min: width || defaultSize }} style={wrapperStyle}>
      {showDefault ? (
        <DefaultAvatarIcon
          width={height || defaultSize}
          height={width || defaultSize}
          circleId={circleId}
          clipPathId={clipPathId}
          gradientId={gradientId}
        />
      ) : (
        <img
          src={url}
          id="avatar"
          alt="Avatar"
          height={height || defaultSize}
          width={width || (squareAspectRatio ? defaultSize : undefined)}
          onError={() => setError(true)}
          style={{ borderRadius: squareAspectRatio ? '50%' : '16px' }}
        />
      )}
    </Box>
  )
}

export default Avatar

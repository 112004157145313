import BaseError from './BaseError'

export default class HttpError extends BaseError {
  status: number
  extra?: any

  constructor(message: string, status: number, extra?: any) {
    super(message)
    this.status = status
    this.extra = extra
  }
}

import { CssVarsPalette, PaletteColor, TextField, useTheme } from '@mui/material'
import { colorChannel } from '@mui/system'
import { Box, Button, Layer, ResponsiveContext, Text, TextArea } from 'grommet'
import getConfig from 'next/config'
import { useContext, useEffect, useState } from 'react'

import { useForcedLogo } from 'components/contexts/ForcedLogoProvider'
import { colors } from 'layout/themeColors/colors'

import ColorPicker from './ColorPicker'
import { GenericConfirmModal } from './GenericConfirmModal'
import BackIcon from './icons/common/BackIcon'
import { CloseRateDialogIcon } from './icons/common/CloseRateDialogIcon'
import { ReverseIcon } from './icons/ReverseIcon'

const {
  publicRuntimeConfig: {
    featureFlags: { isPaasDemoThemeColorPickerEnabled },
  },
} = getConfig()

const EDITABLE_COLOR_KEYS = [
  'primaryBrand',
  'meetingRoomHeader',
  'accent1',
  'accent2',
  'accent3',
  'accent4',
  'accent5',
  'accent6',
  'light1',
  'light2',
  'light3',
]

type Props = {
  forceRerender: () => void
}

const applyCssVars = (
  palette: PaletteColor,
  variant: keyof Pick<
    CssVarsPalette,
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  >,
  shades = true,
) => {
  if (!document) return
  const el = document.documentElement

  el.style.setProperty(`--mui-palette-${variant}-main`, palette.main)
  el.style.setProperty(`--mui-palette-${variant}-mainChannel`, colorChannel(palette.main))
  el.style.setProperty(`--mui-palette-${variant}-contrastText`, palette.contrastText)

  if (shades) {
    el.style.setProperty(`--mui-palette-${variant}-light`, palette.light)
    el.style.setProperty(`--mui-palette-${variant}-dark`, palette.dark)
  }
}

export function PaaSDemoThemeColorsPicker({ forceRerender }: Props) {
  if (!isPaasDemoThemeColorPickerEnabled) return null
  const theme = useTheme()
  const [showColorsPickers, setShowColorPickers] = useState(false)
  const [showColorsList, setShowColorsList] = useState(false)
  const [showLogoEditor, setShowLogoEditor] = useState(false)
  const setColor = (color: string, colorKey: keyof typeof colors) => {
    colors[colorKey] = color

    const palette = theme.palette.augmentColor({ color: { main: color } })
    if (colorKey === 'primaryBrand') {
      applyCssVars(palette, 'primary')
    } else if (colorKey === 'statusError') {
      applyCssVars(palette, 'error')
    } else if (colorKey === 'success') {
      applyCssVars(palette, 'success', false)
    }
    forceRerender()
  }
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'
  const [showBadRawEditWarning, setShowBadRawEditWarning] = useState(false)
  const rerenderColorPicker = () => {
    setShowColorPickers(false)
    setTimeout(() => {
      setShowColorPickers(true)
    }, 0)
  }
  const generateColorList = () => {
    return JSON.stringify(colors).replace(/,/g, ',\n')
  }
  const [initialColors] = useState({ ...colors })

  const [colorList, setColorList] = useState(generateColorList())
  const {
    setForcedSquareLogo,
    forcedFullLogo,
    forcedSquareLogo,
    setForcedFullLogo,
    forcedMarketplaceBanner,
    setForcedMarketplaceBanner,
  } = useForcedLogo()

  useEffect(() => {
    setColorList(generateColorList())
  }, [JSON.stringify(colors)])

  useEffect(() => {
    if (colorList) {
      try {
        const newColorsObject = JSON.parse(
          colorList
            .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ')
            .replace(/'/g, '"')
            .replace(/\/\/.*\n/g, '')
            .replace(/,(?!\s*?[{\\["\\'\w])/g, ''),
        )
        Object.keys(newColorsObject).map((colorKey) => {
          colors[colorKey as keyof typeof colors] = newColorsObject[colorKey]
        })
        forceRerender()
        setShowColorsList(false)
      } catch (err) {
        setShowColorsList(false)
        setShowBadRawEditWarning(true)
        setColorList(generateColorList())
      }
    }
  }, [colorList])

  if (isMobile) return null
  return (
    <>
      {showBadRawEditWarning && (
        <GenericConfirmModal
          open={showBadRawEditWarning}
          setOpen={setShowBadRawEditWarning}
          title="Invalid edit"
          subtitle="Please make sure the input is valid!"
          showCancelButton={false}
        />
      )}
      <Layer
        onClickOutside={() => setShowColorPickers(false)}
        modal={false}
        style={{
          paddingLeft: '100px',
          paddingRight: '100px',
          justifyContent: 'center',
          alignItems: showColorsPickers ? 'start' : 'center',
          width: showColorsPickers ? '100%' : undefined,
          height: showColorsPickers ? '180px' : '12px',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '40px',
          rowGap: '12px',
          borderRadius: '20px',
          backgroundColor: showColorsPickers ? 'white' : 'transparent',
        }}
        position={showColorsPickers ? 'bottom' : 'bottom-right'}>
        {showColorsPickers && !showColorsList && (
          <>
            {EDITABLE_COLOR_KEYS.map((colorKey) => (
              <Box justify="center" align="center" key={colorKey}>
                <Box direction="row" align="center" gap="2px">
                  {colorKey}
                  <Button
                    onClick={() => {
                      setColor(
                        initialColors[colorKey as keyof typeof colors],
                        colorKey as keyof typeof colors,
                      )
                      rerenderColorPicker()
                    }}>
                    <ReverseIcon />
                  </Button>
                </Box>

                <ColorPicker
                  justify="center"
                  pickerTopPosition="-200px"
                  boxWidth="3rem"
                  boxHeight="3rem"
                  showBorders={false}
                  defaultPickerColor={colors[colorKey as keyof typeof colors]}
                  handleChange={(color: string) => {
                    setColor(color, colorKey as keyof typeof colors)
                  }}
                />
              </Box>
            ))}
            <Box
              direction="column"
              gap="small"
              style={{
                position: 'absolute',
                bottom: '12px',
                right: '15px',
              }}>
              <Button
                label={<Text color="black">Reset all</Text>}
                onClick={() => {
                  Object.keys(colors).map((colorKey) => {
                    colors[colorKey as keyof typeof colors] =
                      initialColors[colorKey as keyof typeof colors]
                  })
                  forceRerender()
                  rerenderColorPicker()
                }}
              />
              <Button
                label={<Text color="black">Raw edit</Text>}
                onClick={() => setShowColorsList(true)}
              />
              <Button
                label={<Text color="black">Edit logo</Text>}
                onClick={() => setShowLogoEditor(true)}
              />
            </Box>
            <Button
              plain
              icon={<CloseRateDialogIcon />}
              onClick={() => setShowColorPickers(false)}
              style={{
                position: 'absolute',
                top: '12px',
                right: '15px',
              }}
            />
          </>
        )}
        {showColorsList && (
          <Layer style={{ height: '600px' }} onClickOutside={() => setShowColorsList(false)}>
            <TextArea
              style={{ width: '300px', height: '600px', fontSize: '18px' }}
              value={colorList}
              onChange={(event) => {
                setColorList(event.target.value)
              }}
            />
          </Layer>
        )}
        {showLogoEditor && (
          <Layer onClickOutside={() => setShowLogoEditor(false)} style={{ padding: '24px' }}>
            <Box gap="medium" width="500px">
              <TextField
                label="Full logo - 2:1 aspect ratio"
                placeholder="https://cdn.demo.3veta.com/full-logo.png"
                value={forcedFullLogo}
                onChange={(event) => {
                  setForcedFullLogo(event.target.value)
                }}
              />
              <TextField
                label="Square logo - 1:1 aspect ratio"
                placeholder="https://cdn.demo.3veta.com/square-logo.png"
                value={forcedSquareLogo}
                onChange={(event) => {
                  setForcedSquareLogo(event.target.value)
                }}
              />
              <TextField
                label="Marketplace banner - 9:2 aspect ratio"
                placeholder="https://cdn.demo.3veta.com/marketplace-banner.png"
                value={forcedMarketplaceBanner}
                onChange={(event) => {
                  setForcedMarketplaceBanner(event.target.value)
                }}
              />
            </Box>
          </Layer>
        )}
        {!showColorsPickers && (
          <Button
            plain
            label={
              <Box direction="column" align="center">
                <BackIcon
                  color={colors.dark}
                  width={20}
                  height={20}
                  style={{ transform: 'rotate(90deg)' }}
                />
                <Text>Show color picker</Text>
              </Box>
            }
            onClick={() => setShowColorPickers(true)}
          />
        )}
      </Layer>
    </>
  )
}

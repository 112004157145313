import getConfig from 'next/config'
import React from 'react'

import { DefaultAvatar3vetaIcon } from './DefaultAvatar3vetaIcon'
import { DefaultAvatarNon3vetaIcon } from './DefaultAvatarNon3vetaIcon'

const {
  publicRuntimeConfig: { WHITELABEL_ENV_IS_3VETA },
} = getConfig()

type Props = {
  width: string
  height: string
  circleId: string
  clipPathId: string
  gradientId: string
}
export function DefaultAvatarIcon({ width, height, circleId, clipPathId, gradientId }: Props) {
  if (WHITELABEL_ENV_IS_3VETA) {
    return (
      <DefaultAvatar3vetaIcon
        height={height}
        width={width}
        gradientId={gradientId}
        circleId={circleId}
        clipPathId={clipPathId}
      />
    )
  }
  return <DefaultAvatarNon3vetaIcon width={width} height={height} />
}

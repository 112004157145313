import React from 'react'

import { colors } from 'layout/themeColors/colors'

function ProfessionalTheme() {
  return (
    <svg
      width="172"
      height="140"
      viewBox="0 0 172 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="172" height="140" rx="4" fill="#2E4296" />
      <g>
        <path d="M172 32.2422H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 37.8638H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 43.4849H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 49.1064H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 54.728H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 60.3491H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 65.9707H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 71.5923H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 77.2134H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 82.835H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 88.4565H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 94.0776H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 99.6992H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 105.32H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 110.942H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 116.563H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 122.185H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
        <path d="M172 127.758H72" stroke="#ABF7FF" strokeWidth="0.5" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100" height="96" fill={colors.white} transform="translate(72 32)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ProfessionalTheme

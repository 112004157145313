import { baseErrorHandler } from '../../errors/ErrorHandlers'

import { Endpoint } from '.'

const RESOURCE_BASE_URL = 'meeting-rooms'

const MeetingRoomsEndpoints = {
  create: (): Endpoint => {
    return {
      method: 'POST',
      url: `${RESOURCE_BASE_URL}/add`,
      errorHandler: baseErrorHandler,
    }
  },
  list: (): Endpoint => {
    return {
      method: 'GET',
      url: `${RESOURCE_BASE_URL}/list`,
    }
  },
  delete: (): Endpoint => {
    return {
      method: 'DELETE',
      url: `${RESOURCE_BASE_URL}/delete`,
    }
  },
}

export type MeetingRoomFromApi = {
  id?: string
  user_id: string
  team_id?: string
  name: string
  path_name: string
  subdomain: string
}

export type MeetingRoom = MeetingRoomFromApi & {
  isQuickMeet: boolean
  path: string
  url_base: string
  url: string
}

export default MeetingRoomsEndpoints

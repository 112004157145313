import React, { PropsWithoutRef, SVGProps } from 'react'

import { checkWhitelabelEnv, WHITELABEL_ENVS } from 'lib/whitelabelEnvs'

import { useForcedLogo } from 'components/contexts/ForcedLogoProvider'

import FullLogo3veta from './FullLogo3veta'
import FullLogoNot3veta from './FullLogoNot3veta'

type Props = {
  color: string
  width?: number | string
  height?: number | string
} & PropsWithoutRef<SVGProps<SVGElement>>
function FullLogo(props: Props) {
  const { forcedFullLogo } = useForcedLogo()
  if (checkWhitelabelEnv(WHITELABEL_ENVS.THREEVETA)) {
    return <FullLogo3veta {...props} />
  }

  return <FullLogoNot3veta forcedLogo={forcedFullLogo} />
}

export default FullLogo

import React from 'react'

import { colors } from 'layout/themeColors/colors'

const SelectDownIcon = ({ color = colors.primaryBrand }) => (
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.38386 0.713816L4.13825 3.52023L4.49509 3.88382L4.85194 3.52023L7.60758 0.712534L8.2982 1.41124L4.49548 5.28579L0.700208 1.41038L1.38386 0.713816Z"
      fill={color}
      stroke={color}
    />
  </svg>
)

export default SelectDownIcon

import React from 'react'

interface CloseRateDialogIconProps {
  width?: number
  height?: number
  color?: string
}

export function CloseRateDialogIcon({
  color = '#333',
  height = 9,
  width = 9,
}: CloseRateDialogIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 9 9">
      <path
        fill={color}
        d="M4.441 3.269L1.776.31.591 1.627 3.283 4.62.59 7.608l1.194 1.315L4.45 5.966l2.665 2.957L8.3 7.608 5.608 4.614 8.3 1.627 7.106.31 4.441 3.269z"></path>
    </svg>
  )
}

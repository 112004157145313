import getConfig from 'next/config'

export enum WHITELABEL_ENVS {
  THREEVETA = '3veta',
  AGENCYGO = 'agencygo',
  TWIZ = 'twiz',
  VDDCOMMS = 'vddcomms',
  CONTABI = 'contabi',
  CHESSCOM = 'chesscom',
  SPARKLE = 'sparkleconnects',
  DEMO = 'demo',
}

const {
  publicRuntimeConfig: { WHITELABEL_ENV, PLATFORM_NAME },
} = getConfig()

export const platformName = () => PLATFORM_NAME

/**
 * checkWhitelabelEnv(WHITELABEL_ENVS.SPARKLE)
 */
export const checkWhitelabelEnv = (env: WHITELABEL_ENVS) => {
  return WHITELABEL_ENV === env
}

/**
 * checkWhitelabelEnvList([WHITELABEL_ENVS.SPARKLE, WHITELABEL_ENVS.CONTABI])
 */
export const checkWhitelabelEnvList = (envs: WHITELABEL_ENVS[]) => {
  return envs.includes(WHITELABEL_ENV)
}

export const isWhitelabel = () => {
  return !WHITELABEL_ENV || WHITELABEL_ENV !== WHITELABEL_ENVS.THREEVETA
}

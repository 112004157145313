import { Anchor, Box, Footer, ResponsiveContext, Text } from 'grommet'
import { TFunction } from 'i18next'
import getConfig from 'next/config'
import React from 'react'

import { GenericPalette, INTRO_SCRIPT_DEMO, links, SOFIA_SANS } from 'lib/constants'

import FullLogo from 'components/icons/common/FullLogo'
import { colors } from 'layout/themeColors/colors'

const {
  publicRuntimeConfig: { PLATFORM_NAME },
} = getConfig()

type Props = {
  t: TFunction
  lang: string
  font: string
  palette: GenericPalette
  shouldHavePlatformBranding: boolean
}
export const TemplateFooter = ({ t, lang, font, palette, shouldHavePlatformBranding }: Props) => {
  const languagePath = lang === 'en' ? '' : `/${lang}`
  const marketingUrl = new URL(languagePath, links.landing).href
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'

  let secondary = font
  if (font === INTRO_SCRIPT_DEMO) {
    secondary = SOFIA_SANS
  }

  const footerFontColor =
    palette.colors.footer === colors.white ? palette.colors.text : colors.white

  return (
    <Footer
      direction="column"
      height={{ min: 'fit-content' }}
      background={palette.colors.footer}
      align="center"
      pad={{ top: '15px' }}>
      <Box
        direction={isMobile ? 'column' : 'row'}
        pad="60px 0 40px 0"
        gap={isMobile ? '30px' : '80px'}
        fill
        align="center"
        justify="center">
        {shouldHavePlatformBranding && (
          <Anchor
            target="_blank"
            rel="noreferrer noopener"
            href={links.landing}
            label={t('website-templates.visit-3veta')}
            color={footerFontColor}
            style={{
              textDecoration: 'none',
              fontFamily: secondary,
              fontWeight: 400,
              fontSize: '16px',
            }}
          />
        )}
        <Anchor
          target="_blank"
          rel="noreferrer noopener"
          href={`${marketingUrl}${links.privacyNotice}`}
          label={t('website-templates.privacy-notice')}
          color={footerFontColor}
          style={{
            textDecoration: 'none',
            fontFamily: secondary,
            fontWeight: 400,
            fontSize: '16px',
          }}
        />
        <Anchor
          target="_blank"
          rel="noreferrer noopener"
          href={new URL(languagePath + links.termsOfUseCustomers, links.landing).href}
          label={t('website-templates.terms-of-use')}
          color={footerFontColor}
          style={{
            textDecoration: 'none',
            fontFamily: secondary,
            fontWeight: 400,
            fontSize: '16px',
          }}
        />
      </Box>
      {shouldHavePlatformBranding && <FullLogo color={footerFontColor} width="120" height="35" />}
      <Box
        width="80%"
        pad={{ bottom: '10px' }}
        border={{
          color: 'rgba(255, 255, 255, 0.3)',
          size: '1px',
          style: 'solid',
          side: 'bottom',
        }}>
        {isMobile && (
          <Text
            textAlign="center"
            color={footerFontColor}
            size="12px"
            style={{ fontFamily: secondary, opacity: '0.5' }}>
            {`Website powered by ${PLATFORM_NAME}`}
          </Text>
        )}
      </Box>
      <Box
        direction="row"
        width="80%"
        pad={{ bottom: '20px' }}
        justify={isMobile ? 'center' : 'between'}>
        {!isMobile && (
          <Text
            textAlign="center"
            color={footerFontColor}
            size="12px"
            style={{ fontFamily: secondary, opacity: '0.5' }}>
            {`Website powered by ${PLATFORM_NAME}`}
          </Text>
        )}
        <Text
          textAlign={isMobile ? 'center' : 'end'}
          color={footerFontColor}
          size="12px"
          style={{
            fontFamily: secondary,
            opacity: '0.5',
            width: '270px',
          }}>
          © {new Date().getFullYear()} {PLATFORM_NAME}. All Rights Reserved.
        </Text>
      </Box>
    </Footer>
  )
}

interface PhysicalLocationIconProps {
  width: number
  height: number
}

export function PhysicalLocationIcon({ width = 24, height = 24 }: PhysicalLocationIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9.5C4 10.9011 4.9816 13.0385 5.56944 14.1928C5.84654 14.737 6.17343 15.2487 6.52948 15.7448C7.58426 17.2144 9.97711 20.477 11.5004 22C12.99 20.5101 15.3122 17.3554 16.3999 15.8431C16.8032 15.2823 17.1902 14.7122 17.5174 14.1038C18.11 13.0023 19 11.0842 19 9.5C19 7.12523 18.8377 5.96215 17.5 4C16.1623 2.03785 14.0004 1 11.5 1C8.99965 1 6.83766 2.0381 5.5 4C4.16234 5.9619 4 7.5 4 9.5ZM11.5 12C13.433 12 15 10.433 15 8.5C15 6.567 13.433 5 11.5 5C9.567 5 8 6.567 8 8.5C8 10.433 9.567 12 11.5 12Z"
        fill="#5B31D4"
      />
    </svg>
  )
}

import i18n, { InitOptions } from 'i18next'
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector'
import getConfig from 'next/config'
import { initReactI18next } from 'react-i18next'

import { FALLBACK_LANG } from 'lib/constants'

import bg from './locales/bg.json'
import de from './locales/de.json'
import en from './locales/default.en.json'
import es from './locales/es.json'
import it from './locales/it.json'
import vn from './locales/vn.json'

/**
 * @see https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
 */
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const {
  publicRuntimeConfig: {
    WEBAPP_URL,
    isGermanLanguageOn,
    isSpanishLanguageOn,
    isBulgarianLanguageOn,
    isItalianLanguageOn,
    isVietnameseLanguageOn,
    THREEVETA_ENV,
    PLATFORM_DEFAULT_SUBDOMAIN,
  },
} = getConfig()

const suffix = THREEVETA_ENV === 'production' ? '' : `_${THREEVETA_ENV?.substr(0, 3)}`

const supportedLngs = [
  'en',
  ...(isBulgarianLanguageOn ? ['bg'] : []),
  ...(isItalianLanguageOn ? ['it'] : []),
  ...(isGermanLanguageOn ? ['de'] : []),
  ...(isSpanishLanguageOn ? ['es'] : []),
  ...(isVietnameseLanguageOn ? ['vn'] : []),
]
export const LOCALE_COOKIE_NAME = `next-i18n-lang${suffix}`
export const parseValidLocale = (input?: string) => {
  if (typeof input === 'string' && supportedLngs.includes(input)) {
    return input
  }
}

const detection: DetectorOptions = {
  order: [
    'cookie',
    // 'navigator', // Load locale from visitor's browser
  ],
  caches: ['cookie'],
  cookieDomain:
    WEBAPP_URL && new URL(WEBAPP_URL).host?.replace(`${PLATFORM_DEFAULT_SUBDOMAIN}.`, '.'),
  lookupCookie: LOCALE_COOKIE_NAME,
}

const options: InitOptions = {
  debug: false,
  returnNull: false,
  resources: {
    en: { translation: en },
    ...(isBulgarianLanguageOn ? { bg: { translation: bg } } : {}),
    ...(isItalianLanguageOn ? { it: { translation: it } } : {}),
    ...(isSpanishLanguageOn ? { es: { translation: es } } : {}),
    ...(isGermanLanguageOn ? { de: { translation: de } } : {}),
    ...(isVietnameseLanguageOn ? { vn: { translation: vn } } : {}),
  },
  fallbackLng: FALLBACK_LANG,
  supportedLngs,
  detection,
  react: {
    useSuspense: false,
  },
}

// https://www.i18next.com/overview/configuration-options
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options, (err) => {
    if (err) return console.error(err)
  })

export default i18n

import { ThemeType } from 'grommet'
import { deepFreeze } from 'grommet/utils'
import { css } from 'styled-components'

import CollapseUpIcon from '../components/icons/common/CollapseUpIcon'
import ExpandRightIcon from '../components/icons/common/ExpandRightIcon'
import SelectDownIcon from '../components/icons/common/SelectDownIcon'
import SelectUpIcon from '../components/icons/common/SelectUpIcon'

import { colors } from './themeColors/colors'

export const theme = deepFreeze<ThemeType>({
  global: {
    breakpoints: {
      small: {
        value: 850,
      },
      mediumS: {
        value: 1200,
      },
    },
    font: {
      family: 'Nunito',
    },
    colors: {
      brand: colors.primaryBrand,
      lightText: colors.lightText,
      'accent-1': colors.accent1,
      'accent-2': colors.accent2,
      'accent-3': colors.accent3,
      'accent-4': colors.accent4,
      accent5: colors.accent5,
      accent6: colors.accent6,
      dark: colors.dark,
      'light-1': colors.light1,
      'light-2': colors.light2,
      'status-error': colors.statusError,
      'status-disabled': colors.statusDisabled,
      logoDark: colors.logoDark,
      success: colors.success,
    },
    focus: {
      border: {
        color: 'transparent',
      },
    },
    elevation: {
      light: {
        xlarge: '0px 3px 57px rgba(79, 35, 100, 0.17)',
      },
    },
    input: {
      padding: {
        vertical: '11px',
        horizontal: '17px',
      },
    },
    drop: {
      // grommet's layer zIndex should be set to something < 1202. 1201 works fine because app bar's zIndex is 1200
      zIndex: 1202,
      background: colors.light1,
      shadowSize: 'xsmall',
      // @ts-expect-error extend is unknown
      extend: {
        'border-bottom-left-radius': '10px',
        'border-bottom-right-radius': '10px',
      },
    },
  },
  layer: {
    extend: {
      // app bar's and side menu's zIndex is 1200
      // @ts-expect-error extend is unknown
      zIndex: 1201,
    },
  },
  anchor: {
    color: {
      light: 'accent-1',
    },
  },
  button: {
    size: {
      large: {
        pad: {
          horizontal: '40px',
          vertical: '14px',
        },
        border: {
          radius: '3px',
        },
      },
      medium: {
        pad: {
          horizontal: '24px',
          vertical: '10px',
        },
        border: {
          radius: '3px',
        },
      },
      small: {
        pad: {
          horizontal: '12px',
          vertical: '6px',
        },
        border: {
          radius: '3px',
        },
      },
    },
    border: {
      width: '1.5px',
      radius: '3px',
    },
    padding: {
      vertical: '7px',
      horizontal: '17px',
    },
    color: {
      light: colors.white,
      dark: colors.white,
    },
    extend: {
      // @ts-expect-error extend is unknown
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: '600',
    },
  },
  heading: {
    weight: 700,
    level: {
      1: {
        medium: {
          size: '30px',
          height: '30px',
        },
      },
      2: {
        medium: {
          size: '24px',
          height: '24px',
        },
      },
      3: {
        medium: {
          size: '18px',
          height: '18px',
        },
      },
    },
  },
  text: {
    xsmall: {
      size: '13px',
      height: '15px',
    },
    medium: {
      height: '18px',
    },
  },
  maskedInput: {
    pad: '0',
    extend: {
      // @ts-expect-error extend is unknown
      background: colors.light1,
      border: 'none',
      fontSize: '14px',
      fontWeight: '400',
      padding: '9px 10px',
    },
    container: {
      extend: {
        // @ts-expect-error extend is unknown
        borderRadius: '3px',
      },
    },
  },
  textInput: {
    pad: '0',
    extend: {
      // @ts-expect-error extend is unknown
      background: colors.light1,
      border: 'none',
      fontSize: '14px',
      fontWeight: '400',
      padding: '9px 10px',
    },
    container: {
      extend: {
        // @ts-expect-error extend is unknown
        borderRadius: '3px',
      },
    },
  },
  textArea: {
    pad: '0',
    extend: {
      // @ts-expect-error extend is unknown
      background: colors.light1,
      border: 'none',
      fontSize: '14px',
      fontWeight: '400',
      padding: '10px',
    },
    container: {
      extend: {
        borderRadius: '10px',
      },
    },
    // @ts-expect-error disabled is unknown
    disabled: {
      opacity: 0.5,
      extend: {
        borderRadius: '10px',
      },
    },
  },
  formField: {
    disabled: {
      // @ts-expect-error disabled is unknown
      opacity: 0.5,
      background: {
        color: colors.light1,
      },
    },
    borderRadius: '3px',
    border: {
      color: 'transparent',
      error: {
        color: colors.statusError,
      },
      // @ts-expect-error radius is unknown
      radius: '3px',
    },
    label: {
      size: '13px',
      color: colors.dark,
      margin: {
        vertical: '4px',
        horizontal: '0',
      },
    },
  },
  select: {
    background: colors.light1,
    border: '0',
    container: {
      extend: {
        // @ts-expect-error extend is unknown
        backgroundColor: colors.light1,
        overflow: 'hidden',
      },
    },
    control: {
      open: {
        borderRadius: '0',
      },
    },
    icons: {
      down: SelectDownIcon,
      up: SelectUpIcon,
    },
  },
  table: {
    row: {
      hover: {
        background: 'none',
      },
    },
    header: {
      pad: {
        top: '20px',
        bottom: '12px',
        horizontal: '5px',
      },
      // @ts-expect-error border is unknown
      border: {
        side: 'bottom',
        color: '#F2F2F2',
      },
    },
    body: {
      extend: () => css`
        outline: none;
        box-shadow: none;
      `,
      pad: {
        horizontal: '0',
        vertical: '0',
      },
    },
  },
  dataTable: {
    body: {
      extend: () => css`
        outline: none;
        box-shadow: none;
      `,
    },
    icons: {
      expand: ExpandRightIcon,
      contract: CollapseUpIcon,
    },
  },
  tabs: {
    header: {
      background: colors.light2,
      extend: () => css`
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      `,
    },
    panel: {
      extend: () => css`
        overflow: hidden;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #5597fb;

        padding-top: 5px;
        height: 100%;
      `,
    },
  },
  tab: {
    active: {
      background: colors.white,
    },
    extend: () => css`
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin: 0px;
      padding: 14px 23px 14px 17px;
    `,
    border: undefined,
  },
  accordion: {
    icons: {
      color: colors.primaryBrand,
    },
    border: {
      color: '#CCE0FE',
    },
    // @ts-expect-error active is unknown
    active: {
      label: {
        color: colors.white,
      },
    },
  },
  checkBox: {
    border: {
      color: colors.lightText,
    },
    size: '16px',
    color: {
      light: colors.primaryBrand,
    },
    hover: {
      border: {
        color: undefined,
      },
    },
  },
})

import { colors } from 'layout/themeColors/colors'

interface DefaultAvatarNon3vetaIconProps {
  width?: number | string
  height?: number | string
}

export function DefaultAvatarNon3vetaIcon({
  height = 35,
  width = 35,
}: DefaultAvatarNon3vetaIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="31" height="31" rx="15.5" fill={colors.statusDisabled} />
      <path
        d="M16.9047 16H14.0634C12.2075 16.0091 10.4299 16.5646 9.11013 17.5476C7.79038 18.5307 7.0331 19.8636 7 21.2616V23.3901C7 23.5519 7.08529 23.707 7.2371 23.8214C7.38892 23.9357 7.59482 24 7.80952 24C7.91509 24 8.0196 23.9842 8.11694 23.9534C8.21428 23.9226 8.30249 23.8775 8.37639 23.8207C8.4503 23.7639 8.50843 23.6965 8.54736 23.6226C8.5863 23.5487 8.60527 23.4697 8.60316 23.3901V21.3453C8.60735 20.2489 9.18731 19.1984 10.2164 18.4231C11.2454 17.6479 12.7039 17.5032 14.1592 17.5H16.8155C18.2708 17.5032 19.5052 17.7247 20.5342 18.5C21.5633 19.2753 22.3925 20.2489 22.3967 21.3453V23.3901C22.3967 23.5519 22.482 23.707 22.6338 23.8214C22.7856 23.9357 22.9915 24 23.2062 24C23.3118 24 23.4163 23.9842 23.5136 23.9534C23.611 23.9226 23.6992 23.8775 23.7731 23.8207C23.847 23.7639 23.9051 23.6965 23.944 23.6226C23.983 23.5487 24.002 23.4697 23.9998 23.3901V21.3453V21.2616C23.9666 19.8595 23.2049 18.5231 21.8784 17.5393C20.5518 16.5556 18.7661 16.0029 16.9047 16Z"
        fill="white"
      />
      <path
        d="M15.015 15C15.8061 14.997 16.5785 14.7595 17.2347 14.3176C17.8908 13.8756 18.4012 13.249 18.7012 12.5169C19.0012 11.7849 19.0774 10.9803 18.9202 10.205C18.763 9.42966 18.3793 8.71836 17.8179 8.16104C17.2564 7.60372 16.5422 7.22542 15.7657 7.07398C14.9892 6.92253 14.1853 7.00474 13.4555 7.31022C12.7257 7.61569 12.1029 8.1307 11.6659 8.79013C11.2288 9.44956 10.9971 10.2238 11 11.0149C11.002 11.5402 11.1074 12.0599 11.3102 12.5445C11.513 13.0291 11.8093 13.4689 12.1821 13.839C12.555 14.209 12.997 14.502 13.4831 14.7012C13.9691 14.9004 14.4897 15.0019 15.015 15ZM15.015 8.52236C15.5073 8.52531 15.9877 8.674 16.3956 8.94967C16.8036 9.22534 17.1207 9.61564 17.3071 10.0713C17.4935 10.527 17.5407 11.0277 17.4428 11.5103C17.345 11.9928 17.1064 12.4355 16.7572 12.7826C16.4081 13.1297 15.9639 13.3656 15.4808 13.4606C14.9977 13.5555 14.4973 13.5053 14.0428 13.3162C13.5882 13.1272 13.1998 12.8077 12.9265 12.3981C12.6533 11.9885 12.5075 11.5072 12.5075 11.0149C12.5055 10.6858 12.5691 10.3595 12.6946 10.0552C12.8201 9.75092 13.0049 9.47468 13.2384 9.24264C13.4718 9.01059 13.7491 8.82738 14.0542 8.70371C14.3592 8.58004 14.6858 8.51839 15.015 8.52236Z"
        fill="white"
      />
    </svg>
  )
}

import React from 'react'

import { checkWhitelabelEnv, WHITELABEL_ENVS } from 'lib/whitelabelEnvs'

import { useForcedLogo } from 'components/contexts/ForcedLogoProvider'

import LogoSquare3veta from './LogoSquare3veta'
import LogoSquareNot3veta from './LogoSquareNot3veta'

interface LogoSquareProps {
  width?: number
  height?: number
}

function LogoSquare({ height, width }: LogoSquareProps) {
  const { forcedSquareLogo } = useForcedLogo()

  if (checkWhitelabelEnv(WHITELABEL_ENVS.THREEVETA)) {
    return <LogoSquare3veta width={width} height={height} />
  }

  return <LogoSquareNot3veta width={width} height={height} forcedLogo={forcedSquareLogo} />
}
export default LogoSquare

import React from 'react'

import { colors } from 'layout/themeColors/colors'

function FunkyTheme() {
  return (
    <svg
      width="172"
      height="140"
      viewBox="0 0 172 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect width="172" height="140" rx="4" fill="#A29AFF" />
        <circle cx="173.5" cy="29.5" r="20.5" fill="#4ED9E9" />
        <circle cx="167" cy="43" r="26.5" stroke={colors.dark} />
        <circle cx="2.5" cy="66.5" r="42.5" fill="#FAE579" />
        <circle cx="-3" cy="84" r="41.5" stroke={colors.dark} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="172" height="140" fill={colors.white} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FunkyTheme

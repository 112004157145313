import React from 'react'

import { colors } from 'layout/themeColors/colors'

function SemiCircle({ color, width = '57', height = '128' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 57 114">
      <circle cx="14" cy="43" r="43" fill={color}></circle>
      <circle cy="65" r="55" stroke={colors.dark}></circle>
    </svg>
  )
}

export default SemiCircle

import getConfig from 'next/config'
import { default as TagManager, TagManagerArgs } from 'react-gtm-module'

const {
  publicRuntimeConfig: { GTM_ID },
} = getConfig()

const gtmId = GTM_ID

export type EventType =
  | 'test'
  | 'login'
  | 'logout'
  | 'signup'
  | 'pageview'
  | 'cookie_consent_analytics_accepted'
  | 'cookie_consent_analytics_rejected'
  | 'cookie_consent_marketing_accepted'
  | 'cookie_consent_marketing_rejected'
  | 'meeting_created'
  | 'meeting_not_created'
  | 'onboarding_started'
  | 'onboarding_error'
  | 'onboarding_success'
  | 'onboarding_finalize_error'
  | 'change_language'
  | 'connect_calendar'
  | 'customize_meeting_room'
  | 'update_profile_image'
  | 'booking_complete'
  | 'view_item'
  | 'manual_clear_data_layer_value'
  | 'addToCart'
  | 'checkout'
  | 'purchase'

export type DataEvent = { event: EventType; [key: string]: unknown }

export const gtmInitialize = (params?: Partial<TagManagerArgs>) =>
  TagManager.initialize({ gtmId, ...params })
export const trackEvent = (dataLayer: DataEvent, dataLayerName?: string) =>
  TagManager.dataLayer({ dataLayer, dataLayerName })

export default function useGTM() {
  return {
    gtmId,
    initialize: gtmInitialize,
    trackEvent,
  }
}

import { EmotionCache } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import i18next from 'i18next'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'
import { I18nextProvider } from 'react-i18next'

import '../App.css'
import '../calendar.css'
import '../index.css'

import useGTM from 'config/useGTM'
import { DEFAULT_APP_SUBDOMAIN } from 'lib/constants'
import { isInAppBrowser } from 'lib/inAppBrowsersUtil'
import { links, meta } from 'lib/meta'
import createEmotionCache from 'utils/createEmotionCache'
import theme from 'utils/muiTheme'

import { ForcedLogoProvider } from 'components/contexts/ForcedLogoProvider'
import { SidebarProvider } from 'components/contexts/SidebarProvider'
import { UserContextProvider } from 'components/contexts/UserProvider'
import { PaaSDemoThemeColorsPicker } from 'components/PaaSDemoThemeColorsPicker'
import { CustomToaster } from 'components/Toasts/CustomToaster'
import { GrommetLayout } from 'layout/GrommetLayout'
import { ErrorPageInAppBrowser } from 'scenes/Errors/ErrorPageInAppBrowser'
import { CombinedProps } from 'services/auth/ssp'

import '../i18n'

const {
  publicRuntimeConfig: {
    CDN_URL,
    PLATFORM_NAME,
    DOMAIN,
    featureFlags: { isPaasDemoThemeColorPickerEnabled },
  },
} = getConfig()

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

type PageProps = CombinedProps

type NextPageWithLayout = NextPage<PageProps> & {
  Layout?: ({ children }: { children?: ReactNode }) => React.ReactElement
}
type AppPropsWithLayout = AppProps<PageProps> & {
  Component: NextPageWithLayout
  emotionCache?: EmotionCache
}

const CustomApp = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppPropsWithLayout) => {
  const router = useRouter()
  const { trackEvent } = useGTM()
  // const { mode, setMode } = useColorScheme()
  const [, setForceRerenderCounter] = useState(0)

  // Initiate user language on the backend
  const desiredLocale = pageProps?.serverUser?.iso_language_code ?? pageProps.initialLocale
  if (i18next.language !== desiredLocale) {
    i18next.changeLanguage(desiredLocale)
  }

  useEffect(() => {
    // Next.js Router
    const onRouteChange = (url: string) => {
      trackEvent({
        event: 'pageview',
        language: i18next.language,
        page_title: document.title,
        page_pathname: url,
        page_location:
          document.location.protocol +
          '//' +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      })
    }
    // In order to use the smooth transition add <Link scroll={false} />
    router.events.on('routeChangeComplete', onRouteChange)
    return () => router.events.off('routeChangeComplete', onRouteChange)
  }, [i18next.language])

  const Layout = Component?.Layout || GrommetLayout
  return (
    <CacheProvider value={emotionCache}>
      <I18nextProvider i18n={i18next}>
        <UserContextProvider
          currentSubdomain={pageProps?.currentSubdomain || DEFAULT_APP_SUBDOMAIN}
          serverUser={pageProps?.serverUser}
          path={pageProps?.path}>
          <Head>
            {meta.map((props, index) => (
              <meta key={props?.key || `meta-${index}`} {...props} />
            ))}
            {pageProps?.currentUrl && (
              <meta key="og:url" property="og:url" content={pageProps.currentUrl} />
            )}
            {links.map((props, index) => (
              <link key={`link-${index}`} {...props} />
            ))}
            <title>{`Dashboard | ${PLATFORM_NAME}`}</title>
            {/* Original: https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js */}
            <link rel="preconnect" href="apikeys.civiccomputing.com" />
          </Head>
          <CssVarsProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <Layout>
              <ForcedLogoProvider>
                <CustomToaster />
                {isPaasDemoThemeColorPickerEnabled && !pageProps.disableColorPicker && (
                  <PaaSDemoThemeColorsPicker
                    forceRerender={() => setForceRerenderCounter((prev) => prev + 1)}
                  />
                )}
                <SidebarProvider>
                  <Component {...pageProps} />
                </SidebarProvider>
                {pageProps.host !== DOMAIN && isInAppBrowser() && <ErrorPageInAppBrowser />}
              </ForcedLogoProvider>
            </Layout>
          </CssVarsProvider>
          <script src={`${CDN_URL}static/cookieControl-9.x.min.js`} />
        </UserContextProvider>
      </I18nextProvider>
    </CacheProvider>
  )
}

export default CustomApp

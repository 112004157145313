import ScheduleIcon from '@mui/icons-material/Schedule'
import { Box, Text } from 'grommet'

import { durationToString } from 'lib/dates'

type Props = {
  duration: number
}

export default function DisplayDuration({ duration }: Props) {
  return (
    <Box direction="row" align="center" gap="xsmall">
      <ScheduleIcon color="primary" />
      <Text size="14px" color="#1D1F50" weight={600}>
        {durationToString(duration)}
      </Text>
    </Box>
  )
}

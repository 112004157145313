import { Anchor, Box, Text } from 'grommet'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { centsToCurrency, formatUrl, isValidUrl } from 'lib/helpers'
import { getLocationObject } from 'lib/meeting-location'

import { colors } from 'layout/themeColors/colors'
import { Meeting, UpcomingEvent } from 'services/api/Meetings'

interface EventLocationAndPriceLabelsProps {
  event: UpcomingEvent | Meeting
  fontSize?: string
}

export function EventLocationAndPriceLabels({
  event,
  fontSize = '18px',
}: EventLocationAndPriceLabelsProps) {
  const { t } = useTranslation()
  const locationObject = getLocationObject(event.meeting_location)

  const eventPrice = useMemo(
    () =>
      event.is_free
        ? t('free')
        : centsToCurrency(event.price_in_cents, t('currency_code.' + event.iso_currency_code))
            .withCurrency,
    [event],
  )

  return (
    <Box gap="8px">
      <Text size={fontSize} color={colors.lightText}>
        {locationObject && (
          <Box style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {locationObject.isOnline ? (
              <>{t('website-builder.builder-form.home.online')}</>
            ) : event.is_free ? (
              isValidUrl(event.additional_meeting_info) ? (
                <Anchor href={formatUrl(event.additional_meeting_info as string)} target="_blank">
                  {formatUrl(event.additional_meeting_info as string)}
                </Anchor>
              ) : (
                event.additional_meeting_info
              )
            ) : (
              <>{t('events.physical-location')}</>
            )}
          </Box>
        )}
      </Text>
      <Text size={fontSize} color={colors.lightText}>
        {eventPrice}
      </Text>
    </Box>
  )
}

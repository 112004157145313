import axios, { AxiosError } from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { AUTH_TOKEN_KEY, AUTH_TOKEN_RESTRICTED_KEY, getCookie } from 'cookieStorage'
import getConfig from 'next/config'
import Router from 'next/router'

import { ERROR_CODES, redirectExceptions } from 'lib/constants'
import { routes } from 'lib/routes'

import { Endpoint } from 'services/api'
import { logout } from 'services/auth'

const {
  publicRuntimeConfig: { API_URL, THREEVETA_ENV },
} = getConfig()

export const API_BASE_URL = API_URL

let authInterceptor: number
let logRequestInterceptor: number
let logResponseInterceptor: number

// export const API_BASE_URL = "https://cors-anywhere.herokuapp.com/https://3veta.com/cake"
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

const setupClientInterceptors = () => {
  /**
   * The authorization header is added only if the request is to our API to prevent sending credential to third parties.
   * This is run on each request so if the token changes (for example as a result of a token refresh) then the next
   * request picks up the new token. Also a check for existing authorization value in the request is done to allow
   * overriding of the header from the call site if that happened to be necessary for whatever reason.
   *
   * Reference: https://github.com/axios/axios/issues/1383#issuecomment-377870814
   */
  api.interceptors.request.use(
    (config) => {
      if ('skipAuthorization' in config && (config as Endpoint).skipAuthorization) {
        // skip adding auth
        return config
      }
      if (config.baseURL === API_BASE_URL && !config.headers.Authorization) {
        try {
          const token = getCookie(AUTH_TOKEN_KEY) ?? getCookie(AUTH_TOKEN_RESTRICTED_KEY)
          if (token) config.headers.Authorization = `Bearer ${token}`
        } catch (error) {
          // we're on the server
        }
      }
      return config
    },
    (error) => Promise.reject(error),
  )

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      // Execute custom error handlers per request
      if (error.response && error.response?.config && 'errorHandler' in error.response.config) {
        const { errorHandler } = error.response?.config as Endpoint
        if (typeof errorHandler === 'function') {
          const result = errorHandler(error)
          if (result) {
            return Promise.reject(result)
          }
        }
      }
      switch (error.response?.status) {
        case ERROR_CODES.NOT_AUTHENTICATED:
          if (
            window.location.pathname !== routes.login.index &&
            window.location.pathname !== routes.login.withEmail &&
            window.location.pathname !== routes.quickmeet.path &&
            !window.location.pathname.startsWith(routes.room.path) &&
            !window.location.pathname.includes(`${routes.meeting.path}/`) &&
            !window.location.pathname.includes(`${routes.booking.index}/`) &&
            !window.location.pathname.includes(`${routes.events.index}/`)
          ) {
            logout(true)
          }
          break
        case ERROR_CODES.PAYMENT_REQUIRED:
          if (
            window.location.pathname !== routes.subscription &&
            !redirectExceptions.external.some((exception) =>
              window.location.pathname.includes(exception),
            )
          ) {
            Router.push(`${routes.domain}${routes.subscription}`)
          }
          break
        case ERROR_CODES.METHOD_NOT_ALLOWED:
        case ERROR_CODES.NOT_AUTHORIZED:
          if (THREEVETA_ENV !== 'development') {
            Router.push(routes.error)
          }
          break
        case ERROR_CODES.INTERNAL_SERVER_ERROR:
          if (THREEVETA_ENV !== 'development') {
            Router.push(routes.error)
          } else {
            console.log(error.response)
            throw new Error(error.response?.data?.error || error.response?.statusText)
          }
          break
        default:
          break
      }

      return Promise.reject(error)
    },
  )
}

export const runWithAuth = async (jwtToken: string, callback: () => any) => {
  if (typeof callback !== 'function') {
    throw new Error('Invalid auth callback')
  }
  setupServerAuthentication(jwtToken)
  try {
    return await callback()
  } finally {
    cleanAuthentication()
  }
}

export const setupServerAuthentication = (jwtToken?: string) => {
  console.log('SetupAuthInterceptor', authInterceptor)
  cleanAuthentication()

  if (jwtToken) {
    // Setup authentication at runtime in long-running process
    authInterceptor = api.interceptors.request.use(
      (config) => {
        if ('skipAuthorization' in config && (config as Endpoint).skipAuthorization) {
          // skip adding auth
          console.log('Skip authorization')
          return config
        }
        if (config.baseURL === API_BASE_URL && !config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${jwtToken}`
        }
        return config
      },
      (error) => {
        console.error('API Error:', error.message)
        return Promise.reject(error)
      },
    )
  }
}

export const cleanAuthentication = () => {
  if (typeof authInterceptor !== 'undefined') {
    api.interceptors.request.eject(authInterceptor)
  }
}

export const setupServerResponseInterceptor = () => {
  if (typeof logRequestInterceptor === 'undefined') {
    console.log('SetupLogRequestInterceptor', logRequestInterceptor)
    logRequestInterceptor = api.interceptors.request.use(
      (config) => {
        console.log(
          'API Request:',
          config?.method?.toUpperCase(),
          `${config.baseURL}${config.url}`,
          config?.data ?? '',
        )
        return config
      },
      (error) => {
        console.error('API Error:', error.message)
        return Promise.reject(error)
      },
    )
  }

  if (typeof logResponseInterceptor === 'undefined') {
    console.log('SetupLogResponseInterceptor', logRequestInterceptor)
    logResponseInterceptor = api.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        if (response) {
          console.log(
            'API Response:',
            response?.config?.method?.toUpperCase(),
            response?.statusText,
            `${response?.config?.baseURL}${response?.config?.url}`,
          )
        }
        return response
      },
      (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (
          error.response &&
          error.response?.config &&
          'errorHandler' in error.response.config &&
          (error.response?.config as Endpoint).errorHandler
        ) {
          return error.response?.config.errorHandler(error)
        }
        console.error(
          'API Response Error:',
          error.message,
          `(${error.response?.statusText})`,
          error.response?.config?.method?.toUpperCase(),
          error.response?.data,
          `${error.response?.config?.baseURL}${error.response?.config?.url}`,
        )
        return Promise.reject(error)
      },
    )
  }
}

// Client side interceptors
if (typeof window !== 'undefined') {
  setupClientInterceptors()
}

const useApi = makeUseAxios({ axios: api })

export { api, useApi }

import { imageListItemClasses, inputClasses } from '@mui/material'

/**
 * @see https://mui.com/material-ui/experimental-api/css-theme-variables/usage/#typescript
 */
import { blueGrey, common, grey } from '@mui/material/colors'
import { experimental_extendTheme as extendTheme, PaletteColorOptions } from '@mui/material/styles'
import type {} from '@mui/material/themeCssVarsAugmentation'

/**
 * Needed so we can extend the theme with styleOverrides for DataGrid
 * @see https://mui.com/x/react-data-grid/getting-started/#typescript
 */
import type {} from '@mui/x-data-grid/themeAugmentation'
import { Nunito } from '@next/font/google'

import { colors } from 'layout/themeColors/colors'

declare module '@mui/material/styles' {
  interface PaletteOptions {
    neutral?: PaletteColorOptions
  }
  interface TypeBackground {
    sample: string
  }
}

export const font = Nunito({
  weight: ['300', '400', '500', '600', '700', '900'],
  subsets: ['latin', 'cyrillic'],
  display: 'auto',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

const spacing = 8 // px
const borderRadius = 3

const success = {
  light: '#e5fce5',
  main: colors.success,
  dark: '#044904',
  contrastText: '#FFFFFF',
} as const
const info = {
  main: '#2196F3',
  light: '#64B6F7',
  dark: '#0B79D0',
  contrastText: '#FFFFFF',
} as const
const warning = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText: '#FFFFFF',
} as const
const error = {
  main: colors.statusError,
  light: '#DA6868',
  dark: '#922E2E',
  contrastText: '#FFFFFF',
} as const

export const palette = {
  neutral: {
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  action: {
    active: '#6B7280',
    focus: 'rgba(55, 65, 81, 0.12)',
    hover: 'rgba(55, 65, 81, 0.04)',
    selected: 'rgba(55, 65, 81, 0.08)',
    disabledBackground: 'rgba(55, 65, 81, 0.12)',
    disabled: 'rgba(55, 65, 81, 0.26)',
  },
  background: {
    default: '#F3F4F6',
    paper: '#FFFFFF',
  },
  divider: '#E6E8F0',
  primary: {
    light: '#4B5563',
    main: '#374151',
    dark: '#111827',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#9CA3AF',
    main: '#6B7280',
    dark: '#4B5563',
    contrastText: '#FFFFFF',
  },
  success,
  info,
  warning,
  error,
  text: {
    primary: '#121828',
    secondary: '#65748B',
    disabled: 'rgba(55, 65, 81, 0.48)',
  },
} as const

const theme = extendTheme({
  spacing,
  colorSchemes: {
    light: {
      palette: {
        background: {
          default: grey[100],
          paper: common.white,
          sample: 'orange',
        },
        primary: {
          main: colors.primaryBrand,
        },
        success,
        info,
        warning,
        error,
        AppBar: {
          defaultBg: common.white,
        },
      },
    },
    dark: {
      palette: {
        background: {
          default: blueGrey[900],
          paper: blueGrey[700],
        },
        primary: {
          main: blueGrey[500],
        },
        secondary: {
          main: blueGrey[300],
        },
        success,
        info,
        warning,
        error,
        AppBar: {
          defaultBg: blueGrey[700],
        },
      },
    },
  },
  shadows: [
    'none',
    '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
    '0px 1px 2px rgba(100, 116, 139, 0.12)',
    '0px 1px 4px rgba(100, 116, 139, 0.12)',
    '0px 1px 5px rgba(100, 116, 139, 0.12)',
    '0px 1px 6px rgba(100, 116, 139, 0.12)',
    '0px 2px 6px rgba(100, 116, 139, 0.12)',
    '0px 3px 6px rgba(100, 116, 139, 0.12)',
    '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
    '0px 5px 12px rgba(100, 116, 139, 0.12)',
    '0px 5px 14px rgba(100, 116, 139, 0.12)',
    '0px 5px 15px rgba(100, 116, 139, 0.12)',
    '0px 6px 15px rgba(100, 116, 139, 0.12)',
    '0px 7px 15px rgba(100, 116, 139, 0.12)',
    '0px 8px 15px rgba(100, 116, 139, 0.12)',
    '0px 9px 15px rgba(100, 116, 139, 0.12)',
    '0px 10px 15px rgba(100, 116, 139, 0.12)',
    '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
    '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
    '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
    '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
  ],
  typography: {
    fontFamily: font.style.fontFamily,
    button: {
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    body2: {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: 1.375,
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.375,
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.375,
    },
    h4: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.375,
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.375,
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: 1.375,
    },
  },
  shape: { borderRadius },
  components: {
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: '1rem',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        /**
         * Disable jumping styles
         * @see https://github.com/mui/material-ui/issues/10000
         */
        disableScrollLock: true,
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'primary.dark',
        underline: 'hover',
        fontWeight: 400,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'initial',
        },
        sizeLarge: {
          fontWeight: 700,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
        SelectProps: {
          // disableUnderline: true,
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          // Remove underline from standard inputs globally
          // Same as `disableUnderline: true` prop
          ...(ownerState.variant === 'standard' && {
            [`.${inputClasses.root}::before`]: { display: 'none' },
            [`.${inputClasses.root}::after`]: { display: 'none' },
          }),
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // Disables padding on appbar when modal is opened
          padding: '0 !important',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {},
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // Disables margin overrides on backdrop element
          margin: '0 !important',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // https://github.com/mui/material-ui/issues/31185#issuecomment-1088954266
          paddingTop: `${8}px !important`,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          // Icons in MenuItem
          [`& .${imageListItemClasses.root}`]: { minWidth: '32px' },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: `${borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {},
          '&:hover $notchedOutline': {},
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
        },
        input: {
          borderRadius: `${borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '8px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' },
      },
    },
    MuiChip: {
      defaultProps: { size: 'small' },
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 6,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '32px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        main: {
          backgroundColor: 'white',
        },
        columnHeader: {
          padding: '16px',
          fontSize: '14px',
          fontWeight: 500,
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        columnHeaders: {
          borderColor: palette.divider,
        },
        cell: {
          padding: '8px 16px',
          fontWeight: 400,
          borderColor: palette.divider,
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        cellContent: {},
        row: {
          '&.MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
            borderColor: palette.divider,
          },
        },
        footerContainer: {
          backgroundColor: 'white',
        },
      },
    },
  },
})

export default theme

import getConfig from 'next/config'
import { ParsedUrlQuery, stringify } from 'querystring'

const {
  // getting PLATFORM_DEFAULT_SUBDOMAIN from envs because when getting it from constants.ts results in an error
  // ReferenceError: Cannot access 'routes' before initialization
  publicRuntimeConfig: { WEBAPP_URL, PLATFORM_DEFAULT_SUBDOMAIN },
} = getConfig()

export const trimmedWebAppUrl = () => {
  if (WEBAPP_URL.slice(-1) === '/') {
    return WEBAPP_URL.slice(0, -1)
  }
  return WEBAPP_URL
}

export const assembleSubdomainAddress = (subdomain: string, path = '') => {
  return new URL(path, trimmedWebAppUrl().replace(PLATFORM_DEFAULT_SUBDOMAIN, subdomain)).href
}

export const assembleWebAppAddress = (path: string, query?: { key: string; value: string }) => {
  const url = new URL(path, trimmedWebAppUrl())
  if (query) {
    url.searchParams.set(query.key, query.value)
  }
  return url.href
}

export const assembleWebAppAddressUrl = (path = '') => {
  return new URL(path, trimmedWebAppUrl())
}

export const routes = {
  root: `/`,
  home: '/home',
  addOns: {
    index: '/add-ons',
    zapier: '/add-ons/zapier',
  },
  domain: `${WEBAPP_URL?.replace(/\/$/, '')}`,
  signup: {
    index: '/signup',
    withQuery: (query?: ParsedUrlQuery) => {
      if (query) {
        return `${routes.signup.index}?${stringify(query)}`
      }
      return routes.signup.index
    },
  },
  onboard: {
    byStep: (
      step: 'name-country' | 'team-name' | 'calendar-connect' | 'add-photo' | 'ref-source-industry',
    ) => `/onboard/${step}`,
    index: '/onboard',
    personalInfo: '/onboard/name-country',
    teamName: '/onboard/team-name',
    calendarConnect: '/onboard/calendar-connect',
    addPhoto: '/onboard/add-photo',
    refSourceIndustry: '/onboard/ref-source-industry',
  },
  website: {
    index: '/website',
    themes: '/website/themes',
    colors: '/website/colors',
    fonts: '/website/fonts',
    home: '/website/home',
    about: '/website/about',
    services: '/website/services',
  },
  meeting: {
    path: '/meeting',
    view: (meetingId: string) => `/meeting/${meetingId}`,
  },
  meetings: {
    index: '/meetings',
    upcoming: '/meetings/upcoming',
    past: '/meetings/past',
    canceled: '/meetings/canceled',
    byStatus: (status: 'upcoming' | 'past' | 'canceled') => `/meetings/${status}`,
  },
  events: {
    index: '/events',
    upcoming: '/events/upcoming',
    past: '/events/past',
    canceled: '/events/canceled',
    byStatus: (status: 'upcoming' | 'past' | 'canceled') => `/events/${status}`,
    view: '/events/view',
  },
  meetingRooms: {
    index: '/meeting-rooms',
    bySubdomain: (subdomain: string, name: string) =>
      `${assembleSubdomainAddress(subdomain, `room/${name}`)}`,
  },
  contacts: '/contacts',
  calendar: {
    index: '/calendar',
    calendarSync: '/calendar/calendar-sync',
  },
  booking: {
    index: '/booking',
    bySubdomain: (subdomain: string) => `${assembleSubdomainAddress(subdomain, 'booking')}`,
    viewService: (subdomain: string, serviceId: string) =>
      `${assembleSubdomainAddress(subdomain, 'booking/' + serviceId)}`,
  },
  bookableServices: {
    index: '/bookable-services',
    create: '/bookable-services/create',
    services: '/bookable-services/services',
    availability: '/bookable-services/availability',
  },
  confirmEmail: '/confirm-email',
  customizeMeetingRoom: '/customize-meeting-room',
  error: '/error',
  forgottenPassword: '/forgotten-password',
  login: {
    index: '/login',
    withEmail: '/login/with-email',
  },
  logout: '/logout',
  newMeeting: '/new-meeting',
  newEvent: '/new-event',
  newMeetingRoom: '/new-meeting-room',
  profile: {
    index: '/profile',
    account: '/profile/account',
    team: '/profile/team',
    platform: '/profile/platform',
    billing: '/profile/billing',
  },
  quickmeet: {
    path: '/quickmeet',
    bySubdomain: (subdomain: string) => `${assembleSubdomainAddress(subdomain, 'quickmeet')}`,
  },
  room: {
    path: '/room',
    bySubdomain: (subdomain: string, roomId: string) =>
      `${assembleSubdomainAddress(subdomain, `room/${roomId}`)}`,
  },
  resetPasswordSuccessful: '/reset-password-successful',
  resetPassword: '/reset-password',
  subscription: '/subscription',
  websitePreview: '/website-preview',
  signInWithGoogleEmbeddablePage: '/sign-in-with-google-embeddable-page',
  signInWithFacebookEmbeddablePage: '/sign-in-with-facebook-embeddable-page',
  slackConnect: {
    byId: (userSlackDetailId: string) => `/slack-connect/${userSlackDetailId}`,
  },
  slackAuthorization: '/slack-authorization',
  marketplace: {
    index: '/marketplace',
  },
  paasDemo: '/paas-demo',
  paasDemoIPad: '/paas-demo-ipad',
  meetingNotFound: '/meeting-not-found',
}

import React, { CSSProperties } from 'react'

import { colors } from 'layout/themeColors/colors'

interface BackIconProps {
  width?: number
  height?: number
  style?: CSSProperties
  color?: string
}

const BackIcon = ({ width = 6, height = 9, color = colors.primaryBrand, style }: BackIconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.28618 1.38386L2.47977 4.13825L2.11618 4.49509L2.47977 4.85194L5.28747 7.60758L4.58876 8.2982L0.714213 4.49548L4.58962 0.700208L5.28618 1.38386Z"
      fill={color}
      stroke={color}
    />
  </svg>
)

export default BackIcon

import { AUTH_TOKEN_KEY, AUTH_TOKEN_RESTRICTED_KEY, removeCookie, setCookie } from 'cookieStorage'

import { api } from 'config/api'
import { EventType, trackEvent } from 'config/useGTM'
import { routes } from 'lib/routes'

import { fetchUserById } from './fetchUser'

type LoginTypeData = 'password' | 'google' | 'facebook' | 'passwordless'

type LoginData =
  | { email: string; password: string }
  | { google_credentials: string }
  | { facebook_access_token: string }

type AuthTokens = { full: string; restricted: string }

export const loginWithTokens = async (
  tokens: AuthTokens,
  userId: string,
  event: EventType,
  loginType: LoginTypeData,
) => {
  setCookie(AUTH_TOKEN_KEY, tokens.full, false, false)
  setCookie(AUTH_TOKEN_RESTRICTED_KEY, tokens.restricted, true, false)

  const user = await fetchUserById({
    userId: userId,
    jwt: tokens.restricted,
  })

  trackEvent({
    event,
    user_id: user.id,
    user_type: user.user_type.name,
    login_type: loginType,
  })

  return user
}

const doLoginWithRequest = async (data: LoginData, event: EventType, loginType: LoginTypeData) => {
  const loginResponse = await api.post('/users/login', data)
  return await loginWithTokens(
    loginResponse.data.tokens,
    loginResponse.data.user_id,
    event,
    loginType,
  )
}

export async function loginWithPassword(
  email: string,
  password: string,
  event: EventType = 'login',
) {
  return await doLoginWithRequest(
    {
      email: email,
      password: password,
    },
    event,
    'password',
  )
}

export async function loginWithGoogleSignIn(
  google_credentials: string,
  event: EventType = 'login',
) {
  return await doLoginWithRequest(
    {
      google_credentials: google_credentials,
    },
    event,
    'google',
  )
}

export async function loginWithFacebook(facebook_access_token: string, event: EventType = 'login') {
  return await doLoginWithRequest(
    {
      facebook_access_token,
    },
    event,
    'facebook',
  )
}

export function logout(goToLogoutPage = true) {
  trackEvent({
    event: 'logout',
  })
  removeCookie(AUTH_TOKEN_KEY)
  removeCookie(AUTH_TOKEN_RESTRICTED_KEY)
  if (goToLogoutPage) window.location.href = routes.logout
}

export function changeAuth(tokens: AuthTokens) {
  removeCookie(AUTH_TOKEN_KEY)
  removeCookie(AUTH_TOKEN_RESTRICTED_KEY)
  setCookie(AUTH_TOKEN_KEY, tokens.full, false, false)
  setCookie(AUTH_TOKEN_RESTRICTED_KEY, tokens.restricted, true, false)
  window.location.href = routes.domain
}

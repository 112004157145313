import { Box, Button, Heading, Layer, Text } from 'grommet'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { colors } from '../layout/themeColors/colors'

import { BorderedButton } from './BorderedButton'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm?: () => void | Promise<void>
  title: string
  subtitle?: string
  showCancelButton?: boolean
  labelConfirm?: string
  labelCancel?: string
  dataTrackingConfirm?: string
  dataTrackingCancel?: string
  stopButtonClickPropagation?: boolean
}

export const GenericConfirmModal = ({
  open,
  setOpen,
  onConfirm,
  title,
  subtitle,
  showCancelButton = true,
  labelConfirm,
  labelCancel,
  dataTrackingConfirm,
  dataTrackingCancel,
  stopButtonClickPropagation = false,
}: Props) => {
  const { t } = useTranslation()

  const closeModal = useCallback(() => setOpen(false), [])

  if (!open) {
    return null
  }

  return (
    <Layer onClickOutside={closeModal} onEsc={closeModal}>
      <Box pad="medium" gap="small" margin="auto" background={colors.white}>
        <Box width="medium" alignSelf="center">
          <Heading level="4" textAlign="center" margin="0" size="small">
            {title}
          </Heading>
        </Box>
        {subtitle && (
          <Text
            size="14px"
            color={colors.dark}
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        <Box
          direction="row"
          alignSelf="center"
          width="70%"
          justify="center"
          gap="small"
          pad={{ top: '10px' }}>
          <Box alignSelf="center">
            <Button
              primary
              data-tracking={dataTrackingConfirm}
              size="medium"
              onClick={async (e) => {
                if (stopButtonClickPropagation) {
                  e.stopPropagation()
                }
                closeModal()
                onConfirm && (await onConfirm())
              }}
              label={labelConfirm ?? t('generic-confirm-modal.confirm')}
            />
          </Box>
          {showCancelButton && (
            <Box alignSelf="center">
              <BorderedButton
                onClick={(e) => {
                  if (stopButtonClickPropagation) {
                    e.stopPropagation()
                  }
                  closeModal()
                }}
                size="medium"
                data-tracking={dataTrackingCancel}
                label={labelCancel ?? t('generic-confirm-modal.cancel')}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Layer>
  )
}

import React from 'react'

interface LogoProps {
  width?: number
  height?: number
}

function LogoSquare3veta({ height = 25, width = 24 }: LogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1280 1280"
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        enableBackground: 'new 0 0 1280 1280',
      }}
      xmlSpace="preserve"
      width={width}
      height={height}>
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1={1957.696}
        y1={1212.875}
        x2={3083.139}
        y2={608.627}
        gradientTransform="rotate(-45 1214.613 3012.52) scale(.9973 1.0021)">
        <stop
          offset={0}
          style={{
            stopColor: '#5a9efb',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#5f27cb',
          }}
        />
      </linearGradient>
      <path
        d="M1089.1 191.3c250 249.9 250.9 654.1 2.1 902.8S438 1341.9 188.1 1092-62.8 437.9 186 189.2s653.1-247.8 903.1 2.1z"
        style={{
          fill: 'url(#a)',
        }}
      />
      <linearGradient
        id="b"
        gradientUnits="userSpaceOnUse"
        x1={355.436}
        y1={3060.424}
        x2={435.089}
        y2={3525.103}
        gradientTransform="matrix(1 0 0 -1 0 3572)">
        <stop
          offset={0}
          style={{
            stopColor: '#80a8ca',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#6c4ec0',
          }}
        />
      </linearGradient>
      <path
        d="M636.2 639.5c-56.1-97.2-85.6-207.5-85.6-319.7S580.1 97.3 636.2 0C524.5.7 415 30.5 318.5 86.6c-96.5 56.1-177 135.9-232.8 232.6 55.8 96.7 136.3 177.6 232.8 233.7s206 85.9 317.7 86.6z"
        style={{
          fill: 'url(#b)',
        }}
      />
      <linearGradient
        id="c"
        gradientUnits="userSpaceOnUse"
        x1={875.063}
        y1={2932.753}
        x2={869.249}
        y2={3642.021}
        gradientTransform="matrix(1 0 0 -1 0 3572)">
        <stop
          offset={0}
          style={{
            stopColor: '#edbae8',
          }}
        />
        <stop
          offset={0.891}
          style={{
            stopColor: '#bd4dad',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#b93da9',
          }}
        />
      </linearGradient>
      <path
        d="M640.2 0h-4c-56.1 97.2-85.6 207.5-85.6 319.7 0 112.2 29.5 222.5 85.6 319.7h4c112.3 0 222.7-29.5 320-85.6 97.3-56.1 178.1-136.9 234.2-234.1-56.1-97.2-136.9-178-234.2-234.1C862.9 29.5 752.5 0 640.2 0z"
        style={{
          fill: 'url(#c)',
        }}
      />
      <linearGradient
        id="d"
        gradientUnits="userSpaceOnUse"
        x1={1136.945}
        y1={2619.125}
        x2={924.981}
        y2={3074.477}
        gradientTransform="matrix(1 0 0 -1 0 3572)">
        <stop
          offset={0.015}
          style={{
            stopColor: '#ac9afb',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#aa5be7',
          }}
        />
      </linearGradient>
      <path
        d="M1194.3 319.8c-56.1 97.3-136.9 178-234.2 234.1-97.3 56.1-207.6 85.7-320 85.6h-4c56.1 97.2 136.9 178 234.2 234.1 97.3 56.1 211.6 85.7 323.9 85.6l5.6-9.4c53.5-96.6 81.1-205.4 80.2-315.7-.9-110.4-30.4-218.7-85.5-314.3h-.2z"
        style={{
          fill: 'url(#d)',
        }}
      />
    </svg>
  )
}
export default LogoSquare3veta

import { Anchor, Box, Button, Heading, Image, ResponsiveContext, Text } from 'grommet'
import React, { useRef, useState } from 'react'

import { INTRO_SCRIPT_DEMO, SOFIA_SANS, StandardPalette, ThemeProps } from 'lib/constants'

import CloseIcon from 'components/icons/common/CloseIcon'
import ThreevetaBallBlackAndWhite from 'components/icons/myPage/ThreevetaBallBlackAndWhite'
import ThreevetaFullImageBlackAndWhite from 'components/icons/myPage/ThreevetaFullImageBlackAndWhite'
import HamburgerMenuIcon from 'components/icons/navigation/HamburgerMenuIcon'
import { SloganBanner } from 'components/SloganBanner'
import { colors } from 'layout/themeColors/colors'
import { ProviderEventsPage } from 'scenes/MyPage/Events/ProviderEventsPage'

import { ProviderBookingPage } from '../../Booking/ProviderBookingPage'

import { ProvidersContactForm } from './components/ProvidersContactForm'
import { TemplateFooter } from './components/TemplateFooter'

export const StandardThemePage = ({
  t,
  subdomain,
  font,
  palette,
  homePageImage,
  homeImagePosition,
  logo,
  title,
  subtitle,
  aboutTitle,
  aboutDescription,
  aboutImage,
  aboutImagePosition,
  preview,
  homeSectionRef,
  aboutSectionRef,
  providerId,
  lang = 'en',
  bookableServices,
  shouldHaveBanner = true,
  events,
}: ThemeProps<StandardPalette>) => {
  const size = React.useContext(ResponsiveContext)
  const isMobile = size === 'small'
  const contactSectionRef = useRef<HTMLDivElement>(null)

  let secondary = font
  if (font === INTRO_SCRIPT_DEMO) {
    secondary = SOFIA_SANS
  }

  const isFullWidthTheme = palette.fullWidth

  const homeImageSized =
    typeof homePageImage === 'object'
      ? isMobile || preview
        ? homePageImage?.regular
        : homePageImage?.full
      : homePageImage
  const aboutImageSized =
    typeof aboutImage === 'object'
      ? isMobile || preview
        ? aboutImage?.regular
        : aboutImage?.full
      : aboutImage

  const headerFontColor = palette.colors.header === '#1E737B' ? colors.white : palette.colors.text
  const primaryTitleSize = isMobile ? '33px' : '65px'
  const subTitleSize = isMobile ? '18px' : '25px'
  const secondaryTitleSize = isMobile ? '25px' : '35px'
  const paragraphSize = isMobile ? '12px' : '16px'

  const [openMobileNav, setOpenMobileNav] = useState(false)

  const navButtons = () => (
    <Box
      direction={isMobile ? 'column' : 'row'}
      gap={isMobile ? '25px' : '50px'}
      justify="center"
      fill
      align={isMobile ? 'start' : 'center'}>
      <Anchor
        href="#about"
        label={t('website-templates.about')}
        color={headerFontColor}
        style={{
          textTransform: 'uppercase',
          fontSize: isMobile ? '11px' : '14px',
          textDecoration: 'none',
          fontFamily: secondary,
        }}
      />
      {bookableServices && (
        <Anchor
          href="#services"
          label={t('website-templates.services')}
          color={headerFontColor}
          style={{
            textTransform: 'uppercase',
            fontSize: isMobile ? '11px' : '14px',
            textDecoration: 'none',
            fontFamily: secondary,
          }}
        />
      )}
      {events && (
        <Anchor
          href="#events"
          label={t('website-templates.events')}
          color={headerFontColor}
          style={{
            textTransform: 'uppercase',
            fontSize: isMobile ? '11px' : '14px',
            textDecoration: 'none',
            fontFamily: secondary,
          }}
        />
      )}
      <Anchor
        href="#contact"
        label={t('website-templates.contacts')}
        color={headerFontColor}
        style={{
          textTransform: 'uppercase',
          textDecoration: 'none',
          fontSize: isMobile ? '11px' : '14px',
          fontFamily: secondary,
        }}
      />
    </Box>
  )

  return (
    <Box
      flex="grow"
      background={colors.white}
      className={(preview ? 'preview' : 'website-template') + ' standard-template'}>
      {shouldHaveBanner && <SloganBanner />}
      <Box style={{ minHeight: '70px', position: 'relative' }}>
        <Box
          direction="row"
          ref={homeSectionRef}
          align="center"
          fill="horizontal"
          pad={isMobile ? '0 35px' : '0 125px 0 80px'}
          height="70px"
          background={palette.colors.header}>
          <Box direction="row" gap="50px" align="center">
            {logo ? (
              <Image src={logo} height="35px" />
            ) : (
              <ThreevetaBallBlackAndWhite width="35px" height="35px" />
            )}
          </Box>
          {isMobile ? (
            <Box fill align="end" justify="center">
              <Button style={{ height: '19px' }} onClick={() => setOpenMobileNav((open) => !open)}>
                {openMobileNav ? (
                  <CloseIcon sideSize="16" color={headerFontColor} />
                ) : (
                  <HamburgerMenuIcon color={headerFontColor} />
                )}
              </Button>
            </Box>
          ) : (
            navButtons()
          )}
        </Box>
        {openMobileNav && isMobile && (
          <Box
            background={palette.colors.header}
            pad="35px"
            style={{ position: 'absolute', top: '70px', width: '100%', zIndex: 2 }}>
            {navButtons()}
          </Box>
        )}
      </Box>
      <Box
        style={{ minHeight: 'fit-content' }}
        alignSelf="center"
        width={isFullWidthTheme ? '100%' : '80%'}
        background={colors.white}
        align="center"
        gap={isFullWidthTheme ? '0' : 'medium'}>
        <Box pad={{ top: isMobile ? '50px' : '100px' }} gap="large" align="center" width="100%">
          <Box gap="small" align="center">
            <Heading
              level={1}
              textAlign="center"
              size={primaryTitleSize}
              color={palette.colors.textHeading}
              margin="0"
              style={{ fontFamily: font }}>
              {!title && preview ? t('website-builder.builder-form.home.main-title') : title}
            </Heading>
            <Text
              size={subTitleSize}
              color={palette.colors.text}
              weight={600}
              style={{ fontFamily: secondary }}>
              {!subtitle && preview ? t('website-builder.builder-form.home.subtitle') : subtitle}
            </Text>
          </Box>
          <Box fill="horizontal" height="fit-content">
            {homePageImage ? (
              <Image
                style={{
                  height: isMobile ? '220px' : '550px',
                  objectFit: 'cover',
                  objectPosition: homeImagePosition,
                  borderRadius: isFullWidthTheme ? '0' : isMobile ? '5px' : '10px',
                }}
                src={homeImageSized}
              />
            ) : (
              <ThreevetaFullImageBlackAndWhite width="100%" height={isMobile ? '310px' : '700px'} />
            )}
          </Box>
        </Box>
        <Box ref={aboutSectionRef} id="about" width="100%">
          <Box width="100%">
            <Box
              align="center"
              fill="horizontal"
              flex="grow"
              justify="center"
              gap={isFullWidthTheme ? '0' : 'medium'}>
              <Box
                gap="medium"
                flex="grow"
                height={{ min: '360px' }}
                round={isFullWidthTheme ? '0' : isMobile ? '5px' : '10px'}
                fill="horizontal"
                background={palette.colors.contact}
                align="center"
                justify="center"
                pad={{
                  horizontal: isMobile ? 'medium' : 'xlarge',
                  vertical: 'medium',
                }}>
                <Heading
                  level={2}
                  size={secondaryTitleSize}
                  textAlign="center"
                  color={palette.colors.textHeading}
                  style={{ fontFamily: font }}>
                  {!aboutTitle && preview
                    ? t('website-builder.builder-form.home.title')
                    : aboutTitle}
                </Heading>
                {!aboutDescription && preview ? (
                  <Text
                    size={paragraphSize}
                    color={palette.colors.text}
                    weight={400}
                    style={{ fontFamily: secondary }}>
                    {t('website-builder.builder-form.home.description')}
                  </Text>
                ) : (
                  aboutDescription && (
                    <div
                      dangerouslySetInnerHTML={{ __html: aboutDescription }}
                      style={{
                        fontFamily: secondary,
                        color: palette.colors.text,
                        fontSize: paragraphSize,
                        lineHeight: isMobile ? '24px' : '38px',
                        margin: '0',
                        fontWeight: 400,
                      }}
                    />
                  )
                )}
              </Box>
              <Box width="100%" height={isMobile ? '220px' : '510px'}>
                {aboutImageSized ? (
                  <Image
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: aboutImagePosition,
                      borderRadius: isFullWidthTheme ? '0' : isMobile ? '5px' : '10px',
                    }}
                    src={aboutImageSized}
                  />
                ) : (
                  <ThreevetaFullImageBlackAndWhite width="100%" height="100%" />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {bookableServices && (
        <Box
          id="services"
          width={isFullWidthTheme ? '100%' : '80%'}
          alignSelf="center"
          align="center"
          margin={{ vertical: isFullWidthTheme ? '0' : '24px' }}>
          <ProviderBookingPage
            shouldHaveBanner={false}
            preview={false}
            services={bookableServices}
            withHeader={false}
            round="10px"
            subdomain={subdomain}
            showInline
          />
        </Box>
      )}
      {events && (
        <Box
          id="events"
          width={isFullWidthTheme ? '100%' : '80%'}
          alignSelf="center"
          align="center">
          <ProviderEventsPage events={events} subdomain={subdomain} withHeader={false} />
        </Box>
      )}
      <Box
        id="contact"
        style={{ minHeight: 'fit-content' }}
        width="80%"
        alignSelf="center"
        background={isFullWidthTheme ? colors.white : palette.colors.contact}
        ref={contactSectionRef}
        align="center"
        pad="40px 0 90px 0"
        round="10px"
        margin={{ bottom: '24px' }}>
        <Heading
          level={2}
          size={secondaryTitleSize}
          color={palette.colors.text}
          textAlign="center"
          style={{ fontFamily: font }}>
          {t('website-templates.contact-title')}
        </Heading>
        <Box width={isMobile ? '80%' : '60%'}>
          <ProvidersContactForm
            t={t}
            providerId={providerId}
            backgroundColor={isFullWidthTheme ? palette.colors.contact : colors.white}
            buttonStyle={{
              fontFamily: secondary,
              backgroundColor: palette.colors.button,
              padding: '12px 24px',
              fontSize: '13px',
              lineHeight: '23px',
              borderRadius: '10px',
              width: 'fit-content',
              display: 'block',
              margin: 'auto auto 10px auto',
            }}
            palette={palette}
            secondary={secondary}
          />
        </Box>
      </Box>
      <TemplateFooter
        t={t}
        lang={lang}
        font={font}
        palette={palette}
        shouldHavePlatformBranding={shouldHaveBanner}
      />
    </Box>
  )
}

import getConfig from 'next/config'
import Image from 'next/image'
import React from 'react'

const {
  publicRuntimeConfig: { CDN_URL, PLATFORM_NAME },
} = getConfig()

interface LogoSquareNot3vetaProps {
  width?: number
  height?: number
  forcedLogo?: string
}

function LogoSquareNot3veta({ height = 50, width = 50, forcedLogo }: LogoSquareNot3vetaProps) {
  return (
    <Image
      alt={PLATFORM_NAME}
      width={width}
      height={height}
      src={forcedLogo || `${CDN_URL}static/logo-square.png`}
    />
  )
}

export default LogoSquareNot3veta

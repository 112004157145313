import { createContext, ReactNode, useContext, useState } from 'react'

const ForcedLogoContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const [forcedFullLogo, setForcedFullLogo] = useState<string>()
  const [forcedSquareLogo, setForcedSquareLogo] = useState<string>()
  const [forcedMarketplaceBanner, setForcedMarketplaceBanner] = useState<string>()
  return {
    forcedFullLogo,
    setForcedFullLogo,
    forcedSquareLogo,
    setForcedSquareLogo,
    forcedMarketplaceBanner,
    setForcedMarketplaceBanner,
  }
}

export const ForcedLogoProvider = ({ children }: { children: ReactNode }) => {
  const context = useInit()
  return <ForcedLogoContext.Provider value={context}>{children}</ForcedLogoContext.Provider>
}

export const useForcedLogo = () => {
  return useContext(ForcedLogoContext)
}
